/* eslint-disable @typescript-eslint/no-inferrable-types */
export const textoParaValor = (valor: string) => {
  const numero = valor.replace(/\D/g, '');

  return parseFloat(numero) / 100;
};

export const valorParaTexto = (valor?: number | string, obrigatorio: boolean = false): string => {
  if (valor === null || valor === undefined || isNaN(Number(valor))) {
    return obrigatorio
      ? (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : '';
  }

  // Garante que o valor seja tratado como número
  const numericValue = typeof valor === 'string' ? parseFloat(valor) : valor;

  return numericValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const retornarImagemBanco = (banco?: string) => {
  switch (banco) {
  }
};

export const retornarImagemBandeira = (bandeira?: string) => {
  switch (bandeira) {
  }
};

export const primeiroDiaMesAtual = () => {
  const data = new Date();
  return new Date(data.getFullYear(), data.getMonth(), 1);
};

export const ultimoDiaMesAtual = () => {
  const data = new Date();
  return new Date(data.getFullYear(), data.getMonth() + 1, 0);
};

export const extrairMesAno = (data: Date) => {
  const mes = data.getMonth() + 1;
  const ano = data.getFullYear();

  return `${ano}-${mes.toString().padStart(2, '0')}`;
};

