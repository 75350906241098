import Pagina from '../estruturais/pagina/Pagina';
import styles from './Configuracao.module.css';

export default function Configuracao() {
  return (
    <Pagina>
      <div></div>
    </Pagina>
  );
}

