import { useState } from 'react';
import Pagina from '../../estruturais/pagina/Pagina';
import styles from './Conta.module.css';
import { useContas } from '../../../servicos/contas/useContas';
import { valorParaTexto } from '../../../utils/Utils';
import { TituloPagina } from '../../estruturais/tituloPagina/TituloPagina';
import Botao from '../../../componentes/botao/Botao';
import { Pesquisa } from '../../../componentes/pesquisa/Pesquisa';
import TextField from '../../../componentes/inputs/TextField';
import DataTable, { Coluna, Linha } from '../../../componentes/datatable/DataTable';
import PopUp from '../../../componentes/popup/PopUp';
import Formulario from './Formulario';
import ComboBox from '../../../componentes/inputs/ComboBox';
import Guide from '../../../componentes/guia/Guide';

const colunas: Coluna[] = [
  { nome: 'Id', visivel: false },
  { nome: 'Nome' },
  { nome: 'Tipo', alinhamento: 'center', tamanho: 100 },
  { nome: 'Saldo', alinhamento: 'center', tamanho: 150 }
];

const isNotebook = window.innerWidth <= 1366;

const guias = [
  {
    mensagem:
      'Adicione uma nova conta clicando no botão "Adicionar". Registre suas contas bancárias, carteiras ou qualquer outra fonte financeira para acompanhar seus saldos.',
    target: '#adicionar'
  },
  {
    mensagem:
      'Utilize a barra de pesquisa para localizar rapidamente uma conta específica. Isso facilita a busca quando houver várias contas cadastradas.',
    target: '#pesquisa'
  },
  {
    mensagem:
      'Clique no ícone de edição para modificar os detalhes da conta, como nome, tipo, cor de identificação ou outras informações relevantes.',
    target: '#editar'
  },
  {
    mensagem:
      'Para remover uma conta, clique no ícone de exclusão. As movimentações associadas serão automaticamente desvinculadas, garantindo que o histórico permaneça consistente.',
    target: '#remover'
  }
];

export default function Conta() {
  const { contas, deleteConta } = useContas();
  const [selecionada, setSelecionada] = useState<any>(null);
  const [abrirEdicao, setAbrirEdicao] = useState(false);
  const [abrirExclusao, setAbrirExclusao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [tipo, setTipo] = useState('');
  const [mostrarGuia, setMostrarGuia] = useState(false);
  const [selecionados, setSelecionados] = useState<string[]>([]);

  const handleAbrirEdicao = (id: number) => {
    setAbrirEdicao(true);
    setSelecionada(contas.find((item) => item.id === id));
  };

  const handleAbrirExclusao = () => {
    setAbrirExclusao(true);
  };

  const handleSelecionarLinha = (id: string) => {
    if (selecionados.includes(id)) {
      setSelecionados(selecionados.filter((item) => item !== id));
    } else {
      setSelecionados([...selecionados, id]);
    }
  };

  const handleSelecionarTodos = () => {
    if (selecionados.length === dados.length) {
      setSelecionados([]);
    } else {
      setSelecionados(dados.map((item) => item[0].valor?.toString() || ''));
    }
  };

  const handleApagar = () => {
    if (selecionados.length > 0) {
      deleteConta(selecionados);
      handleCancelar();
    }
  };

  const handleCancelar = () => {
    setAbrirEdicao(false);
    setAbrirExclusao(false);
    setSelecionada(null);
    setSelecionados([]);
  };

  const handleLimparFiltros = () => {
    setTipo('');
    setPesquisa('');
  };

  const dados: Linha[][] = contas
    .filter((conta) => {
      return [
        () => pesquisa === '' || conta.descricao.toLowerCase().includes(pesquisa.toLowerCase()), // Pesquisa no nome
        () => tipo === '' || conta.tipo === tipo // Filtra pelo tipo (D ou R)
      ].every((filtro) => filtro()); // Verifica se todos os filtros retornam true
    })
    .map((conta) => [{ valor: conta.id }, { valor: conta.descricao }, { valor: conta.tipo }, { valor: valorParaTexto(conta.saldo, true) }]);

  const opcoesTipo = [
    { id: 'Corrente', valor: 'Corrente' },
    { id: 'Dinheiro', valor: 'Dinheiro' },
    { id: 'Poupança', valor: 'Poupança' },
    { id: 'Investimento', valor: 'Investimento' },
    { id: 'Outros', valor: 'Outros' }
  ];

  return (
    <Pagina>
      <div className={styles.container}>
        {mostrarGuia && <Guide guias={guias} onClose={() => setMostrarGuia(false)} />}
        {abrirEdicao && (
          <PopUp
            icone="account_balance"
            titulo={selecionada ? 'Alterar conta' : 'Adicionar uma conta'}
            subtitulo={'Adicione ou edite suas contas bancárias.'}
            onClose={() => handleCancelar()}
          >
            <Formulario item={selecionada} fecharModal={() => handleCancelar()} />
          </PopUp>
        )}
        {abrirExclusao && (
          <PopUp
            icone="delete"
            titulo={'Excluir conta'}
            subtitulo={'Deseja realmente excluir esta conta?'}
            onClose={() => handleCancelar()}
            cor="#D92D20"
          >
            <div className={styles.botoes}>
              <Botao
                texto="Cancelar"
                icone={'close'}
                onClick={() => handleCancelar()}
                background="var(--background)"
                borderColor="var(--border)"
                color="var(--text);"
              />
              <Botao icone="delete" texto="Excluir" onClick={() => handleApagar()} background="#D92D20" color="var(--white)" />
            </div>
          </PopUp>
        )}
        <div className={styles.wrapper}>
          <TituloPagina titulo="Contas Bancárias" subtitulo="Adicione ou edite suas contas bancárias.">
            {selecionados.length > 0 ? (
              <Botao
                texto={`Excluir (${selecionados.length})`}
                onClick={() => handleAbrirExclusao()}
                icone="delete"
                background="var(--background)"
                borderColor="#D92D20"
                color="#D92D20"
              />
            ) : (
              <Botao
                id="adicionar"
                texto="Adicionar"
                onClick={() => setAbrirEdicao(true)}
                icone="add"
                background="var(--background)"
                color="var(--primary)"
                borderColor="var(--primary)"
              />
            )}
          </TituloPagina>
          <Pesquisa>
            <div className={styles.filtros}>
              <TextField value={pesquisa} onChange={setPesquisa} placeholder="Pesquisar..." icone="search" />
              <ComboBox
                value={tipo}
                onChange={setTipo}
                icone="account_balance_wallet"
                placeholder={isNotebook ? '' : 'Tipo da conta'}
                options={opcoesTipo}
              />
              {(tipo || pesquisa) && (
                <Botao texto="Limpar Filtros" onClick={handleLimparFiltros} background="transparent" color="var(--text);" icone="close" />
              )}
            </div>
            <Botao
              texto={isNotebook ? '' : 'Ajuda'}
              hint="Guia"
              onClick={() => setMostrarGuia(true)}
              background="var(--background)"
              color="var(--text)"
              borderColor="var(--border)"
              icone="help"
              iconPosition="right"
            />
          </Pesquisa>
          <DataTable
            colunas={colunas}
            linhas={dados}
            onEditar={handleAbrirEdicao}
            multiselecao
            selecionados={selecionados}
            onSelecionar={handleSelecionarLinha}
            onSelecionarTodos={handleSelecionarTodos}
          />
        </div>
      </div>
    </Pagina>
  );
}

