import Botao from '../../componentes/botao/Botao';
import styles from './LandingBase.module.css';

const Price = ({ onClick = () => {} }) => {
  return (
    <section id="depoimentos" className={styles.container}>
      <div className={styles.wrapperFeatures}>
        <span className={styles.dica}>Planos</span>
        <span className={styles.titulo}>Planos para todas as necessidades</span>
        <span className={styles.subtitulo}>
          Experimente <b style={{ color: 'var(--yellow)' }}>14 dias grátis</b> e aproveite o melhor controle financeiro. <br />
        </span>
        <div>
          <div className={styles.opcoes}>
            <div className={styles.painel}>
              <div className={styles.cabecalho}>
                <div className={styles.infos}>
                  <span className={styles.tituloPlano}>Plano Gratuito</span>
                  <span className={styles.subtituloPlano}>Para quem quer um controle básico das finanças.</span>
                </div>
                <div className={styles.valores}>
                  <span className={styles.valor}>R$ 0.00</span>
                  <span className={styles.label}>/ {'mês'}</span>
                </div>
              </div>
              <div className={styles.detalhes}>
                <div className={styles.detalhesTitulo}>Funcionalidades</div>
                <div className={styles.detalhesSubtitulo}>
                  Tudo que o plano <b>grátis</b> possui:
                </div>
                <div className={styles.funcionalidades}>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Funcionalidades <b>básicas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Dashboards <b>básicos</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Prioridade <b>normal</b> no suporte
                      </span>
                    </div>
                  </div>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Contas bancárias <b>ilimitadas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Cartões de crédito <b>ilimitadas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Categorias <b>ilimitadas</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.painel}>
              <div className={styles.cabecalho}>
                <div className={styles.infos}>
                  <span className={styles.tituloPlano}>
                    <span className="material-symbols-rounded" style={{ color: 'var(--yellow)' }}>
                      crown
                    </span>
                    Plano <span style={{ color: 'var(--yellow)' }}>Plus</span>
                  </span>
                  <span className={styles.subtituloPlano}>Para quem quer um controle mais avançado das finanças.</span>
                </div>
                <div className={styles.valores}>
                  <span className={styles.valor}>R$ 9.90</span>
                  <span className={styles.label}>/ {'mês'}</span>
                </div>
              </div>
              <div className={styles.detalhes}>
                <div className={styles.detalhesTitulo}>Funcionalidades</div>
                <div className={styles.detalhesSubtitulo}>
                  Tudo que o plano <b>grátis</b> possui, mais:
                </div>
                <div className={styles.funcionalidades}>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        14 dias de <b>teste grátis</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Dashboards <b>avançados</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>
                        Prioridade <b>alta</b> no suporte
                      </span>
                    </div>
                  </div>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>Gastos e receitas por categoria</span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>Metas de gastos e receitas</span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.iconePlano} material-symbols-rounded`}>check</span>
                      <span>Cancele a qualquer momento</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className={styles.subtitulo} style={{ marginTop: '32px' }}>
          São menos de <b style={{ color: 'var(--yellow)' }}>R$ 0,33</b> por dia para ter controle total das suas finanças! <br />
        </span>
        <Botao
          texto="Experimente grátis por 14 dias"
          onClick={onClick}
          background="var(--primary)"
          color="var(--white)"
          borderColor="var(--primary)"
          iconPosition="right"
        />
      </div>
    </section>
  );
};

export default Price;

