import { useEffect, useState } from 'react';
import axios from 'axios';
import { Conta } from './typeConta';
import { useContaContexto } from '../../contextos/ContaContexto';
import useAvisos from '../../hooks/useAviso';
import { usePerfilContexto } from '../../contextos/PerfilContexto';

const API_URL = process.env.REACT_APP_API_URL + '/contas';

export function useContas() {
  const { perfil, setPerfil } = usePerfilContexto();
  const { contas, setContas } = useContaContexto();
  const [loading, setLoading] = useState<boolean>(false);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token');

  // Fetch all contas with JWT token
  const fetchContas = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get<Conta[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContas(response.data);
      setPerfil((prev) => ({ ...prev, carregarContas: false }));
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao buscar as contas.');
    } finally {
      setLoading(false);
    }
  };

  // Create a new conta with JWT token
  const createConta = async (newConta: Conta) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(API_URL, newConta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      response.data.saldo = newConta.saldo;

      setContas((prevContas) => [...prevContas, response.data]);

      adicionarAviso('Conta adicionada com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao criar a conta.');
    } finally {
      setLoading(false);
    }
  };

  // Update a conta with JWT token
  const updateConta = async (id: number, updatedConta: Conta) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedConta, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContas((prevContas) => prevContas.map((conta) => (conta.id === id ? { ...response.data, saldo: conta.saldo } : conta)));
      adicionarAviso('Conta alterada com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao alterar a conta.');
    } finally {
      setLoading(false);
    }
  };

  // Delete a conta with JWT token
  const deleteConta = async (listaIds: string[]) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);
    try {
      await axios.delete(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          ids: listaIds
        }
      });
      setContas((prevContas) => prevContas.filter((conta) => !listaIds.includes(conta.id?.toString() || '')));
      adicionarAviso('Conta removida com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao remover a conta.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (perfil.carregarContas) {
      fetchContas();
    }
  }, [perfil.carregarContas]);

  return {
    contas,
    loading,
    fetchContas,
    createConta,
    updateConta,
    deleteConta
  };
}

