import { useNavigate } from 'react-router-dom';
import Botao from '../../componentes/botao/Botao';
import styles from './LandingBase.module.css';
import Logo from '../../images/Logo';
import Switch from '../../componentes/switch/Switch';
import { useState } from 'react';

type Props = {
  onLogin: () => void;
  onCreateAccount: () => void;
  esconder?: boolean;
  atualizar?: boolean;
  onAtualizar: (value: boolean) => void;
};

const Header = ({ onLogin, onCreateAccount, esconder = false, atualizar = false, onAtualizar }: Props) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleAparencia = (value: string) => {
    const root = document.documentElement;
    root.setAttribute('data-theme', value);
    localStorage.setItem('tema', value);
    onAtualizar(!atualizar);
  };

  const tema = localStorage.getItem('tema');

  return (
    <div className={styles.container} id={'principal'}>
      <div className={styles.wrapperHeader}>
        <div className={styles.logo} onClick={handleLogoClick}>
          <Logo className={styles.logoIcone} />
          <span>Finant</span>
        </div>
        {!esconder && (
          <div className={styles.menus}>
            <div className={styles.menu} onClick={() => document.getElementById('funcionalidades')?.scrollIntoView({ behavior: 'smooth' })}>
              <span>Funcionalidades</span>
              <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>
            </div>
            <div className={styles.menu} onClick={() => document.getElementById('depoimentos')?.scrollIntoView({ behavior: 'smooth' })}>
              <span>Depoimentos</span>
              <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>
            </div>
            <div className={styles.menu} onClick={() => document.getElementById('perguntas')?.scrollIntoView({ behavior: 'smooth' })}>
              <span>Perguntas Frequentes</span>
              <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>
            </div>
          </div>
        )}
        {!esconder && (
          <div className={styles.acoes}>
            <div className={styles.temaContainer}>
              <span className={styles.tema + ' material-symbols-rounded'}>{tema === 'dark' ? 'dark_mode' : 'light_mode'}</span>
              <Switch checked={tema === 'dark'} onChange={(value) => handleAparencia(value ? 'dark' : 'light')} />
            </div>
            <Botao
              texto="Entrar"
              icone="login"
              onClick={onLogin}
              background="var(--background)"
              color="var(--text)"
              borderColor="var(--border)"
              iconPosition="right"
            />
            <Botao
              texto="Cadastrar"
              icone="rocket_launch"
              onClick={onCreateAccount}
              background="var(--primary)"
              color="var(--white)"
              borderColor="var(--primary)"
              iconPosition="right"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

