import styles from './Login.module.css';
import useAvisos from '../../hooks/useAviso';
import FormularioLogin from './formularioLogin/FormularioLogin';

type LoginProps = {
  onCriarConta: () => void;
  onRecuperarSenha: () => void;
};

export default function Login({ onCriarConta, onRecuperarSenha }: LoginProps) {
  const { avisos } = useAvisos();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <FormularioLogin onCriarConta={onCriarConta} onRecuperarSenha={onRecuperarSenha} />
        </div>
      </div>
    </div>
  );
}

