import { useEffect, useState } from 'react';
import styles from './Formulario.module.css';
import Botao from '../../../componentes/botao/Botao';
import ComboBox from '../../../componentes/inputs/ComboBox';
import TextField from '../../../componentes/inputs/TextField';
import MoneyField from '../../../componentes/inputs/MoneyField';
import { useTransacoes } from '../../../servicos/transacoes/useTransacoes';
import { Transacao } from '../../../servicos/transacoes/typeTransacao';
import { useContaContexto } from '../../../contextos/ContaContexto';
import { useCategoriaContexto } from '../../../contextos/CategoriaContexto';
import { useCartaoCreditoContexto } from '../../../contextos/CartaoCreditoContexto';
import Switch from '../../../componentes/switch/Switch';
import NumberField from '../../../componentes/inputs/NumberField';
import { set } from 'date-fns';
import DateField from '../../../componentes/inputs/DateField';
import useAvisos from '../../../hooks/useAviso';

const tiposPagamentos = [
  { valor: 'PIX', id: 'PIX' },
  { valor: 'Dinheiro', id: 'Dinheiro' },
  { valor: 'Cartão de Crédito', id: 'Cartão de Crédito' },
  { valor: 'Cartão de Debito', id: 'Cartão de Debito' },
  { valor: 'Boleto', id: 'Boleto' },
  { valor: 'Transferência', id: 'Transferência' },
  { valor: 'VR', id: 'VR' },
  { valor: 'VA', id: 'VA' }
];

type FormularioProps = {
  item?: Transacao | null;
  itens?: string[] | null;
  fecharModal: () => void;
  tipo: 'D' | 'R';
};

export default function Formulario({ item, itens, tipo, fecharModal }: FormularioProps) {
  const emLote = itens && itens.length > 0;

  const { contas } = useContaContexto();
  const { categorias } = useCategoriaContexto();
  const { cartoes } = useCartaoCreditoContexto();
  const { adicionarAviso } = useAvisos();

  const [dados, setDados] = useState({});
  const [data, setData] = useState(new Date().toLocaleDateString());
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState(0);
  const [pagamento, setPagamento] = useState('');
  const [conta, setConta] = useState('');
  const [cartaoCredito, setCartaoCredito] = useState('');
  const [categoria, setCategoria] = useState('');
  const [fixa, setFixa] = useState(false);
  const [mesesFixa, setMesesFixa] = useState(0);
  const [parcelada, setParcelada] = useState(false);
  const [parcelaAtual, setParcelaAtual] = useState(1);
  const [mesesParcelada, setMesesParcelada] = useState(0);
  const [alvoEdicao, setAlvoEdicao] = useState<'somenteEsta' | 'futuras' | 'todas'>('somenteEsta');
  const [step, setStep] = useState(0);

  const { loading, createTransacao, updateTransacao, bulkUpdateTransacao } = useTransacoes();

  const edicao = !!item;
  const opcaoContinuar = (fixa || parcelada) && edicao && !emLote && step === 0;

  const parseDate = (dateStr: string): Date => {
    const parts = dateStr.split('/');
    if (parts.length !== 3) new Date();

    const [day, month, year] = parts.map(Number);
    return new Date(year, month - 1, day); // Mês começa do 0 (Janeiro = 0)
  };

  const handleSalvar = async () => {
    if (!emLote) {
      if (!data || !descricao || !valor) {
        adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
        return;
      }

      if (fixa && !mesesFixa && !edicao && !emLote) {
        adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
        return;
      }

      if (parcelada && !mesesParcelada && !edicao && !emLote) {
        adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
        return;
      }

      if (edicao && opcaoContinuar && step === 0 && !emLote) {
        setStep(1);
        return;
      }
    }

    if (emLote) {
      await bulkUpdateTransacao(itens, categoria, conta, cartaoCredito, pagamento);
      fecharModal();
    } else {
      const regAtualizado = {
        ...dados,
        tipo: tipo,
        valor: valor,
        data: parseDate(data),
        descricao: descricao,
        tipo_pagamento: pagamento,
        conta_id: parseInt(conta),
        categoria_id: parseInt(categoria),
        cartao_credito_id: parseInt(cartaoCredito),
        eh_fixa: fixa,
        totalMeses: mesesFixa,
        eh_parcelada: parcelada,
        parcela: parcelaAtual,
        totalParcelas: mesesParcelada,
        modoEdicao: opcaoContinuar ? alvoEdicao : 'somenteEsta'
      };

      if (item && item.id !== undefined) {
        await updateTransacao(item.id, regAtualizado);
        fecharModal();
      } else {
        await createTransacao(regAtualizado);
        limparCampos();
      }
    }
  };

  const limparCampos = () => {
    setDescricao('');
    setValor(0);
    setFixa(false);
    setParcelada(false);
    setParcelaAtual(1);
    setMesesParcelada(0);
    setMesesFixa(0);
  };

  useEffect(() => {
    if (item) {
      setData(new Date(item.data).toLocaleDateString());
      setDescricao(item.descricao || '');
      setValor(item.valor || 0);
      setPagamento(item.tipo_pagamento || '');
      setConta(item.conta_id?.toString() || '');
      setCategoria(item.categoria_id?.toString() || '');
      setCartaoCredito(item.cartao_credito_id?.toString() || '');
      setFixa(item.eh_fixa || false);
      setParcelada(item.eh_parcelada || false);
      setParcelaAtual(item.parcela || 0);
      setMesesParcelada(item.totalParcelas || 0);
    }
  }, [item]);

  useEffect(() => {
    setDados({
      data,
      descricao,
      valor,
      tipo_pagamento: pagamento,
      conta_id: conta,
      categoria_id: categoria,
      cartao_credito_id: cartaoCredito,
      eh_fixa: fixa,
      eh_parcelada: parcelada,
      parcela: parcelaAtual,
      totalParcelas: mesesParcelada
    });
  }, [data, descricao, valor, pagamento, conta, cartaoCredito, categoria, fixa, parcelada, parcelaAtual, mesesParcelada]);

  useEffect(() => {
    if (fixa) {
      setParcelada(false);
      setParcelaAtual(1);
      setMesesParcelada(0);
    }
  }, [fixa]);

  useEffect(() => {
    if (parcelada) {
      setFixa(false);
      setMesesFixa(0);
    }
  }, [parcelada]);

  useEffect(() => {
    if (!pagamento) {
      return;
    }

    if (pagamento === 'Cartão de Crédito') {
      setConta('');

      if (cartoes.length === 1) {
        setCartaoCredito(cartoes[0].id?.toString() || '');
      }
    } else if (pagamento === 'Dinheiro') {
      setConta('');
      setCartaoCredito('');
    } else {
      setCartaoCredito('');
      if (contas.length === 1) {
        setConta(contas[0].id?.toString() || '');
      }
    }
  }, [pagamento]);

  const tiposContas = contas.map((conta) => ({ id: conta.id?.toString() || '', valor: conta.descricao }));
  const tiposCategorias = categorias
    .filter((categoria) => categoria.tipo === tipo)
    .map((categoria) => ({
      id: categoria.id?.toString() || '',
      valor: categoria.nome,
      icone: categoria.icone,
      cor: categoria.cor_identificacao
    }));
  const tiposCartoes = cartoes.map((cartao) => ({ id: cartao.id?.toString() || '', valor: cartao.nome }));
  const tipoEscrito = tipo === 'D' ? 'despesa' : tipo === 'R' ? 'receita' : 'transferência';

  const mostrarCartao = pagamento === 'Cartão de Crédito';
  const mostrarConta = !['', 'Cartão de Crédito', 'Dinheiro'].includes(pagamento);
  const mostrarMesesFixa = fixa && !edicao && !emLote;
  const mostrarParcela = parcelada && pagamento === 'Cartão de Crédito' && !edicao && !emLote;
  const labelBotao = () => {
    if (!edicao && !emLote) {
      return 'Adicionar ' + tipoEscrito;
    } else {
      return opcaoContinuar ? 'Continuar' : 'Alterar ' + tipoEscrito;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.paineis}>
        {step === 0 && (
          <div className={styles.campos}>
            {!emLote && (
              <DateField
                icone="event"
                label="Data"
                value={data}
                onChange={setData}
                placeholder={'Data da movimentação'}
                obrigatorio
                expandir
                range={false}
              />
            )}
            {!emLote && (
              <TextField
                icone="description"
                label="Identificação"
                value={descricao}
                onChange={setDescricao}
                placeholder="Descrição da movimentação"
                obrigatorio
              />
            )}
            {!emLote && (
              <MoneyField
                icone="attach_money"
                label="Valor"
                value={valor}
                onChange={setValor}
                placeholder="Valor da movimentação"
                obrigatorio
              />
            )}
            <ComboBox
              label="Categoria"
              value={categoria}
              onChange={setCategoria}
              icone={categoria ? categorias.find((cat) => cat.id?.toString() === categoria)?.icone : 'category'}
              placeholder="Categoria da movimentação"
              options={tiposCategorias}
            />
          </div>
        )}
        {step === 0 && (
          <div className={styles.campos}>
            <ComboBox
              label="Forma de pagamento"
              value={pagamento}
              onChange={setPagamento}
              icone={'payment'}
              placeholder="Forma de pagamento"
              options={tiposPagamentos}
            />
            {mostrarCartao && (
              <ComboBox
                label="Cartão de Crédito"
                value={cartaoCredito}
                onChange={setCartaoCredito}
                icone={'credit_card'}
                placeholder="Identificação do cartão"
                options={tiposCartoes}
              />
            )}
            {mostrarConta && (
              <ComboBox
                label="Conta"
                value={conta}
                onChange={setConta}
                icone={'account_balance'}
                placeholder="Conta bancária"
                options={tiposContas}
              />
            )}
            {!mostrarParcela && !edicao && !emLote && (
              <>
                <div className={styles.switch}>
                  <Switch checked={fixa} onChange={setFixa} label={'Recorrência mensal?'} />
                </div>
                {mostrarMesesFixa && (
                  <NumberField
                    icone="event"
                    label="Meses de recorrência"
                    value={mesesFixa}
                    onChange={setMesesFixa}
                    placeholder="Meses de recorrência"
                    min={1}
                    max={36}
                    obrigatorio
                  />
                )}
              </>
            )}
            {!mostrarMesesFixa && !edicao && !emLote && (
              <>
                {mostrarCartao && (
                  <div className={styles.switch}>
                    <Switch checked={parcelada} onChange={setParcelada} label={'Lançamento parcelado?'} />
                  </div>
                )}
                {mostrarParcela && (
                  <NumberField
                    icone="event"
                    label="Número da parcelas"
                    value={mesesParcelada}
                    onChange={setMesesParcelada}
                    placeholder="Número total de parcelas"
                    min={1}
                    max={12}
                    obrigatorio
                  />
                )}
              </>
            )}
          </div>
        )}
        {step === 1 && (
          <div className={styles.alvos}>
            <span className={styles.alvosTitulo}>
              Este lançamento é {fixa ? 'uma depesa fixa' : 'um parcelamento'}, você deseja editar:
            </span>
            <div className={styles.alvosOpcoes}>
              <div className={styles.switch} style={{ flex: 1 }}>
                <Switch
                  checked={alvoEdicao === 'somenteEsta'}
                  onChange={() => setAlvoEdicao('somenteEsta')}
                  label={'Somente a atual'}
                  style={{ alignItems: 'center' }}
                />
              </div>
              <div className={styles.switch} style={{ flex: 1 }}>
                <Switch
                  checked={alvoEdicao === 'futuras'}
                  onChange={() => setAlvoEdicao('futuras')}
                  label={'Todas as futuras'}
                  style={{ alignItems: 'center' }}
                />
              </div>
              <div className={styles.switch} style={{ flex: 1 }}>
                <Switch
                  checked={alvoEdicao === 'todas'}
                  onChange={() => setAlvoEdicao('todas')}
                  label={'Todas'}
                  style={{ alignItems: 'center' }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.acoes}>
        <Botao
          texto="Cancelar"
          icone={'close'}
          onClick={fecharModal}
          background="var(--background)"
          color="var(--text);"
          borderColor="var(--border)"
        />
        <Botao
          texto={labelBotao()}
          onClick={handleSalvar}
          background="var(--primary)"
          color="var(--white)"
          iconPosition={opcaoContinuar ? 'right' : 'left'}
          icone={opcaoContinuar ? 'arrow_forward' : 'check'}
          spin={loading}
          enabled={!loading}
        />
      </div>
    </div>
  );
}

