import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

ChartJS.register(ArcElement, Tooltip, Legend);

type Categoria = {
  icone: string;
  cor_identificacao: string;
  nome: string;
  valor: number;
  quantidade: number;
};

type GraficoRoscaProps = {
  categorias: Categoria[];
  cores: string[];
  tipo: 'R' | 'D';
  premium?: boolean;
};

const isNotebook = window.innerWidth <= 1366;

const GraficoRosca = ({ categorias, cores, tipo, premium }: GraficoRoscaProps) => {
  const chartRef = useRef<any>(null);
  const [textoCentral, setTextoCentral] = useState<string>(''); // Texto dinâmico no centro

  const corTexto = getComputedStyle(document.documentElement).getPropertyValue('--text');
  const corValor = getComputedStyle(document.documentElement).getPropertyValue('--textSecondary');

  const handleHover = (event: any, elements: any) => {
    if (elements.length > 0 && premium) {
      event.native.target.style.cursor = 'pointer'; // Cursor pointer no hover
      const index = elements[0].index;
      const categoria = categorias[index];

      // Calcular o total de valores
      const total = categorias.reduce((acc, cat) => acc + cat.valor, 0);

      // Calcular a porcentagem da categoria
      const porcentagem = ((categoria.valor / total) * 100).toFixed(2);

      // Montar o texto central com a porcentagem
      setTextoCentral(
        `${categoria.cor_identificacao}|${categoria.icone}|${categoria.nome}|${porcentagem}%|
        R$ ${categoria.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}|
        ${categoria.quantidade}`
      );
    } else {
      setTextoCentral('');
      event.native.target.style.cursor = 'default'; // Cursor default
    }
  };

  const hexToRgba = (hex: string, alpha: number): string => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const data = {
    labels: categorias.map((categoria) => categoria.nome),
    datasets: [
      {
        data: categorias.map((categoria) => categoria.valor),
        hoverBackgroundColor: cores.map((cor) => hexToRgba(cor, 1)), // Cor mais forte ao passar o mouse
        borderWidth: 4,
        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--background'),
        backgroundColor: cores.map((cor) => hexToRgba(cor, 0.8)),
        borderRadius: 8
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '90%', // Aumenta o tamanho do furo central
    onHover: handleHover, // Função de hover
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  };

  const corCategoria = textoCentral.split('|')[0];
  const iconeCategoria = textoCentral.split('|')[1];
  const textoCategoria = textoCentral.split('|')[2];
  const textoPorcentagem = textoCentral.split('|')[3];
  const textoValor = textoCentral.split('|')[4];
  const textoQuantidade = textoCentral.split('|')[5];
  const textoTipo =
    tipo === 'R' ? (parseFloat(textoQuantidade) > 1 ? 'receitas' : 'receita') : parseFloat(textoQuantidade) > 1 ? 'despesas' : 'despesa';

  return (
    <div style={{ height: isNotebook ? '240px' : '260px', position: 'relative' }}>
      <Doughnut ref={chartRef} data={data} options={options} id={uuidv4()} />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '4px'
        }}
      >
        {textoCategoria && (
          <div
            style={{
              background: hexToRgba(corCategoria, 0.1),
              fontSize: '12px',
              width: '36px',
              height: '36px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span className={`material-symbols-rounded ${premium ? '' : 'disabled'}`} style={{ color: corCategoria }}>
              {iconeCategoria}
            </span>
          </div>
        )}
        {textoCategoria && <div style={{ color: corTexto, fontSize: '12px' }}>{textoCategoria}</div>}
        {textoValor && <div style={{ color: corTexto, fontSize: '14px', fontWeight: 700 }}>{textoValor}</div>}
        {textoPorcentagem && (
          <div style={{ color: corTexto, fontSize: '12px', fontWeight: 700, paddingTop: '8px' }}>{textoPorcentagem}</div>
        )}
        {textoQuantidade && (
          <div style={{ color: corTexto, fontSize: '12px' }}>
            {textoQuantidade} {textoTipo}
          </div>
        )}
      </div>
    </div>
  );
};

export default GraficoRosca;

