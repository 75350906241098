import { createContext, useContext } from 'react';

type AtualizacaoContextType = {
  atualizar: boolean;
  setAtualizar: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AtualizacaoContexto = createContext<AtualizacaoContextType | undefined>(undefined);

export const useAtualizacaoContexto = () => {
  const context = useContext(AtualizacaoContexto);
  if (!context) {
    throw new Error('useAtualizacaoContexto deve ser usado dentro de um AtualizacaoContexto.Provider');
  }
  return context;
};

