import { createContext, useContext } from 'react';
import { Filtro } from '../servicos/filtro/typeFiltro';

type FiltroContextType = {
  filtros: Filtro;
  setFiltros: React.Dispatch<React.SetStateAction<Filtro>>;
};

export const FiltroContexto = createContext<FiltroContextType | undefined>(undefined);

export const useFiltroContexto = () => {
  const context = useContext(FiltroContexto);
  if (!context) {
    throw new Error('useFiltroContexto deve ser usado dentro de um FiltroContexto.Provider');
  }
  return context;
};

