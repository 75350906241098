import { useEffect, useState } from 'react';
import styles from './Perfil.module.css';
import { usePerfis } from '../../../servicos/perfis/usePerfil';
import { Perfil as TypePerfil } from '../../../servicos/perfis/typePerfil';
import Abas from '../../../componentes/abas/Abas';
import TextField from '../../../componentes/inputs/TextField';
import ComboBox from '../../../componentes/inputs/ComboBox';
import Botao from '../../../componentes/botao/Botao';
import useAvisos from '../../../hooks/useAviso';
import useFirebase from '../../../hooks/useFirebase';
import { useUsuarioContexto } from '../../../contextos/UsuarioContexto';
import { useAtualizacaoContexto } from '../../../contextos/AtualizacaoContexto';
import Switch from '../../../componentes/switch/Switch';
import usePagamento from '../../../servicos/pagamento/usePagamento';

const sexo = [
  { valor: 'M', texto: 'Masculino' },
  { valor: 'F', texto: 'Feminino' },
  { valor: 'O', texto: 'Outro' }
];

const aparencia = [
  { valor: 'light', texto: '#FFFFFF' },
  { valor: 'dark', texto: '#0C0E12' }
];

const cores = [
  { valor: '#1570EF', texto: '#1570EF' }, // Azul
  { valor: '#7F56D9', texto: '#7F56D9' }, // Roxo
  { valor: '#F472B6', texto: '#F472B6' }, // Rosa claro
  { valor: '#099250', texto: '#099250' }, // Verde
  { valor: '#F59E0B', texto: '#F59E0B' }, // Amarelo
  { valor: '#E04F16', texto: '#E04F16' } // Laranja
];

type PerfilProps = {
  onClose: () => void;
};

export default function Perfil({ onClose }: PerfilProps) {
  const [abas, setAbas] = useState<string[]>([]);
  const [abaSelecionada, setAbaSelecionada] = useState<string>('Meus detalhes');
  const { salvando, perfil, updatePerfil } = usePerfis();
  const [dados, setDados] = useState<TypePerfil>(perfil);
  const [senhaAtual, setSenhaAtual] = useState<string>('');
  const [novaSenha, setNovaSenha] = useState<string>('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState<string>('');
  const { usuario } = useUsuarioContexto();
  const { loading: loadingSenha, changePassword } = useFirebase();
  const { atualizar, setAtualizar } = useAtualizacaoContexto();
  const { cancelar } = usePagamento();

  const premium = perfil.subscription_status === 'active';

  const { adicionarAviso } = useAvisos();

  const handleSalvarInformacoes = async () => {
    if (!perfil || !perfil.id) {
      return;
    }

    await updatePerfil(perfil.id, { ...dados });

    onClose();
  };

  const handleSalvarSenha = async () => {
    if (!perfil || !perfil.id || !novaSenha || !confirmacaoSenha || !senhaAtual) {
      return;
    }

    if (!senhaAtual) {
      adicionarAviso('Informe a senha atual', 'erro');
      return;
    }

    if (novaSenha !== confirmacaoSenha) {
      adicionarAviso('Senhas não conferem', 'erro');
      return;
    }

    await changePassword(senhaAtual, novaSenha);

    setSenhaAtual('');
    setNovaSenha('');
    setConfirmacaoSenha('');

    onClose();
  };

  useEffect(() => {
    setDados(perfil);
  }, [perfil]);

  useEffect(() => {
    if (!usuario) {
      return;
    }

    const providerId = usuario.data?.providerData[0]?.providerId;

    if (providerId === 'google.com') {
      setAbas(['Meus detalhes', 'Preferências', 'Assinatura']);
      return;
    }

    if (premium) {
      setAbas(['Meus detalhes', 'Senhas', 'Preferências', 'Assinatura']);
      return;
    }

    setAbas(['Meus detalhes', 'Senhas', 'Preferências']);
  }, [premium, usuario]);

  const handleAparencia = (value: string) => {
    const root = document.documentElement;
    root.setAttribute('data-theme', value);
    localStorage.setItem('tema', value);
    setAtualizar(!atualizar);
  };

  const handleCor = (value: string) => {
    const root = document.documentElement;
    root.style.setProperty('--primary', value);
    root.style.setProperty('--secondary', value + '10');
    localStorage.setItem('cor', value);
    setAtualizar(!atualizar);
  };

  const handleCancelarAssinatura = async () => {
    cancelar();
  };

  const senhasPreenchidas = novaSenha !== '' && confirmacaoSenha !== '' && senhaAtual !== '';

  const optionsSexo = sexo.map((s) => ({ id: s.valor, valor: s.texto }));

  const tema = localStorage.getItem('tema');
  const cor = localStorage.getItem('cor');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painel}>
          <Abas abas={abas} abaSelecionada={abaSelecionada} setAbaSelecionada={setAbaSelecionada} />
          {abaSelecionada === 'Meus detalhes' && (
            <div className={styles.formularios}>
              <div className={styles.formulario}>
                <TextField label="Nome" value={dados.nome || ''} onChange={(value) => setDados({ ...dados, nome: value })} icone="person" />
                <ComboBox
                  label="Sexo"
                  value={dados.sexo || ''}
                  onChange={(value) => setDados({ ...dados, sexo: value })}
                  icone="wc"
                  options={optionsSexo}
                />
                <TextField
                  label="Telefone"
                  value={dados.telefone || ''}
                  onChange={(value) => setDados({ ...dados, telefone: value })}
                  icone="phone"
                />
                <TextField
                  label="Nacionalidade"
                  value={dados.nacionalidade || ''}
                  onChange={(value) => setDados({ ...dados, nacionalidade: value })}
                  icone="public"
                />
              </div>

              <div className={styles.botoes}>
                <Botao
                  texto="Cancelar"
                  icone={'close'}
                  onClick={onClose}
                  background="var(--background)"
                  color="var(--text);"
                  borderColor="var(--border)"
                />
                <Botao
                  texto={'Salvar'}
                  icone={'check'}
                  spin={salvando}
                  background="var(--primary)"
                  color="var(--white)"
                  borderColor="var(--primary)"
                  onClick={handleSalvarInformacoes}
                />
              </div>
            </div>
          )}
          {abaSelecionada === 'Senhas' && (
            <div className={styles.formularios}>
              <div className={styles.formularioSenha}>
                <TextField label="Senha Atual" value={senhaAtual} onChange={(value) => setSenhaAtual(value)} password obrigatorio />
                <TextField label="Nova Senha" value={novaSenha} onChange={(value) => setNovaSenha(value)} password obrigatorio />
                <TextField
                  label="Confirmação de Senha"
                  value={confirmacaoSenha}
                  onChange={(value) => setConfirmacaoSenha(value)}
                  password
                  obrigatorio
                />
              </div>
              <div className={styles.botoes}>
                <Botao
                  texto="Cancelar"
                  icone={'close'}
                  onClick={onClose}
                  background="var(--background)"
                  color="var(--text);"
                  borderColor="var(--border)"
                />
                <Botao
                  texto={'Alterar Senha'}
                  icone={'check'}
                  spin={loadingSenha}
                  background="var(--primary)"
                  color="var(--white)"
                  enabled={senhasPreenchidas}
                  onClick={handleSalvarSenha}
                />
              </div>
            </div>
          )}
          {abaSelecionada === 'Preferências' && (
            <div className={styles.formularios}>
              <div className={styles.formularioSenha}>
                {/* <div className={styles.campoPersonalizado}>
                  <span className={styles.label}>Tema</span>
                  <div className={styles.cores}>
                    {aparencia.map((a) => (
                      <div
                        key={a.valor}
                        style={{
                          backgroundColor: a.texto,
                          borderColor: tema === a.valor ? 'var(--primary)' : 'var(--border)'
                        }}
                        className={styles.cor}
                        onClick={() => handleAparencia(a.valor)}
                      />
                    ))}
                  </div>
                </div> */}
                <Switch label="Modo escuro" checked={tema === 'dark'} onChange={(value) => handleAparencia(value ? 'dark' : 'light')} />
                <div className={styles.campoPersonalizado}>
                  <span className={styles.label}>Cor do tema</span>
                  <div className={styles.cores}>
                    {cores.map((a) => (
                      <div
                        key={a.valor}
                        style={{
                          backgroundColor: a.texto,
                          borderColor: cor === a.valor ? 'var(--primary)' : 'var(--border)'
                        }}
                        className={styles.cor}
                        onClick={() => handleCor(a.valor)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {abaSelecionada === 'Assinatura' && (
            <div className={styles.formularios}>
              <div className={styles.formularioSenha}>
                <div className={styles.assinaturaTitulo}>
                  Você é um usuário <b style={{ color: 'var(--yellow)' }}>Plus</b>.
                </div>
                <div className={styles.assinaturaSubtitulo}>Seus benefícios incluem:</div>
                <div className={styles.beneficios}>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Todas as funcionalidades</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Acesso exclusivo a novas funcionalidades</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Dashboards avançados</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Prioridade alta no suporte</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Controle de metas de receitas e despesas</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Contas bancárias ilimitadas</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Cartões de crédito ilimitados</span>
                  </div>
                  <div className={styles.beneficio}>
                    <span className={`material-symbols-rounded ${styles.beneficioIcone}`}>check</span>
                    <span className={styles.beneficioTexto}>Categorias ilimitadas</span>
                  </div>
                </div>
                <div className={styles.recorrencia}>
                  Recorrencia de cobrança mensal de <b style={{ color: 'var(--primary)' }}>R$ 9,90</b>.
                </div>
              </div>
              <div className={styles.botoes}>
                <Botao
                  texto="Cancelar Assinatura"
                  icone="close"
                  background="var(--error)"
                  color="var(--white)"
                  borderColor="var(--border)"
                  onClick={handleCancelarAssinatura}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

