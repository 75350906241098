import { useEffect, useState } from 'react';
import styles from './LandingBase.module.css';

const depoimentos = [
  {
    texto: `O Finant mudou minha <b style="color: var(--yellow)">vida financeira</b>. Agora sei onde cortar pra <b style="color: var(--yellow)">economizar</b>.`,
    nome: 'João Almeida',
    cargo: 'Engenheiro de Dados',
    imagem: require('../../images/person1.jpg')
  },
  {
    texto: `Com o Finant, finalmente consegui entender onde estava perdendo dinheiro todo mês. <b style="color: var(--yellow)">Organização real</b>!`,
    nome: 'Camila Menezes',
    cargo: 'Analista Financeira',
    imagem: require('../../images/person2.jpg')
  },
  {
    texto: `Eu achava que planilhas eram suficientes. <b style="color: var(--yellow)">Finant me surpreendeu</b> pela praticidade.`,
    nome: 'Ricardo Tavares',
    cargo: 'Autônomo',
    imagem: require('../../images/person3.jpg')
  },
  {
    texto: `Nunca consegui acompanhar meus gastos no cartão de crédito. <b style="color: var(--yellow)">Agora tudo faz sentido</b>.`,
    nome: 'Fernanda Lopes',
    cargo: 'Publicitária',
    imagem: require('../../images/person4.jpg')
  },
  {
    texto: `Testei vários apps de finanças. O Finant foi o único que <b style="color: var(--yellow)">realmente me ajudou</b> a entender meu dinheiro.`,
    nome: 'Marcelo Dutra',
    cargo: 'Empresário',
    imagem: require('../../images/person5.jpg')
  }
];

const Testimonial = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndex((prev) => (prev + 1) % depoimentos.length);
    }, 6000); // 6 segundos

    return () => clearInterval(intervalo);
  }, []);

  const atual = depoimentos[index];

  return (
    <section id="depoimentos" className={styles.container} style={{ backgroundColor: 'var(--light-gray)' }}>
      <div className={styles.wrapperFeatures}>
        <span className={styles.dica}>Depoimentos</span>
        <span className={styles.titulo} style={{ fontWeight: 700 }} dangerouslySetInnerHTML={{ __html: `"${atual.texto}"` }} />
        <div className={styles.declaracao}>
          <img src={atual.imagem} alt={atual.nome} className={styles.foto} />
          <span className={styles.autor}>{atual.nome}</span>
          <span className={styles.cargo}>{atual.cargo}</span>
        </div>
        <div className={styles.balas}>
          {depoimentos.map((_, i) => (
            <div key={i} className={`${styles.bala} ${index === i ? styles.balaAtiva : ''}`} onClick={() => setIndex(i)} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;

