import { useState } from 'react';
import styles from './Painel.module.css';
import PopUp from '../../../componentes/popup/PopUp';
import Premium from '../../cadastros/premium/Premium';

type PainelProps = {
  id?: string;
  children: React.ReactNode;
  onClick?: () => void;
  flex: number;
  barraRolagem?: boolean;
  premium?: boolean;
};

const Painel = ({ id, children, flex, onClick, barraRolagem, premium }: PainelProps) => {
  const [abrirPremium, setAbrirPremium] = useState(false);

  return (
    <div
      className={styles.container}
      style={{
        flex,
        paddingRight: barraRolagem ? '8px' : '24px',
        userSelect: premium === false ? 'none' : 'auto'
      }}
      id={id}
    >
      {abrirPremium && (
        <PopUp onClose={() => setAbrirPremium(false)} titulo="Planos" subtitulo="Selecione o melhor plano para você">
          <Premium onClose={() => setAbrirPremium(false)} />
        </PopUp>
      )}
      {premium === false && (
        <div className={styles.bloqueioContainer} onClick={() => setAbrirPremium(true)}>
          <div className={styles.bloqueioWrapper}>
            <span className={`${styles.bloqueioIcone} material-symbols-rounded`}>lock</span>
            <span className={styles.bloqueioTexto}>Disponível apenas no Plano Plus.</span>
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.corpo}>{children}</div>
        {onClick && (
          <div className={styles.rodape} onClick={onClick}>
            <span className={styles.botao}>Ver mais</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Painel;

