import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CartaoCredito from './telas/cadastros/cartaoCredito/CartaoCredito';
import Categoria from './telas/cadastros/categoria/Categoria';
import Dashboard from './telas/dashboard/Dashboard';
import Conta from './telas/cadastros/conta/Conta';
import Transacao from './telas/cadastros/transacao/Transacao';
import Configuracao from './telas/configuracao/Configuracao';
import { ContaContexto } from './contextos/ContaContexto';
import { useEffect, useState } from 'react';
import { Conta as TipoConta } from './servicos/contas/typeConta';
import { CategoriaContexto } from './contextos/CategoriaContexto';
import { Categoria as TipoCategoria } from './servicos/categorias/typeCategoria';
import { TransacaoContexto } from './contextos/TransacaoContexto';
import { Transacao as TipoTransacao } from './servicos/transacoes/typeTransacao';
import { PerfilContexto } from './contextos/PerfilContexto';
import { Perfil as TipoPerfil } from './servicos/perfis/typePerfil';
import { AvisoContexto, AvisoType } from './contextos/AvisoContexto';
import { CartaoCredito as TipoCartao } from './servicos/cartaoCredito/typeCartaoCredito';
import { CartaoCreditoContexto } from './contextos/CartaoCreditoContexto';
import { Filtro as TipoFiltro } from './servicos/filtro/typeFiltro';
import { Usuario } from './servicos/usuarios/typeUsuario';
import Landing from './telas/landing/Landing';
import Termos from './telas/landing/termos/Termos';
import { auth } from './index';
import { UsuarioContexto } from './contextos/UsuarioContexto';
import { AtualizacaoContexto } from './contextos/AtualizacaoContexto';
import { FiltroContexto } from './contextos/FiltroContexto';
import Checkout from './telas/checkout/Checkout';
import Importacao from './telas/importacoes/Importacao';
import Avisos from './telas/estruturais/aviso/Avisos';

const filtroFinal = new Date(); // Hoje
const filtroInicial = new Date();
filtroInicial.setMonth(filtroFinal.getMonth() - 1);
filtroInicial.setDate(filtroFinal.getDate()); // Mantém o mesmo dia

export default function Rotas() {
  const [contas, setContas] = useState<TipoConta[]>([]);
  const [categorias, setCategorias] = useState<TipoCategoria[]>([]);
  const [transacoes, setTransacoes] = useState<TipoTransacao[]>([]);
  const [perfil, setPerfil] = useState<TipoPerfil>({});
  const [avisos, setAvisos] = useState<AvisoType[]>([]);
  const [cartoes, setCartoes] = useState<TipoCartao[]>([]);
  const [usuario, setUsuario] = useState<Usuario>({
    data: null
  });
  const [filtros, setFiltros] = useState<TipoFiltro>({
    dashboard: { dataInicial: filtroInicial, dataFinal: filtroFinal },
    movimentacao: { dataInicial: filtroInicial, dataFinal: filtroFinal }
  });
  const [atualizar, setAtualizar] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUsuario({ data: user });
      }
    });

    const root = document.documentElement;
    const tema = localStorage.getItem('tema');
    const cor = localStorage.getItem('cor');
    root.setAttribute('data-theme', tema || 'light');
    root.style.setProperty('--primary', cor || '#1570EF');
    root.style.setProperty('--secondary', cor + '10' || '#1570EF10');

    return () => unsubscribe();
  }, []);

  return (
    <BrowserRouter>
      <AtualizacaoContexto.Provider value={{ atualizar, setAtualizar }}>
        <UsuarioContexto.Provider value={{ usuario, setUsuario }}>
          <PerfilContexto.Provider value={{ perfil, setPerfil }}>
            <ContaContexto.Provider value={{ contas, setContas }}>
              <CategoriaContexto.Provider value={{ categorias, setCategorias }}>
                <TransacaoContexto.Provider value={{ transacoes, setTransacoes }}>
                  <AvisoContexto.Provider value={{ avisos, setAvisos }}>
                    <CartaoCreditoContexto.Provider value={{ cartoes, setCartoes }}>
                      <FiltroContexto.Provider value={{ filtros, setFiltros }}>
                        <Avisos>
                          <Routes>
                            <Route path="/cartoes" element={<CartaoCredito />} />
                            <Route path="/categorias" element={<Categoria />} />
                            <Route path="/contas" element={<Conta />} />
                            <Route path="/" element={<Landing />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/movimentacoes" element={<Transacao />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/configuracoes" element={<Configuracao />} />
                            <Route path="/termos-e-politicas" element={<Termos />} />
                            <Route path="/importacoes" element={<Importacao />} />
                          </Routes>
                        </Avisos>
                      </FiltroContexto.Provider>
                    </CartaoCreditoContexto.Provider>
                  </AvisoContexto.Provider>
                </TransacaoContexto.Provider>
              </CategoriaContexto.Provider>
            </ContaContexto.Provider>
          </PerfilContexto.Provider>
        </UsuarioContexto.Provider>
      </AtualizacaoContexto.Provider>
    </BrowserRouter>
  );
}

