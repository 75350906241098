import { useCategoriaContexto } from '../../../contextos/CategoriaContexto';
import { usePerfilContexto } from '../../../contextos/PerfilContexto';
import GraficoLinhasMultiplas, { Categoria, Grupo } from '../graficos/GraficoLinhasMultiplas';
import Painel from '../painel/Painel';
import styles from './DashCategoriaDetalhes.module.css';

type Props = {
  transacoes: any[];
  tipo: 'R' | 'D';
  categoriaSelecionada?: number;
  setCategoriaSelecionada?: (categoria: number) => void;
};

type Transacao = {
  id: number;
  data: string;
  valor: number;
  categoria_id: number;
};

const DashCategoriaDetalhes = ({ transacoes, tipo, categoriaSelecionada, setCategoriaSelecionada }: Props) => {
  const { categorias } = useCategoriaContexto();
  const { perfil } = usePerfilContexto();
  const transacoesFiltro = transacoes.filter((t) => {
    if (categoriaSelecionada === undefined) return t.tipo === tipo;
    if (categoriaSelecionada === null) return t.tipo === tipo && (t.categoria_id === null || t.categoria_id === undefined);
    return t.tipo === tipo && t.categoria_id === categoriaSelecionada;
  });
  const transacoesConvertidaParaObjeto = transacoesFiltro.map<Transacao>((transacao) => ({
    id: transacao.id,
    data: transacao.data,
    valor: transacao.valor,
    categoria_id: transacao.categoria_id
  }));

  // Calcular o período entre a primeira e a última transação
  const obterPeriodoEmDias = () => {
    if (transacoesConvertidaParaObjeto.length === 0) return 0;
    const datas = transacoesConvertidaParaObjeto.map((t) => new Date(t.data).getTime());
    const minData = Math.min(...datas);
    const maxData = Math.max(...datas);
    return Math.ceil((maxData - minData) / (1000 * 60 * 60 * 24));
  };

  const periodoEmDias = obterPeriodoEmDias();
  const agrupamento = periodoEmDias > 31 ? 'mes' : 'dia';

  const transacoesAgrupadasData = transacoesConvertidaParaObjeto.reduce(
    (acc, transacao) => {
      let data;
      if (agrupamento === 'dia') {
        const dataObj = new Date(transacao.data);
        const dia = dataObj.toLocaleDateString('pt-BR', { day: '2-digit' });
        const mes = dataObj.toLocaleDateString('pt-BR', { month: '2-digit' });
        const ano = dataObj.toLocaleDateString('pt-BR', { year: 'numeric' });
        data = `${ano}/${mes}/${dia}`; // Formato YYYY/MM/DD
      } else {
        const dataObj = new Date(transacao.data);
        const mesAno = dataObj.toLocaleDateString('pt-BR', { month: '2-digit', year: 'numeric' });
        data = `${mesAno.split('/')[1]}/${mesAno.split('/')[0]}`; // Formato MM/YYYY
      }

      if (!acc[data]) {
        acc[data] = [];
      }

      acc[data].push(transacao);
      return acc;
    },
    {} as Record<string, Transacao[]>
  );

  const agrupamentoDataCategoria = Object.entries(transacoesAgrupadasData).map(([data, transacoes]) => {
    const lista = Object.values(
      transacoes.reduce(
        (acc, transacao) => {
          const categoria = categorias.find((cat) => cat.id === transacao.categoria_id);
          const categoriaNome = categoria?.nome || 'Sem categoria';
          const categoriaCor = categoria?.cor_identificacao || '#17B26A';
          const valor = parseFloat(transacao.valor.toString());

          if (!acc[categoriaNome]) {
            acc[categoriaNome] = { nome: categoriaNome, valor: 0, cor: categoriaCor, data };
          }

          acc[categoriaNome].valor += valor;
          return acc;
        },
        {} as Record<string, Categoria>
      )
    ).sort((a, b) => b.valor - a.valor); // Ordena os valores do maior para o menor

    return {
      data,
      categorias: lista
    };
  });

  return (
    <Painel flex={1} barraRolagem id={'detalhes_categoria'} premium={perfil.subscription_status === 'active'}>
      <div className={styles.graficosWrapper}>
        <span className={styles.titulo}>Detalhes por categoria</span>
        {transacoes.length === 0 && (
          <div className={styles.semTransacoes}>
            <span>Nenhum registro encontrado</span>
          </div>
        )}
        <div className={styles.graficoCategorias}>
          <GraficoLinhasMultiplas categorias={agrupamentoDataCategoria} agrupamento={agrupamento} />
        </div>
      </div>
    </Painel>
  );
};

export default DashCategoriaDetalhes;

