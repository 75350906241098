import { useState } from 'react';
import styles from './Premium.module.css';
import Botao from '../../../componentes/botao/Botao';
import usePagamento from '../../../servicos/pagamento/usePagamento';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

type Props = {
  onClose: () => void;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string, {});

const Premium = ({ onClose }: Props) => {
  const [telaCheckout, setTelaCheckout] = useState(false);

  const { checkout: fetchClientSecret } = usePagamento();

  const handlePagamento = async () => {
    setTelaCheckout(true);
  };

  const handleTermos = () => {
    window.open('https://www.finant.com.br/termos-e-politicas', '_blank');
  };

  const priceId = process.env.REACT_APP_STRIPE_PRICE_ID as string;

  const options = {
    fetchClientSecret: () => fetchClientSecret(priceId)
  };

  return (
    <div className={styles.container}>
      {telaCheckout ? (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options as any}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <div className={styles.wrapper}>
          {/* <div className={styles.switchs}>
            <div
              className={styles.switch}
              onClick={() => handlePeriodo('anual')}
              style={{ 
                backgroundColor: periodo === 'mensal' ? 'var(--background)' : '',
                borderColor: periodo === 'mensal' ? 'var(--border)' : ''
              }}
            >
              Mensal
            </div>
            <div
              className={styles.switch}
              onClick={() => handlePeriodo('mensal')}
              style={{
                backgroundColor: periodo === 'anual' ? 'var(--background)' : '',
                borderColor: periodo === 'anual' ? 'var(--border)' : ''
              }}
            >
              Anual <span style={{ fontSize: '12px', fontWeight: 500, color: 'var(--textSecondary)', paddingLeft: '4px' }}>20% off</span>
            </div>
          </div> */}
          <div className={styles.opcoes}>
            <div className={styles.painel}>
              <div className={styles.cabecalho}>
                <div className={styles.infos}>
                  <span className={styles.titulo}>Plano Gratuito</span>
                  <span className={styles.subtitulo}>Para quem quer um controle básico das finanças.</span>
                </div>
                <div className={styles.valores}>
                  <span className={styles.valor}>R$ 0.00</span>
                  <span className={styles.label}>/ {'mês'}</span>
                </div>
              </div>
              <div className={styles.detalhes}>
                <div className={styles.detalhesTitulo}>Funcionalidades</div>
                <div className={styles.detalhesSubtitulo}>
                  Tudo que o plano <b>grátis</b> possui:
                </div>
                <div className={styles.funcionalidades}>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Funcionalidades <b>básicas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Dashboards <b>básicos</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Prioridade <b>normal</b> no suporte
                      </span>
                    </div>
                  </div>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Contas bancárias <b>ilimitadas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Cartões de crédito <b>ilimitadas</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Categorias <b>ilimitadas</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Botao
                texto={'Seu plano'}
                background="var(--primary)"
                color="var(--white)"
                style={{ flex: 1 }}
                icone="check"
                enabled={false}
                onClick={() => null}
              />
            </div>
            <div className={styles.painel}>
              <div className={styles.cabecalho}>
                <div className={styles.infos}>
                  <span className={styles.titulo}>
                    <span className="material-symbols-rounded" style={{ color: 'var(--yellow)' }}>
                      crown
                    </span>
                    Plano Plus
                  </span>
                  <span className={styles.subtitulo}>Para quem quer um controle mais avançado das finanças.</span>
                </div>
                <div className={styles.valores}>
                  <span className={styles.valor}>R$ 9.90</span>
                  <span className={styles.label}>/ {'mês'}</span>
                </div>
              </div>
              <div className={styles.detalhes}>
                <div className={styles.detalhesTitulo}>Funcionalidades</div>
                <div className={styles.detalhesSubtitulo}>
                  Tudo que o plano <b>grátis</b> possui, mais:
                </div>
                <div className={styles.funcionalidades}>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        14 dias de <b>teste grátis</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Dashboards <b>avançados</b>
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>
                        Prioridade <b>alta</b> no suporte
                      </span>
                    </div>
                  </div>
                  <div className={styles.lista}>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>Gastos e receitas por categoria</span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>Metas de gastos e receitas</span>
                    </div>
                    <div className={styles.item}>
                      <span className={`${styles.icone} material-symbols-rounded`}>check</span>
                      <span>Cancele a qualquer momento</span>
                    </div>
                  </div>
                </div>
              </div>
              <Botao
                onClick={() => handlePagamento()}
                texto={'Continuar'}
                icone={'arrow_forward'}
                iconPosition="right"
                background="var(--primary)"
                color="var(--white)"
                style={{ flex: 1 }}
              />
            </div>
          </div>
          <div className={styles.termos}>
            <span className={styles.termosTexto}>
              Ao assinar o plano <b>Plus</b>, você concorda com nossos{' '}
              <b style={{ color: 'var(--primary)', cursor: 'pointer' }} onClick={() => handleTermos()}>
                Termos de Serviço
              </b>{' '}
              e{' '}
              <b style={{ color: 'var(--primary)', cursor: 'pointer' }} onClick={() => handleTermos()}>
                Política de Privacidade
              </b>
              .
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Premium;

