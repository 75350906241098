import { valorParaTexto } from '../../../utils/Utils';
import styles from './GraficoPercentual.module.css';

type Meta = {
  id: number;
  descricao: string;
  valor: number;
  valor_meta: number;
  cor: string;
  icone: string;
};

type Props = {
  metas: Meta[];
  premium?: boolean;
  onClick?: (categoriaId: number) => void;
};

const hexToRgba = (hex: string, alpha: number): string => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const GraficoPercentual = ({ metas, premium, onClick }: Props) => {
  return (
    <div className={styles.container}>
      {metas
        .filter((meta) => meta.valor_meta > 0)
        .map((meta, index) => {
          const percentual = (meta.valor / meta.valor_meta) * 100;
          const valor = meta.valor.toFixed(2);
          const valorMeta = meta.valor_meta.toFixed(2);
          const corFundo = hexToRgba(meta.cor, 0.1);

          return (
            <div key={index} className={styles.item} onClick={() => onClick && onClick(meta.id)}>
              <div className={styles.iconeContainer} style={{ backgroundColor: corFundo }}>
                <span className={'material-symbols-rounded'} style={{ color: meta.cor }}>
                  {meta.icone}
                </span>
              </div>
              <div className={styles.infos}>
                <div className={styles.titulo}>
                  <div className={styles.descricao}>
                    <span>{meta.descricao}</span>
                  </div>
                  {premium ? (
                    <span className={styles.valor}>
                      {valorParaTexto(valor)} de {valorParaTexto(valorMeta)}
                    </span>
                  ) : (
                    <span className={styles.valor}>* de *</span>
                  )}
                </div>
                <div className={styles.barraWrapper}>
                  <div className={styles.barraContainer} style={{ backgroundColor: corFundo }}>
                    <div
                      className={styles.barra}
                      style={{
                        width: `${percentual <= 100 ? percentual.toString() : '100'}%`,
                        backgroundColor: hexToRgba(meta.cor, 0.5)
                      }}
                    />
                  </div>
                  <div className={styles.percentual}>
                    {premium ? <span>{percentual > 0 && `${percentual.toFixed(2)}%`}</span> : <span>**.**%</span>}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default GraficoPercentual;

