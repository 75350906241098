import GraficoBarrasDuplas from '../graficos/GraficoBarrasDuplas';
import Painel from '../painel/Painel';
import styles from './DashFluxoCaixa.module.css';

type DashFluxoCaixaProps = {
  transacoes: any[];
};

const DashFluxoCaixa = ({ transacoes }: DashFluxoCaixaProps) => {
  const corPositivo = getComputedStyle(document.documentElement).getPropertyValue('--positive');
  const corNegativo = getComputedStyle(document.documentElement).getPropertyValue('--negative');

  const transacoesOrdenadas = transacoes.sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());

  return (
    <Painel flex={0.6} id="fluxo">
      <div className={styles.graficosWrapper}>
        <span className={styles.titulo}>Evolução das receitas e despesas</span>
        <GraficoBarrasDuplas transacoes={transacoesOrdenadas} cor1={corPositivo} cor2={corNegativo} />
      </div>
    </Painel>
  );
};

export default DashFluxoCaixa;

