import MenuLateral from '../menuLateral/MenuLateral';
import PainelCentral from '../painelCentral/PainelCentral';
import styles from './Pagina.module.css';
import Loader from '../../loader/Loader';
import { usePerfis } from '../../../servicos/perfis/usePerfil';
import Guia from '../../../componentes/boasVindas/Guia';
import { useContas } from '../../../servicos/contas/useContas';
import { useCartaoCreditos } from '../../../servicos/cartaoCredito/useCartaoCredito';
import { useCategorias } from '../../../servicos/categorias/useCategorias';
import Checkout from '../../checkout/Checkout';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Pagina(props: { loading?: boolean; children: React.ReactNode }) {
  const { loading: loadingPerfil } = usePerfis();
  const { loading: loadingContas } = useContas();
  const { loading: loadingCreditos } = useCartaoCreditos();
  const { loading: loadingCategorias } = useCategorias();

  const loading = loadingPerfil || loadingContas || loadingCreditos || loadingCategorias || props.loading;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const checkout = query.get('checkout');

  useEffect(() => {
    // Cria o script para a conversão
    const conversionScript = document.createElement('script');
    conversionScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-11189895703');
    gtag('event', 'conversion', {
      send_to: 'AW-11189895703/w-q1CLnI4a8aEJeE4dcp',
      value: 1.0,
      currency: 'BRL'
    });
  `;
    document.head.appendChild(conversionScript);
  }, []);

  return (
    <div className={styles.container}>
      {checkout && <Checkout />}
      {loading && !checkout ? <Loader /> : <Guia />}
      <div className={styles.wrapper}>
        <MenuLateral />
        <div className={styles.conteudo}>
          <PainelCentral>{props.children}</PainelCentral>
        </div>
      </div>
    </div>
  );
}

