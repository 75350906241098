import styles from './CheckBox.module.css';

type CheckBoxProps = {
  checked: boolean;
  setChecked?: (checked: boolean) => void;
  enabled?: boolean;
};

export default function CheckBox({ checked = false, enabled = true, setChecked }: CheckBoxProps) {
  return (
    <div className={styles.container} onClick={() => enabled && setChecked && setChecked(!checked)} style={{ opacity: enabled ? 1 : 0.5 }}>
      <div className={`${styles.checkbox} ${checked ? styles.checked : ''} ${enabled ? styles.enabled : ''} `}>
        {checked && <span className={styles.icone + ' material-symbols-rounded'}>check</span>}
      </div>
    </div>
  );
}

