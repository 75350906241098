import React, { useState, useEffect, useRef } from 'react';
import styles from './Guide.module.css';

type Props = {
  guias: { target: string; mensagem: string }[];
  onClose: () => void;
};

const padding = 10;

const Guide = ({ guias, onClose }: Props) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [loaded, setLoaded] = useState(false);
  const [etapa, setEtapa] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const target = guias[etapa]?.target;
  const message = guias[etapa]?.mensagem;

  const messageTop = position.top + position.height + padding + 16;
  const windowHeight = window.innerHeight;

  // Verifica se a mensagem vai ficar fora da tela na parte inferior
  const isOverflowingBottom = messageTop + 50 > windowHeight; // 50px é a altura aproximada da mensagem
  const messagePosition = isOverflowingBottom ? 'top' : 'bottom';

  useEffect(() => {
    const handleScrollEnd = () => {
      const element = document.querySelector(target);
      if (element) {
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY;
        const left = rect.left + window.scrollX;

        setPosition({
          top: top,
          left: left,
          width: rect.width,
          height: rect.height
        });
        setLoaded(true);
      }
    };

    setTimeout(() => {
      const element = document.querySelector(target);
      if (element) {
        // Garante que o elemento esteja visível na tela e centralizado
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
        handleScrollEnd();
      }
    }, 30); // Pequeno delay para garantir o layout completo

    return () => {
      window.removeEventListener('scroll', handleScrollEnd);
    };
  }, [target]);

  useEffect(() => {
    //se clicar fora
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setEtapa(-1);
        onClose();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const clipPath = `
    polygon(
      0% 0%, 100% 0%, 100% 100%, 0% 100%,
      0% ${position.top - padding}px,
      ${position.left - padding}px ${position.top - padding}px,
      ${position.left - padding}px ${position.top + position.height + padding}px,
      ${position.left + position.width + padding}px ${position.top + position.height + padding}px,
      ${position.left + position.width + padding}px ${position.top - padding}px,
      0% ${position.top - padding}px
    )
  `;

  const handleAnterior = () => {
    if (etapa > 0) {
      setEtapa(etapa - 1);
    }
  };

  const handleProximo = () => {
    if (etapa < guias.length - 1) {
      setEtapa(etapa + 1);
    } else {
      setEtapa(-1);
      onClose();
    }
  };

  return (
    <>
      {/* Overlay escura com clip-path */}
      {loaded && <div className={styles.overlay} style={{ clipPath }}></div>}

      {/* Área destacada clicável */}
      {loaded && (
        <div
          className={styles.highlight}
          style={{
            top: position.top - padding,
            left: position.left - padding,
            width: position.width + 2 * padding,
            height: position.height + 2 * padding
          }}
          ref={ref}
        >
          <div
            className={styles.message}
            style={{
              top: messagePosition === 'bottom' ? position.height + padding + 16 : 'auto',
              bottom: messagePosition === 'top' ? position.height + padding + 16 : 'auto'
            }}
          >
            <span>{message}</span>
            <div className={styles.next}>
              {etapa > 0 ? (
                <div onClick={handleAnterior} className={styles.botao}>
                  Voltar
                </div>
              ) : (
                <div />
              )}
              <span className={styles.botao} onClick={handleProximo} style={{ backgroundColor: 'var(--primary)', color: 'var(--white)' }}>
                {etapa < guias.length - 1 ? 'Próximo' : 'Fechar'}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Guide;

