import { useRef, useState } from 'react';
import Pagina from '../estruturais/pagina/Pagina';
import { TituloPagina } from '../estruturais/tituloPagina/TituloPagina';
import styles from './Importacao.module.css';
import useImportacao from '../../servicos/importacao/useImportacao';
import DataTable, { Coluna } from '../../componentes/datatable/DataTable';
import { valorParaTexto } from '../../utils/Utils';
import useAvisos from '../../hooks/useAviso';

const colunas: Coluna[] = [
  { nome: 'Id', visivel: false },
  { nome: 'Tipo', tamanho: 30, alinhamento: 'center' },
  { nome: 'Descricao' },
  { nome: 'Data', tamanho: 100, alinhamento: 'center' },
  { nome: 'Valor', tamanho: 150, alinhamento: 'center' }
];

const Importacao = () => {
  const [lista, setLista] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { loading, importar } = useImportacao();
  const [dragActive, setDragActive] = useState(false);
  const { adicionarAviso } = useAvisos();
  const dragCounter = useRef(0);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleFile = async (file: File) => {
    if (loading) {
      adicionarAviso('Aguarde o processamento do arquivo atual.', 'erro');
      return;
    }

    try {
      const retorno = await importar(file);

      if (typeof retorno === 'string') {
        adicionarAviso(retorno, 'erro');
        return;
      }

      if (!Array.isArray(retorno)) {
        adicionarAviso('Resposta inválida do servidor.', 'erro');
        return;
      }

      if (retorno.length === 0) {
        adicionarAviso('Nenhuma transação importada.', 'alerta');
        return;
      }

      setLista(retorno);
      adicionarAviso('Arquivo importado com sucesso.', 'sucesso');
    } catch (error: any) {
      adicionarAviso('Erro ao processar o arquivo.', 'erro');
      console.error(error);
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event.target.files.length > 0) {
      await handleFile(event.target.files[0]);
      event.target.value = ''; // limpa o input
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current += 1;
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current -= 1;

    if (dragCounter.current === 0) {
      setDragActive(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setDragActive(false);

    if (e.dataTransfer.files.length > 0) {
      await handleFile(e.dataTransfer.files[0]);
    }
  };

  const dados = (lista || [])
    .sort((a, b) => new Date(b.data).getTime() - new Date(a.data).getTime())
    .map((transacao) => [
      { valor: transacao.id },
      {
        valor: '',
        icone: transacao.tipo === 'D' ? 'trending_down' : 'trending_up',
        cor: transacao.tipo === 'D' ? '#D92D20' : '#17B26A'
      },
      { valor: transacao.descricao },
      { valor: new Date(transacao.data).toLocaleDateString() },
      { valor: valorParaTexto(transacao.valor) }
    ]);

  return (
    <Pagina>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <TituloPagina
            titulo="Importar transações"
            subtitulo="Selecione ou arraste um arquivo OFX para importar suas movimentações bancárias."
          >
            <></>
          </TituloPagina>

          <div
            className={`${styles.inputWrapper} ${dragActive ? styles.dragOver : ''}`}
            onClick={handleClick}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className={styles.iconContainer}>
              <span className={`${styles.uploadIcone} material-symbols-rounded ${loading && 'spin'}`}>
                {loading ? 'progress_activity' : 'backup'}
              </span>
            </div>
            <span className={styles.label}>
              <b style={{ color: 'var(--primary)' }}>Clique ou arraste</b> um arquivo para importar
            </span>
            <span className={styles.labelSecundaria}>Formato suportado: .ofx (Open Financial Exchange)</span>
            <span className={styles.labelSecundaria} style={{ color: 'var(--warning)' }}>
              Importações duplicadas serão ignoradas.
            </span>
            <input type="file" accept=".ofx" onChange={handleFileChange} className={styles.fileInput} ref={inputRef} />
          </div>

          <div className={styles.resultList}>
            <DataTable colunas={colunas} linhas={dados} />
          </div>
        </div>
      </div>
    </Pagina>
  );
};

export default Importacao;

