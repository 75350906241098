import Questions from './Questions';
import Features from './Features';
import Footer from './Footer';
import FreeTrial from './FreeTrial';
import Header from './Header';
import Main from './Main';
import Testimonial from './Testimonial';
import { useState } from 'react';
import PopUp from '../../componentes/popup/PopUp';
import Login from '../login/Login';
import CriarConta from '../login/criarConta/CriarConta';
import RecuperarSenha from '../login/recuperarSenha/RecuperarSenha';
import Price from './Price';

export default function Landing() {
  const [mostrarLogin, setMostrarLogin] = useState(false);
  const [mostrarCriarConta, setMostrarCriarConta] = useState(false);
  const [mostrarRecuperarSenha, setMostrarRecuperarSenha] = useState(false);
  const [atualizar, setAtualizar] = useState(false);

  const handleLogin = () => {
    setMostrarRecuperarSenha(false);
    setMostrarCriarConta(false);
    setMostrarLogin(true);
  };

  const handleCreateAccount = () => {
    setMostrarRecuperarSenha(false);
    setMostrarLogin(false);
    setMostrarCriarConta(true);
  };

  const handleRecuperarSenha = () => {
    setMostrarLogin(false);
    setMostrarCriarConta(false);
    setMostrarRecuperarSenha(true);
  };

  return (
    <div>
      {mostrarLogin && (
        <PopUp titulo="Bem vindo!" subtitulo="Informe seus dados para continuar" onClose={() => setMostrarLogin(false)}>
          <Login onCriarConta={handleCreateAccount} onRecuperarSenha={handleRecuperarSenha} />
        </PopUp>
      )}
      {mostrarCriarConta && (
        <PopUp titulo="Criar conta" subtitulo="Informe seus dados para continuar" onClose={() => setMostrarCriarConta(false)}>
          <CriarConta onClick={handleLogin} />
        </PopUp>
      )}
      {mostrarRecuperarSenha && (
        <PopUp
          titulo="Recuperar senha"
          subtitulo="Informe seu e-mail para recuperar a senha"
          onClose={() => setMostrarRecuperarSenha(false)}
        >
          <RecuperarSenha onClick={handleLogin} />
        </PopUp>
      )}
      <Header onLogin={handleLogin} onCreateAccount={handleCreateAccount} atualizar={atualizar} onAtualizar={setAtualizar} />
      <Main onClick={handleCreateAccount} atualizar={atualizar} />
      <Features />
      <Price onClick={handleCreateAccount} />
      <Testimonial />
      <Questions />
      <FreeTrial onClick={handleCreateAccount} />
      <Footer />
    </div>
  );
}

