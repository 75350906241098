import Pagina from '../estruturais/pagina/Pagina';
import styles from './Dashboard.module.css';
import { useEffect, useState } from 'react';
import { useTransacoes } from '../../servicos/transacoes/useTransacoes';
import { TituloPagina } from '../estruturais/tituloPagina/TituloPagina';
import Botao from '../../componentes/botao/Botao';
import { Pesquisa } from '../../componentes/pesquisa/Pesquisa';
import { usePerfilContexto } from '../../contextos/PerfilContexto';
import DateField from '../../componentes/inputs/DateField';
import { Transacao } from '../../servicos/transacoes/typeTransacao';
import { useCategoriaContexto } from '../../contextos/CategoriaContexto';
import DashReceitas from './paineis/DashReceitas';
import DashDespesas from './paineis/DashDespesas';
import DashFluxoCaixa from './paineis/DashFluxoCaixa';
import DashUltimasTransacoes from './paineis/DashUltimasTransacoes';
import DashCategoria from './paineis/DashCategoria';
import DashMetas from './paineis/DashMetas';
import Guide from '../../componentes/guia/Guide';
import { useFiltroContexto } from '../../contextos/FiltroContexto';
import Abas from '../../componentes/abas/Abas';
import DashCategoriaDetalhes from './paineis/DashCategoriaDetalhes';

const guias = [
  {
    mensagem:
      'Utilize a barra de filtros para selecionar um período específico e visualizar as movimentações financeiras desse intervalo. Escolha datas personalizadas ou use os atalhos rápidos para facilitar a análise.',
    target: '#pesquisa'
  },
  {
    mensagem:
      'Aqui você acompanha as receitas totais registradas ao longo do tempo. Visualize o crescimento financeiro e identifique meses com maior volume de ganhos.',
    target: '#receitas'
  },
  {
    mensagem:
      'Esta seção mostra as despesas totais ao longo do tempo. Monitore os períodos de maior gasto e identifique padrões que podem ajudar no planejamento financeiro.',
    target: '#despesas'
  },
  {
    mensagem:
      'O fluxo de caixa mostra a diferença entre receitas e despesas em cada período. Utilize para identificar meses com saldo positivo ou negativo e ajustar sua estratégia financeira.',
    target: '#fluxo'
  },
  {
    mensagem:
      'Aqui você encontra as últimas movimentações financeiras registradas. Verifique as transações recentes e mantenha o controle do que foi cadastrado.',
    target: '#ultimas'
  },
  {
    mensagem:
      'Navegue entre as abas para visualizar diferentes aspectos de suas finanças. Cada aba traz informações específicas que ajudam a entender melhor seus gastos e receitas.',
    target: '#abas'
  },
  {
    mensagem:
      'Esta visualização categoriza suas receitas ou despesas, mostrando quanto cada fonte representa no total. Use para identificar as principais fontes de entrada ou saida de dinheiro.',
    target: '#categoria'
  },
  {
    mensagem:
      'Acompanhe as metas de receita ou despesa e veja o quanto já foi alcançado. Utilize para manter o foco nos objetivos financeiros e celebrar suas conquistas.',
    target: '#meta'
  }
];

const isNotebook = window.innerWidth <= 1366;

export default function Dashboard() {
  const { perfil } = usePerfilContexto();
  const { filtros, setFiltros } = useFiltroContexto();
  const { categorias } = useCategoriaContexto();

  const { loading, fetchTransacoes } = useTransacoes(false);
  const [transacoes, setTransacoes] = useState<Transacao[]>([]);
  const [mostrarGuia, setMostrarGuia] = useState(false);

  const [categoriaSelecionada, setCategoriaSelecionada] = useState<number | undefined>(undefined);

  const [abas, setAbas] = useState<string[]>(['Despesas', 'Receitas']);
  const [abaSelecionada, setAbaSelecionada] = useState('Despesas');

  useEffect(() => {
    const buscarTransacoes = async () => {
      const colecao = await fetchTransacoes({ dataInicial: filtros.dashboard.dataInicial, dataFinal: filtros.dashboard.dataFinal });
      setTransacoes(colecao || []);
    };

    buscarTransacoes();
  }, [filtros.dashboard]);

  const handleFiltrar = async (dataInicial: Date, dataFinal: Date) => {
    setFiltros({ ...filtros, dashboard: { dataInicial: new Date(dataInicial), dataFinal: new Date(dataFinal) } });
  };

  const handlePreFiltro = (dias: number) => {
    const dataFinal = new Date();
    const dataInicial = new Date(dataFinal);
    dataInicial.setDate(dataFinal.getDate() - dias);
    handleFiltrar(dataInicial, dataFinal);
  };

  const handleAbaSelecionada = (aba: string) => {
    setAbaSelecionada(aba);
    setCategoriaSelecionada(undefined);
  };

  const welcomeMessage = perfil.nome ? `Bem-vindo(a), ${perfil.nome.split(' ')[0]}` : 'Bem-vindo(a)';
  const transReceitas = transacoes.filter((t) => t.tipo === 'R');
  const transDespesas = transacoes.filter((t) => t.tipo === 'D');
  const transUltimas = transacoes
    .filter((t) => t.createdAt)
    .sort((a, b) => (b.id ?? 0) - (a.id ?? 0))
    .slice(0, 10);

  const totalDias = Math.floor((filtros.dashboard.dataFinal.getTime() - filtros.dashboard.dataInicial.getTime()) / (1000 * 60 * 60 * 24));

  return (
    <Pagina loading={loading}>
      <div className={styles.container}>
        {mostrarGuia && <Guide guias={guias} onClose={() => setMostrarGuia(false)} />}
        <div className={styles.wrapper}>
          <TituloPagina titulo={welcomeMessage} subtitulo="Saiba exatamente para onde seu dinheiro está indo e como otimizar seus gastos.">
            <></>
          </TituloPagina>
          <Pesquisa>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <div className={styles.prefiltros}>
                <div className={styles.prefiltro} onClick={() => handlePreFiltro(365)}>
                  <span>12 meses</span>
                </div>
                <div className={styles.prefiltro} onClick={() => handlePreFiltro(180)}>
                  <span>6 meses</span>
                </div>
                <div className={styles.prefiltro} onClick={() => handlePreFiltro(30)}>
                  <span>30 dias</span>
                </div>
                <div className={styles.prefiltro} onClick={() => handlePreFiltro(7)}>
                  <span>7 dias</span>
                </div>
                <div className={styles.prefiltro} onClick={() => handlePreFiltro(1)}>
                  <span>24 horas</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                <DateField
                  value={`${filtros.dashboard.dataInicial.toLocaleDateString()} - ${filtros.dashboard.dataFinal.toLocaleDateString()}`}
                  onSubmit={handleFiltrar}
                  icone="event"
                  placeholder="Data"
                  range
                  expandir
                  disabled
                />
                <Botao
                  texto={isNotebook ? '' : 'Ajuda'}
                  hint="Guia"
                  onClick={() => setMostrarGuia(true)}
                  background="var(--background)"
                  color="var(--text)"
                  borderColor="var(--border)"
                  icone="help"
                  iconPosition="right"
                />
              </div>
            </div>
          </Pesquisa>
          <div className={styles.dashboards}>
            <div className={styles.dashboardLinha}>
              <DashReceitas transacoes={transReceitas} />
              <DashDespesas transacoes={transDespesas} />
            </div>
            <div className={styles.dashboardLinha}>
              <DashFluxoCaixa transacoes={transacoes} />
              <DashUltimasTransacoes transacoes={transUltimas} />
            </div>
            <div style={{ paddingTop: '16px' }} id="abas">
              <Abas abas={abas} abaSelecionada={abaSelecionada} setAbaSelecionada={handleAbaSelecionada} />
            </div>
            {abaSelecionada === 'Receitas' && (
              <>
                <div className={styles.dashboardLinha}>
                  <DashCategoria
                    transacoes={transacoes}
                    tipo="R"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                  <DashMetas
                    transacoes={transacoes}
                    categorias={categorias}
                    totalDias={totalDias}
                    tipo="R"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                </div>
                <div className={styles.dashboardLinha}>
                  <DashCategoriaDetalhes
                    transacoes={transacoes}
                    tipo="R"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                </div>
              </>
            )}
            {abaSelecionada === 'Despesas' && (
              <>
                <div className={styles.dashboardLinha}>
                  <DashCategoria
                    transacoes={transacoes}
                    tipo="D"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                  <DashMetas
                    transacoes={transacoes}
                    categorias={categorias}
                    totalDias={totalDias}
                    tipo="D"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                </div>
                <div className={styles.dashboardLinha}>
                  <DashCategoriaDetalhes
                    transacoes={transacoes}
                    tipo="D"
                    categoriaSelecionada={categoriaSelecionada}
                    setCategoriaSelecionada={setCategoriaSelecionada}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Pagina>
  );
}

