import { useCategoriaContexto } from '../../../contextos/CategoriaContexto';
import Painel from '../painel/Painel';
import styles from './DashUltimasTransacoes.module.css';

type DashUltimasTransacoesProps = {
  transacoes: any[];
};

const DashUltimasTransacoes = ({ transacoes }: DashUltimasTransacoesProps) => {
  const { categorias } = useCategoriaContexto();

  const hexToRgba = (hex: string, alpha: number): string => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <Painel flex={0.4} barraRolagem id="ultimas">
      <div className={styles.graficosWrapper}>
        <span className={styles.titulo}>Ultimas transações</span>
        <div className={styles.transacoesWrapper}>
          {transacoes.length === 0 ? (
            <div className={styles.semTransacoes}>
              <span>Nenhum registro encontrado</span>
            </div>
          ) : (
            transacoes.map((transacao) => {
              const categoria = categorias.find((c) => c.id === transacao.categoria_id);
              const icone = categoria?.icone || 'category';
              const cor = categoria?.cor_identificacao || '#17B26A';
              const tipoCor = transacao.tipo === 'R' ? '#17B26A' : '#D92D20';

              return (
                <div key={transacao.id} className={styles.transacao}>
                  <span
                    className={`material-symbols-rounded ${styles.transacaoCategoria}`}
                    style={{ color: cor, backgroundColor: `${hexToRgba(cor, 0.1)}` }}
                  >
                    {icone}
                  </span>
                  <div className={styles.transacaoInfo}>
                    <span className={styles.transacaoDescricao}>{transacao.descricao}</span>
                    <span className={styles.transacaoData}>{new Date(transacao.data).toLocaleDateString()}</span>
                  </div>
                  <span className={styles.transacaoValor}>
                    {`R$ ${parseFloat(transacao.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    <span className={`material-symbols-rounded ${styles.transacaoTipo}`} style={{ color: tipoCor }}>
                      {transacao.tipo === 'R' ? 'trending_up' : 'trending_down'}
                    </span>
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </Painel>
  );
};

export default DashUltimasTransacoes;

