import styles from './Pesquisa.module.css';

export const Pesquisa = (props: { children: React.ReactNode }) => {
  return (
    <div className={styles.container} id="pesquisa">
      <div className={styles.wrapper}>{props.children}</div>
    </div>
  );
};

