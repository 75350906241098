import { useEffect, useRef, useState } from 'react';
import styles from './ComboBox.module.css';
import TextField from './TextField';

type ComboBoxProps = {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  icone?: string;
  options: ComboBoxItem[];
  obrigatorio?: boolean;
  maxWidth?: string;
};

export type ComboBoxItem = {
  id: string;
  valor: string;
  icone?: string;
  cor?: string;
};

const ComboBox = ({ label, maxWidth, value, onChange, placeholder, icone, options, obrigatorio }: ComboBoxProps) => {
  const [expandido, setExpandido] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setExpandido(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleOpcao = (id: string) => {
    onChange(id);
    setExpandido(false);
  };

  const valor = options.find((option) => option.id.toLowerCase() === value.toLowerCase())?.valor || '';
  const cor = options.find((option) => option.id.toLowerCase() === value.toLowerCase())?.cor || '';

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.input} onClick={() => setExpandido(!expandido)} style={{ gap: label ? '6px' : '0px' }}>
        <div className={styles.labelContainer}>
          <span className={styles.label}>{label}</span>
          {obrigatorio && <span className={styles.obrigatorio}>*</span>}
        </div>
        <div className={styles.wrapperValores}>
          {icone && (
            <span className={`material-symbols-rounded ${styles.icone}`} style={{ color: cor }}>
              {icone}
            </span>
          )}
          <span
            className={styles.valor}
            style={{ maxWidth: maxWidth ? maxWidth : '', color: valor !== '' ? 'var(--text)' : 'var(--placeholder)', flex: 1 }}
          >
            {valor || placeholder}
          </span>
          <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>
        </div>
      </div>
      {expandido && (
        <div className={styles.options}>
          {value !== '' && !obrigatorio && (
            <div onClick={() => handleOpcao('')} className={styles.option}>
              {''}
            </div>
          )}
          {options.map((option) => (
            <div key={option.id} onClick={() => handleOpcao(option.id)} className={styles.option}>
              {option.icone && (
                <span className={'material-symbols-rounded ' + styles.icone} style={{ color: option.cor }}>
                  {option.icone}
                </span>
              )}
              {option.valor}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ComboBox;

