import { useEffect, useState } from 'react';
import styles from './Formulario.module.css';
import Botao from '../../../componentes/botao/Botao';
import { useCartaoCreditos } from '../../../servicos/cartaoCredito/useCartaoCredito';
import { CartaoCredito } from '../../../servicos/cartaoCredito/typeCartaoCredito';
import ComboBox from '../../../componentes/inputs/ComboBox';
import TextField from '../../../componentes/inputs/TextField';
import NumberField from '../../../componentes/inputs/NumberField';
import MoneyField from '../../../componentes/inputs/MoneyField';
import useAvisos from '../../../hooks/useAviso';

const bandeiras = [
  { id: 'Visa', valor: 'Visa' },
  { id: 'Mastercard', valor: 'Mastercard' },
  { id: 'Elo', valor: 'Elo' },
  { id: 'American Express', valor: 'American Express' },
  { id: 'Diners Club', valor: 'Diners Club' },
  { id: 'Outros', valor: 'Outros' }
];

type FormularioProps = {
  item: CartaoCredito | null;
  fecharModal: () => void;
};

export default function Formulario({ item, fecharModal }: FormularioProps) {
  const [dados, setDados] = useState<CartaoCredito>({
    limite: 0,
    nome: '',
    bandeira: '',
    conta_id: undefined,
    data_fechamento: 1,
    data_vencimento: 31,
    saldo: undefined
  });

  const [nome, setNome] = useState('');
  const [bandeira, setBandeira] = useState('');
  const [limite, setLimite] = useState(0);
  const [data_fechamento, setDataFechamento] = useState(0);
  const [data_vencimento, setDataVencimento] = useState(0);

  const { loading, createCartaoCredito, updateCartaoCredito } = useCartaoCreditos();

  const { adicionarAviso } = useAvisos();

  const handleSalvar = async () => {
    if (!bandeira || !nome || !limite || !data_fechamento || !data_vencimento) {
      adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
      return;
    }

    const regAtualizado = {
      ...dados,
      limite: limite
    };

    if (item && item.id !== undefined) {
      await updateCartaoCredito(item.id, regAtualizado);
    } else {
      await createCartaoCredito(regAtualizado);
    }

    fecharModal();
  };

  useEffect(() => {
    if (item) {
      setNome(item.nome);
      setBandeira(item.bandeira || '');
      setLimite(item.limite || 0);
      setDataFechamento(item.data_fechamento || 0);
      setDataVencimento(item.data_vencimento || 0);
    }
  }, [item]);

  useEffect(() => {
    setDados({ ...dados, nome, bandeira, limite, data_fechamento, data_vencimento });
  }, [nome, bandeira, limite, data_fechamento, data_vencimento]);

  return (
    <div className={styles.container}>
      <div className={styles.campos}>
        <ComboBox
          label="Bandeira"
          value={dados.bandeira}
          onChange={setBandeira}
          options={bandeiras}
          placeholder="Bandeira do cartão"
          icone="credit_card"
          obrigatorio
        />
        <TextField
          icone="description"
          label="Identificação"
          value={dados.nome}
          onChange={setNome}
          placeholder="Nome do cartão"
          obrigatorio
        />
        <MoneyField
          icone="attach_money"
          label="Limite"
          value={dados.limite}
          onChange={setLimite}
          placeholder="Limite disponível no cartão"
          obrigatorio
        />
        <NumberField
          icone="event"
          label="Dia do fechamento"
          value={dados.data_fechamento}
          onChange={setDataFechamento}
          placeholder="Dia do fechamento da fatura"
          min={1}
          max={31}
          obrigatorio
        />
        <NumberField
          icone="event"
          label="Dia do vencimento"
          value={dados.data_vencimento}
          onChange={setDataVencimento}
          placeholder="Dia do vencimento da fatura"
          min={1}
          max={31}
          obrigatorio
        />
      </div>
      <div className={styles.acoes}>
        <Botao
          texto="Cancelar"
          icone={'close'}
          onClick={fecharModal}
          background="var(--background)"
          color="var(--text);"
          borderColor="var(--border)"
        />
        <Botao
          texto={item ? 'Alterar cartão' : 'Adicionar cartão'}
          onClick={handleSalvar}
          background="var(--primary)"
          color="var(--white)"
          icone="check"
          spin={loading}
          enabled={!loading}
        />
      </div>
    </div>
  );
}

