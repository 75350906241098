import { useNavigate } from 'react-router-dom';
import Botao from '../../componentes/botao/Botao';
import styles from './LandingBase.module.css';

const FreeTrial = ({ onClick = () => {} }) => {
  return (
    <section id="comecar" className={styles.container} style={{ backgroundColor: 'var(--light-gray)' }}>
      <div className={styles.wrapperFeatures}>
        <span className={styles.titulo}>Ta esperando o que?</span>
        <span className={styles.subtitulo}>
          Use o Finant <b style={{ color: 'var(--yellow)' }}>grátis por 14 dias</b> e descubra como é fácil tomar o controle do seu dinheiro
          de verdade.
        </span>
        <Botao
          texto="Crie sua conta gratuita"
          onClick={onClick}
          background="var(--primary)"
          color="var(--white)"
          borderColor="var(--primary)"
          iconPosition="right"
        />
      </div>
    </section>
  );
};

export default FreeTrial;

