import styles from './Abas.module.css';

type AbasProps = {
  abas: string[];
  abaSelecionada: string;
  setAbaSelecionada: (aba: string) => void;
};

export default function Abas(props: AbasProps) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {props.abas.map((aba) => (
          <div
            key={aba}
            className={`${styles.aba} ${props.abaSelecionada === aba ? styles.abaSelecionada : ''}`}
            onClick={() => props.setAbaSelecionada(aba)}
          >
            {aba}
          </div>
        ))}
      </div>
    </div>
  );
}

