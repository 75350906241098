import Pagina from '../../estruturais/pagina/Pagina';
import styles from './Transacao.module.css';
import { useTransacoes } from '../../../servicos/transacoes/useTransacoes';
import { valorParaTexto } from '../../../utils/Utils';
import { useEffect, useState } from 'react';
import { TituloPagina } from '../../estruturais/tituloPagina/TituloPagina';
import Botao from '../../../componentes/botao/Botao';
import { Pesquisa } from '../../../componentes/pesquisa/Pesquisa';
import TextField from '../../../componentes/inputs/TextField';
import DataTable, { Coluna } from '../../../componentes/datatable/DataTable';
import PopUp from '../../../componentes/popup/PopUp';
import FormularioTransacao from './Formulario';
import { useContaContexto } from '../../../contextos/ContaContexto';
import { useCategoriaContexto } from '../../../contextos/CategoriaContexto';
import ComboBox from '../../../componentes/inputs/ComboBox';
import DateField from '../../../componentes/inputs/DateField';
import Guide from '../../../componentes/guia/Guide';
import { useCartaoCreditoContexto } from '../../../contextos/CartaoCreditoContexto';
import { useFiltroContexto } from '../../../contextos/FiltroContexto';
import Abas from '../../../componentes/abas/Abas';

const colunas: Coluna[] = [
  { nome: 'Id', visivel: false },
  { nome: 'Tipo', tamanho: 30, alinhamento: 'center' },
  { nome: 'Descricao' },
  { nome: 'Data', tamanho: 100, alinhamento: 'center' },
  { nome: 'Pagamento', tamanho: 150, alinhamento: 'center' },
  { nome: 'Categoria', tamanho: 100, alinhamento: 'center' },
  { nome: 'Valor', tamanho: 150, alinhamento: 'right' }
];

const guias = [
  {
    mensagem:
      'Para registrar uma nova movimentação financeira, clique no botão "Receita" para adicionar um ganho ou no botão "Despesa" para registrar um gasto.',
    target: '#adicionar'
  },
  {
    mensagem:
      'Utilize a barra de pesquisa para encontrar movimentações específicas rapidamente. Isso facilita a visualização e edição de registros antigos.',
    target: '#pesquisa'
  },
  {
    mensagem:
      'Clique no ícone de edição para modificar os detalhes da movimentação, como descrição, valor, data, categoria e outras informações.',
    target: '#editar'
  }
];

const isNotebook = window.innerWidth <= 1366;

export default function Transacao() {
  const [abaSelecionada, setAbaSelecionada] = useState('Despesas');
  const { loading, transacoes, fetchTransacoes, deleteTransacao } = useTransacoes();
  const { contas } = useContaContexto();
  const { cartoes } = useCartaoCreditoContexto();
  const { categorias } = useCategoriaContexto();
  const [selecionada, setSelecionada] = useState<any>(null);
  const [tipo, setTipo] = useState<'D' | 'R'>('D');
  const [abrirEdicao, setAbrirEdicao] = useState(false);
  const [abrirExclusao, setAbrirExclusao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [tipoFiltro, setTipoFiltro] = useState('D');
  const [pagamentoFiltro, setPagamentoFiltro] = useState('');
  const [categoriaFiltro, setCategoriaFiltro] = useState('');
  const [contaFiltro, setContaFiltro] = useState('');
  const [cartaoFiltro, setCartaoFiltro] = useState('');
  const [mostrarGuia, setMostrarGuia] = useState(false);
  const [selecionados, setSelecionados] = useState<string[]>([]);

  const { filtros, setFiltros } = useFiltroContexto();

  const dados = transacoes
    .filter((item) => {
      return [
        () => pesquisa === '' || item.descricao?.toLowerCase().includes(pesquisa.toLowerCase()),
        () => tipoFiltro === '' || item.tipo === tipoFiltro,
        () => pagamentoFiltro === '' || item.tipo_pagamento === pagamentoFiltro,
        () => categoriaFiltro === '' || item.categoria_id?.toString() === categoriaFiltro,
        () => contaFiltro === '' || item.conta_id?.toString() === contaFiltro,
        () => cartaoFiltro === '' || item.cartao_credito_id?.toString() === cartaoFiltro
      ].every((filtro) => filtro());
    })
    .sort((a, b) => {
      return new Date(b.data).getTime() - new Date(a.data).getTime();
    })
    .map((transacao) => [
      { valor: transacao.id },
      {
        valor: '',
        icone: transacao.tipo === 'D' ? 'trending_down' : 'trending_up',
        cor: transacao.tipo === 'D' ? '#D92D20' : '#17B26A'
      },
      { valor: transacao.descricao },
      { valor: new Date(transacao.data).toLocaleDateString() },
      { valor: transacao.tipo_pagamento },
      {
        valor: transacao.categoria_id ? categorias.find((categoria) => categoria.id === transacao.categoria_id)?.nome : '',
        cor: transacao.categoria_id ? categorias.find((categoria) => categoria.id === transacao.categoria_id)?.cor_identificacao : '',
        icone: transacao.categoria_id ? categorias.find((categoria) => categoria.id === transacao.categoria_id)?.icone : ''
      },
      { valor: valorParaTexto(transacao.valor) }
    ]);

  const handleNovo = (tipo: 'D' | 'R') => {
    setAbrirEdicao(true);
    setSelecionada(null);
    setTipo(tipo);
  };

  const handleAbrirEdicao = (id: number) => {
    setAbrirEdicao(true);
    setSelecionada(transacoes.find((item) => item.id === id));
    setSelecionados([]);
  };

  const handleAbrirEdicaoSelecionados = () => {
    if (selecionados.length > 0) {
      setAbrirEdicao(true);
    }
  };

  const handleSelecionarLinha = (id: string) => {
    if (selecionados.includes(id)) {
      setSelecionados(selecionados.filter((item) => item !== id));
    } else {
      setSelecionados([...selecionados, id]);
    }
  };

  const handleSelecionarTodos = () => {
    if (selecionados.length === dados.length) {
      setSelecionados([]);
    } else {
      setSelecionados(dados.map((item) => item[0].valor?.toString() || ''));
    }
  };

  const handleAbrirExclusao = () => {
    setAbrirExclusao(true);
  };

  const handleApagar = () => {
    if (selecionados.length > 0) {
      deleteTransacao(selecionados);
      handleCancelar();
    }
  };

  const handleCancelar = () => {
    setAbrirEdicao(false);
    setAbrirExclusao(false);
    setSelecionada(null);
    setSelecionados([]);
    setTipo(abaSelecionada === 'Despesas' ? 'D' : 'R');
  };

  const handleLimparFiltros = () => {
    setPesquisa('');
    setPagamentoFiltro('');
    setCategoriaFiltro('');
    setCartaoFiltro('');
    setContaFiltro('');
  };

  const handleSelecionarAba = (aba: string) => {
    setAbaSelecionada(aba);
    setTipoFiltro(aba === 'Despesas' ? 'D' : 'R');
    setSelecionados([]);
    setTipo(aba === 'Despesas' ? 'D' : 'R');
  };

  useEffect(() => {
    const buscarTransacoes = async () => {
      fetchTransacoes({ dataInicial: filtros.movimentacao.dataInicial, dataFinal: filtros.movimentacao.dataFinal });
    };

    buscarTransacoes();
  }, [filtros.movimentacao]);

  const handleFiltrar = (dataInicial: Date, dataFinal: Date) => {
    setFiltros({ ...filtros, movimentacao: { dataInicial: new Date(dataInicial), dataFinal: new Date(dataFinal) } });
  };

  const opcoesPagamento = [
    { valor: 'PIX', id: 'PIX' },
    { valor: 'Dinheiro', id: 'Dinheiro' },
    { valor: 'Cartão de Crédito', id: 'Cartão de Crédito' },
    { valor: 'Cartão de Debito', id: 'Cartão de Debito' },
    { valor: 'Boleto', id: 'Boleto' },
    { valor: 'Transferência', id: 'Transferência' },
    { valor: 'VR', id: 'VR' },
    { valor: 'VA', id: 'VA' }
  ];

  const opcoesCategoria = categorias
    .filter((categoria) => categoria.tipo === tipo)
    .map((categoria) => ({
      id: categoria.id?.toString() || '',
      valor: categoria.nome,
      icone: categoria.icone,
      cor: categoria.cor_identificacao
    }));
  const opcoesConta = contas.map((conta) => ({ id: conta.id?.toString() || '', valor: conta.descricao }));
  const opcoesCartao = cartoes.map((cartao) => ({ id: cartao.id?.toString() || '', valor: cartao.nome }));
  const iconeCategoria = categorias.find((categoria) => categoria.id?.toString() === categoriaFiltro)?.icone || 'category';

  return (
    <Pagina loading={loading}>
      <div className={styles.container}>
        {mostrarGuia && <Guide guias={guias} onClose={() => setMostrarGuia(false)} />}
        {abrirEdicao && (
          <PopUp
            icone="currency_exchange"
            titulo={selecionada ? 'Alterar movimentação' : selecionados.length > 0 ? 'Alterar movimentações' : 'Adicionar uma movimentação'}
            subtitulo={'Adicione ou edite suas movimentações financeiras.'}
            onClose={() => handleCancelar()}
          >
            <FormularioTransacao
              item={selecionada}
              itens={selecionados}
              fecharModal={() => handleCancelar()}
              tipo={selecionada ? selecionada.tipo : tipo}
            />
          </PopUp>
        )}
        {abrirExclusao && (
          <PopUp
            icone="delete"
            titulo={'Excluir movimentação'}
            subtitulo={'Deseja realmente excluir as movimentações selecionadas?'}
            onClose={() => handleCancelar()}
            cor="#D92D20"
          >
            <div className={styles.botoes}>
              <Botao
                texto="Cancelar"
                icone={'close'}
                onClick={() => handleCancelar()}
                background="var(--background)"
                borderColor="var(--border)"
                color="var(--text);"
              />
              <Botao texto="Excluir" icone="delete" onClick={() => handleApagar()} background="#D92D20" color="var(--white)" />
            </div>
          </PopUp>
        )}
        <div className={styles.wrapper}>
          <TituloPagina titulo="Movimentações" subtitulo="Registre suas movimentações financeiras.">
            {selecionados.length > 0 ? (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center' }} id="adicionar">
                <Botao
                  texto={`Excluir (${selecionados.length})`}
                  onClick={() => handleAbrirExclusao()}
                  icone="delete"
                  background="var(--background)"
                  color="#D92D20"
                  borderColor="#D92D20"
                />
                <Botao
                  texto={`Editar (${selecionados.length})`}
                  onClick={() => handleAbrirEdicaoSelecionados()}
                  icone="edit"
                  background="var(--background)"
                  color="var(--primary)"
                  borderColor="var(--primary)"
                />
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center' }} id="adicionar">
                <Botao
                  texto="Despesa"
                  onClick={() => handleNovo('D')}
                  icone="trending_down"
                  background="var(--background)"
                  borderColor="#D92D20"
                  color="#D92D20"
                />
                <Botao
                  texto="Receita"
                  onClick={() => handleNovo('R')}
                  icone="trending_up"
                  background="var(--background)"
                  color="var(--primary)"
                  borderColor="var(--primary)"
                />
              </div>
            )}
          </TituloPagina>
          <Abas abas={['Despesas', 'Receitas']} abaSelecionada={abaSelecionada} setAbaSelecionada={handleSelecionarAba} />
          <Pesquisa>
            <div className={styles.filtros}>
              <TextField value={pesquisa} onChange={setPesquisa} placeholder="Pesquisar..." icone="search" />
              <ComboBox
                value={categoriaFiltro || ''}
                onChange={setCategoriaFiltro}
                icone={iconeCategoria}
                placeholder={isNotebook ? '' : 'Categoria'}
                options={opcoesCategoria}
                maxWidth="100px"
              />
              <ComboBox
                value={pagamentoFiltro || ''}
                onChange={setPagamentoFiltro}
                icone="attach_money"
                placeholder={isNotebook ? '' : 'Forma de pagamento'}
                options={opcoesPagamento}
                maxWidth="100px"
              />
              <ComboBox
                value={cartaoFiltro || ''}
                onChange={setCartaoFiltro}
                icone="credit_card"
                placeholder={isNotebook ? '' : 'Cartão de Crédito'}
                options={opcoesCartao}
                maxWidth="100px"
              />
              <ComboBox
                value={contaFiltro || ''}
                onChange={setContaFiltro}
                icone="account_balance"
                placeholder={isNotebook ? '' : 'Conta'}
                options={opcoesConta}
                maxWidth="100px"
              />
              {(tipoFiltro || pagamentoFiltro || categoriaFiltro || contaFiltro || pesquisa) && (
                <Botao texto="" onClick={handleLimparFiltros} background="transparent" color="var(--text);" icone="close" />
              )}
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
              <DateField
                value={`${filtros.movimentacao.dataInicial.toLocaleDateString()} - ${filtros.movimentacao.dataFinal.toLocaleDateString()}`}
                onSubmit={handleFiltrar}
                icone="event"
                placeholder="Data"
                range
                expandir
                disabled
              />
              <Botao
                texto={isNotebook ? '' : 'Ajuda'}
                hint="Guia"
                onClick={() => setMostrarGuia(true)}
                background="var(--background)"
                color="var(--text)"
                borderColor="var(--border)"
                icone="help"
                iconPosition="right"
              />
            </div>
          </Pesquisa>
          <div className={styles.dataContainer}>
            <DataTable
              colunas={colunas}
              linhas={dados}
              onEditar={handleAbrirEdicao}
              multiselecao
              selecionados={selecionados}
              onSelecionar={handleSelecionarLinha}
              onSelecionarTodos={handleSelecionarTodos}
            />
          </div>
        </div>
      </div>
    </Pagina>
  );
}

