import styles from './Formulario.module.css';
import { Categoria } from '../../../servicos/categorias/typeCategoria';
import { useEffect, useState } from 'react';
import { useCategorias } from '../../../servicos/categorias/useCategorias';
import Botao from '../../../componentes/botao/Botao';
import TextField from '../../../componentes/inputs/TextField';
import ComboBox from '../../../componentes/inputs/ComboBox';
import { cores, icones } from '../../../utils/Listas';
import useAvisos from '../../../hooks/useAviso';
import MoneyField from '../../../componentes/inputs/MoneyField';

type FormularioProps = {
  item: Categoria | null;
  fecharModal: () => void;
};

export default function Formulario({ item, fecharModal }: FormularioProps) {
  const [dados, setDados] = useState({ nome: '', cor: '', icone: '', tipo: 'D', valor_meta: 0 });

  const [nome, setNome] = useState('');
  const [cor, setCor] = useState('');
  const [icone, setIcone] = useState('');
  const [tipo, setTipo] = useState('');
  const [meta, setMeta] = useState(0);

  const { loading, createCategoria, updateCategoria } = useCategorias();

  const { adicionarAviso } = useAvisos();

  const handleSalvar = async () => {
    if (!tipo || !nome || !cor || !icone) {
      adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
      return;
    }

    const contaAtualizada = {
      nome: dados.nome,
      cor_identificacao: dados.cor,
      tipo: dados.tipo,
      icone: dados.icone,
      valor_meta: dados.valor_meta === 0 ? undefined : dados.valor_meta
    };

    if (item && item.id !== undefined) {
      await updateCategoria(item.id, contaAtualizada);
    } else {
      await createCategoria(contaAtualizada);
    }

    fecharModal();
  };

  useEffect(() => {
    if (item) {
      setNome(item.nome);
      setCor(item.cor_identificacao);
      setIcone(item.icone);
      setTipo(item.tipo);
      setMeta(item.valor_meta || 0);
    }
  }, [item]);

  useEffect(() => {
    setDados({ nome, cor, icone, tipo, valor_meta: meta });
  }, [nome, cor, icone, tipo, meta]);

  const listaIcones = icones.map((icone) => ({ id: icone, valor: icone, icone: icone }));
  const listaCores = cores.map((cor) => ({ id: cor, valor: cor, icone: 'radio_button_checked', cor: cor }));

  return (
    <div className={styles.container}>
      <div className={styles.campos}>
        <ComboBox
          label="Tipo de categoria"
          value={dados.tipo}
          onChange={setTipo}
          icone={dados.tipo ? (dados.tipo === 'D' ? 'trending_down' : 'trending_up') : 'swap_horiz'}
          placeholder="Tipo de categoria"
          options={[
            { id: 'D', valor: 'Despesa', icone: 'trending_down', cor: '#D92D20' },
            { id: 'R', valor: 'Receita', icone: 'trending_up', cor: '#17B26A' }
          ]}
          obrigatorio
        />
        <TextField
          icone="description"
          label="Identificação"
          value={dados.nome}
          onChange={setNome}
          placeholder="Comida, contas da casa, mercado, veterinario..."
          obrigatorio
        />
        <MoneyField label="Meta mensal" value={meta} onChange={setMeta} icone={'savings'} placeholder="Meta de gastos ou receitas" />
        <div className={styles.lista}>
          <span className={styles.titulo}>
            Cor de identificação <span style={{ color: 'var(--error)' }}>*</span>
          </span>
          <div className={styles.listaItens}>
            {listaCores.map((cor) => (
              <div
                key={cor.id}
                className={styles.iconeContainer}
                style={{
                  borderColor: cor.id === dados.cor ? 'var(--text)' : 'transparent',
                  backgroundColor: cor.id === dados.cor ? 'var(--border)' : 'transparent'
                }}
              >
                <div className={styles.icone} style={{ backgroundColor: cor.id }} onClick={() => setCor(cor.id)} />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.lista}>
          <span className={styles.titulo}>
            Ícone <span style={{ color: 'var(--error)' }}>*</span>
          </span>
          <div className={styles.listaItens}>
            {listaIcones.map((icone) => (
              <div
                key={icone.id}
                className={styles.iconeContainer}
                style={{
                  color: icone.id === dados.icone ? 'var(--primary)' : 'var(--text)'
                }}
              >
                <span className={`${styles.icone} material-symbols-rounded`} onClick={() => setIcone(icone.id)}>
                  {icone.icone}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.acoes}>
        <Botao
          texto="Cancelar"
          icone={'close'}
          onClick={fecharModal}
          background="var(--background)"
          color="var(--text);"
          borderColor="var(--border)"
        />
        <Botao
          texto={item ? 'Alterar categoria' : 'Adicionar categoria'}
          onClick={handleSalvar}
          icone={'check'}
          background="var(--primary)"
          color="var(--white)"
          spin={loading}
          enabled={!loading}
        />
      </div>
    </div>
  );
}

