import { useEffect, useRef } from 'react';
import styles from './PopUp.module.css';

type PopUpProps = {
  id?: string;
  titulo?: string;
  subtitulo?: string;
  icone?: string;
  children: React.ReactNode;
  onClose: () => void;
  cor?: string;
  noFrame?: boolean;
};

const PopUp = ({ id, titulo, cor, subtitulo, icone, children, onClose, noFrame }: PopUpProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} id={id}>
      <div className={styles.wrapper} ref={ref}>
        {!noFrame && (
          <div className={styles.cabecalho}>
            {icone && (
              <div className={styles.iconeContainer} style={{ backgroundColor: cor + '50', borderColor: cor + '10' }}>
                <span className="material-symbols-rounded" style={{ color: cor }}>
                  {icone}
                </span>
              </div>
            )}
            <div className={styles.infoContainer}>
              <span className={styles.titulo}>{titulo}</span>
              <span className={styles.subtitulo}>{subtitulo}</span>
            </div>
            <span className="material-symbols-rounded" onClick={onClose} style={{ cursor: 'pointer' }}>
              close
            </span>
          </div>
        )}
        <div className={styles.conteudo}>{children}</div>
      </div>
    </div>
  );
};

export default PopUp;

