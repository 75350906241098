import { useEffect, useState } from 'react';
import axios from 'axios';
import { Categoria } from './typeCategoria';
import { useCategoriaContexto } from '../../contextos/CategoriaContexto';
import useAvisos from '../../hooks/useAviso';
import { usePerfilContexto } from '../../contextos/PerfilContexto';

const API_URL = process.env.REACT_APP_API_URL + '/categorias';

export function useCategorias() {
  const { categorias, setCategorias } = useCategoriaContexto();
  const { perfil, setPerfil } = usePerfilContexto();

  const [loading, setLoading] = useState<boolean>(false);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token');

  // Fetch all categorias with JWT token
  const fetchCategorias = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get<Categoria[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias(response.data);
      setPerfil((prev) => ({ ...prev, carregarCategorias: false }));
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao buscar as categorias.');
    } finally {
      setLoading(false);
    }
  };

  // Create a new categoria with JWT token
  const createCategoria = async (newCategoria: Categoria) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      newCategoria.createdAt = new Date().toISOString();
      const response = await axios.post(API_URL, newCategoria, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias((prevCategorias) => [...prevCategorias, response.data]);
      adicionarAviso('Categoria adicionada com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao adicionar a categoria.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Update a categoria with JWT token
  const updateCategoria = async (id: number, updatedCategoria: Categoria) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    updatedCategoria.updatedAt = new Date().toISOString();

    setLoading(true);

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedCategoria, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCategorias((prevCategorias) => prevCategorias.map((categoria) => (categoria.id === id ? response.data : categoria)));
      adicionarAviso('Categoria alterada com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao alterar a categoria.');
    } finally {
      setLoading(false);
    }
  };

  // Delete a categoria with JWT token
  const deleteCategoria = async (listaIds: string[]) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          ids: listaIds
        }
      });
      setCategorias((prevCategorias) => prevCategorias.filter((categoria) => !listaIds.includes(categoria.id?.toString() || '')));
      adicionarAviso('Categoria removida com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao remover a categoria.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (perfil.carregarCategorias) {
      fetchCategorias();
    }
  }, [perfil.carregarCategorias]);

  return {
    categorias,
    loading,
    fetchCategorias,
    createCategoria,
    updateCategoria,
    deleteCategoria
  };
}

