import { useNavigate } from 'react-router-dom';
import styles from './MenuLateral.module.css';
import { usePerfilContexto } from '../../../contextos/PerfilContexto';
import useFirebase from '../../../hooks/useFirebase';
import { useState } from 'react';
import PopUp from '../../../componentes/popup/PopUp';
import Perfil from '../../cadastros/perfil/Perfil';
import Logo from '../../../images/Logo';
import Premium from '../../cadastros/premium/Premium';

const grupos = [
  {
    nome: 'Geral',
    menus: [
      { icone: 'dashboard', nome: 'Dashboard', url: '/dashboard', submenu: [] },
      { icone: 'currency_exchange', nome: 'Movimentações', url: '/movimentacoes', submenu: [] },
      { icone: 'backup', nome: 'Importações', url: '/importacoes', submenu: [] }
    ]
  },
  {
    nome: 'Cadastros',
    menus: [
      { icone: 'assured_workload', nome: 'Contas', url: '/contas', submenu: [] },
      { icone: 'credit_card', nome: 'Cartões de Crédito', url: '/cartoes', submenu: [] },
      { icone: 'category', nome: 'Categorias', url: '/categorias', submenu: [] }
    ]
  }
  // {
  //   nome: 'Ajuda',
  //   menus: [{ icone: 'rocket_launch', nome: 'Guias', url: '/guias', submenu: [] }]
  // }
];

export default function MenuLateral() {
  const { perfil } = usePerfilContexto();
  const [abrirPerfil, setAbrirPerfil] = useState(false);
  const [abrirPremium, setAbrirPremium] = useState(false);
  const [expandir, setExpandir] = useState(false);

  const isMobile = window.innerWidth <= 768;

  const navigate = useNavigate();

  const { logout } = useFirebase();

  function handleNavigate(nome: string, url: string) {
    navigate(url);
  }

  function handleLogout() {
    logout();
    navigate('/');
  }

  //pega o path da url para ver o menu selecionado
  const path = window.location.pathname;
  const imagem = null;

  return (
    <div className={styles.container}>
      {abrirPerfil && (
        <PopUp onClose={() => setAbrirPerfil(false)} titulo="Perfil" subtitulo="Informações pessoais e de segurança">
          <Perfil onClose={() => setAbrirPerfil(false)} />
        </PopUp>
      )}
      {abrirPremium && (
        <PopUp onClose={() => setAbrirPremium(false)} titulo="Planos" subtitulo="Selecione o melhor plano para você">
          <Premium onClose={() => setAbrirPremium(false)} />
        </PopUp>
      )}
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          {isMobile && (
            <div className={styles.menu} onClick={() => setExpandir(!expandir)}>
              <span className={`material-symbols-rounded ${styles.menuIcone}`}>menu</span>
            </div>
          )}
          <Logo className={styles.imagem} />
          <span className={styles.titulo}>
            Finant
            {perfil.subscription_status === 'active' && <span className={styles.tituloPlus}>Plus</span>}
          </span>
        </div>
        {(!isMobile || expandir) && (
          <>
            <div className={styles.grupos}>
              {grupos.map((grupo, indexGrupo) => (
                <div key={indexGrupo}>
                  <div className={styles.menu}>
                    <span className={styles.grupoTexto}>{grupo.nome.toUpperCase()}</span>
                  </div>
                  <div className={styles.menus}>
                    {grupo.menus.map((menu, index) => (
                      <div
                        id={menu.icone}
                        key={index}
                        className={`${styles.menu} ${path === menu.url ? styles.menuSelecionado : ''}`}
                        onClick={() => handleNavigate(menu.nome, menu.url)}
                      >
                        <span
                          className={`material-symbols-rounded ${styles.menuIcone}`}
                          style={{ color: path === menu.url ? 'var(--primary)' : '' }}
                        >
                          {menu.icone}
                        </span>
                        <span className={styles.menuTexto}>{menu.nome}</span>
                        <span className={`material-symbols-rounded ${styles.menuAcao}`}>chevron_right</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {perfil.subscription_status !== 'active' && (
                <div className={styles.profile} onClick={() => setAbrirPremium(true)}>
                  <span className={`material-symbols-rounded ${styles.profileImagem}`} style={{ color: 'var(--yellow)' }}>
                    bolt
                  </span>
                  <div className={styles.profileInfo}>
                    <span className={styles.profileNome}>Upgrade para Plus</span>
                    <span className={styles.profileEmail}>14 dias grátis</span>
                  </div>
                </div>
              )}
            </div>

            <div className={`${styles.menu} `} onClick={() => handleLogout()}>
              <span className={`material-symbols-rounded ${styles.menuIcone}`}>exit_to_app</span>
              <span className={styles.menuTexto}>{'Sair'}</span>
            </div>
            <div className={styles.profile} onClick={() => setAbrirPerfil(true)}>
              {imagem ? (
                <img src={imagem} alt="Profile" className={styles.profileImagem} />
              ) : (
                <span className={`material-symbols-rounded ${styles.profileImagem}`}>person</span>
              )}
              <div className={styles.profileInfo}>
                <span className={styles.profileNome}>
                  {perfil.subscription_status === 'active' && <span className={`material-symbols-rounded ${styles.premium}`}>crown</span>}
                  {perfil.nome || 'Usuario'}
                </span>
                <span className={styles.profileEmail}>{perfil.email || 'Email'}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

