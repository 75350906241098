import { useState, useEffect } from 'react';
import styles from './InputBase.module.css';
import Tooltip from '../tooltip/Tooltip';

const MoneyField = (props: {
  label?: string;
  hint?: string;
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  icone: string;
  expandir?: boolean;
  disabled?: boolean;
  cor?: string;
  min?: number;
  max?: number;
  allowNegative?: boolean; // Permitir valores negativos (opcional)
  currency?: string; // Define a moeda (ex: 'BRL', 'USD', 'EUR')
  onClick?: () => void;
  style?: React.CSSProperties;
  obrigatorio?: boolean;
}) => {
  const {
    label,
    hint,
    value,
    onChange,
    placeholder,
    icone,
    disabled,
    cor,
    min,
    max,
    allowNegative = false,
    currency = 'BRL',
    obrigatorio
  } = props;

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('pt-BR', {
      currency,
      minimumFractionDigits: 2
    }).format(amount / 100); // Convertemos de centavos para reais
  };

  const parseCurrency = (str: string) => {
    const numericValue = parseFloat(str.replace(/[^\d]/g, '')); // Remove tudo que não é número
    return isNaN(numericValue) ? 0 : numericValue;
  };

  const [rawValue, setRawValue] = useState(value ? value * 100 : 0); // Guardamos o valor em centavos internamente
  const [displayValue, setDisplayValue] = useState(value ? formatCurrency(value * 100) : '');

  useEffect(() => {
    if (value !== undefined) {
      setRawValue(value * 100);
      setDisplayValue(formatCurrency(value * 100));
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const rawInput = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    const numericValue = parseCurrency(rawInput); // Mantemos em centavos

    if (!allowNegative && numericValue < 0) return;
    if (min !== undefined && numericValue < min * 100) return;
    if (max !== undefined && numericValue > max * 100) return;

    setRawValue(numericValue);
    setDisplayValue(formatCurrency(numericValue));

    if (onChange) {
      onChange(numericValue / 100); // Enviamos para `onChange` o valor correto em reais
    }
  };

  return (
    <div className={styles.container} style={{ gap: label ? '6px' : '0px', ...props.style }}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
        {obrigatorio && <span className={styles.obrigatorio}>*</span>}
      </div>
      <div className={styles.wrapper} onClick={props.onClick}>
        <span className={`material-symbols-rounded ${styles.icone}`} style={{ color: cor }}>
          {icone}
        </span>
        <input
          type="text"
          className={styles.input}
          value={displayValue}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {props.expandir && <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>}
        {props.hint && (
          <Tooltip text={props.hint}>
            <span className={`material-symbols-rounded ${styles.hint}`}>help</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default MoneyField;

