import Painel from '../painel/Painel';
import styles from './DashMetas.module.css';
import { Transacao } from '../../../servicos/transacoes/typeTransacao';
import { Categoria } from '../../../servicos/categorias/typeCategoria';
import GraficoPercentual from '../graficos/GraficoPercentual';
import { usePerfilContexto } from '../../../contextos/PerfilContexto';

type DashMetasProps = {
  transacoes: Transacao[];
  categorias: Categoria[];
  totalDias: number;
  tipo: 'D' | 'R';
  categoriaSelecionada?: number;
  setCategoriaSelecionada?: (categoria?: number) => void;
};

const DashMetas = ({ transacoes, categorias, totalDias, tipo, categoriaSelecionada, setCategoriaSelecionada }: DashMetasProps) => {
  const transacoesFiltro = transacoes.filter((t) => t.tipo === tipo);
  const { perfil } = usePerfilContexto();

  // Mapeando as metas a partir das categorias
  const metas = categorias
    .filter((categoria) => categoria.tipo === tipo)
    .filter((categoria) => categoria.valor_meta)
    .filter((categoria) => {
      if (categoriaSelecionada === undefined) return true;
      if (categoriaSelecionada === null) return categoria.id === null || categoria.id === undefined;
      return categoria.id === categoriaSelecionada;
    })
    .map((categoria) => {
      // Filtra as transações da categoria
      const transacoesCategoria = transacoesFiltro.filter((t) => t.categoria_id === categoria.id);
      // Calcula o valor total da categoria
      const valorTotal = transacoesCategoria.reduce((acc, transacao) => acc + parseFloat(transacao.valor.toString()), 0);

      // Calcular a meta diária (considerando um mês de 30 dias)
      const valorMetaDiaria = (categoria.valor_meta || 0) / 30;

      // Meta proporcional ao período filtrado
      const valorMeta = valorMetaDiaria * totalDias;

      const valorBarraMeta = valorMeta;

      return {
        id: categoria.id || 0,
        descricao: categoria.nome,
        valor: valorTotal,
        valor_meta: valorBarraMeta < 0 ? 0 : valorBarraMeta,
        cor: categoria.cor_identificacao,
        icone: categoria.icone
      };
    });

  const metasOrder = metas.sort((a, b) => b.valor - a.valor);

  const handleSelecao = (categoriaId: number) => {
    if (categoriaId === categoriaSelecionada) {
      if (setCategoriaSelecionada) {
        setCategoriaSelecionada(undefined);
      }
      return;
    }

    if (setCategoriaSelecionada) {
      setCategoriaSelecionada(categoriaId);
    }
  };

  return (
    <Painel flex={0.5} barraRolagem id={'meta'} premium={perfil.subscription_status === 'active'}>
      <div className={styles.graficosWrapper}>
        <span className={styles.titulo}>Meta de {tipo === 'R' ? 'receitas' : 'despesas'}</span>
        {transacoes.length === 0 && (
          <div className={styles.semTransacoes}>
            <span>Nenhum registro encontrado</span>
          </div>
        )}
        {transacoes.length > 0 && (
          <GraficoPercentual metas={metasOrder} premium={perfil.subscription_status === 'active'} onClick={handleSelecao} />
        )}
      </div>
    </Painel>
  );
};

export default DashMetas;

