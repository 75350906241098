import { useState, useEffect } from 'react';
import Tooltip from '../tooltip/Tooltip';
import styles from './InputBase.module.css';
import DatePicker from '../datepicker/DatePicker';
import PopUp from '../popup/PopUp';

const DateField = (props: {
  label?: string;
  hint?: string;
  value?: string;
  onChange?: (value: string) => void;
  onSubmit?: (start: Date, end: Date) => void;
  placeholder?: string;
  icone: string;
  expandir?: boolean;
  disabled?: boolean;
  cor?: string;
  min?: string;
  max?: string;
  style?: React.CSSProperties;
  range?: boolean;
  obrigatorio?: boolean;
}) => {
  const { label, hint, value, onChange, placeholder, icone, disabled, cor, min, max, range, onSubmit, obrigatorio } = props;
  const [displayValue, setDisplayValue] = useState(value || '');
  const [abrirPicker, setAbrirPicker] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (value) {
      setDisplayValue(formatDateRange(value));
    }
  }, [value]);

  // Formata um único valor para dd/MM/yyyy
  const formatDate = (input: string): string => {
    const cleanValue = input.replace(/\D/g, ''); // Remove tudo que não for número
    let day = cleanValue.slice(0, 2);
    let month = cleanValue.slice(2, 4);
    const year = cleanValue.slice(4, 8);

    // Corrige valores inválidos
    if (parseInt(day, 10) > 31) day = '31';
    if (parseInt(month, 10) > 12) month = '12';

    let formatted = '';
    if (day) formatted += day;
    if (month) formatted += `/${month}`;
    if (year) formatted += `/${year}`;

    return formatted;
  };

  // Formata o range de datas no formato dd/MM/yyyy - dd/MM/yyyy
  const formatDateRange = (input: string): string => {
    const cleanValue = input.replace(/\D/g, ''); // Remove tudo que não for número
    const firstDate = cleanValue.slice(0, 8);
    const secondDate = cleanValue.slice(8, 16);

    let formatted = formatDate(firstDate);

    // Adiciona automaticamente o `-` se o primeiro valor estiver completo
    if (firstDate.length === 8 && secondDate.length > 0) {
      formatted += ' - ' + formatDate(secondDate);
    }

    return formatted;
  };

  // Valida se uma data está correta (dd/MM/yyyy)
  const isValidDate = (date: string): boolean => {
    const parts = date.split('/');
    if (parts.length !== 3) return false;

    const [day, month, year] = parts.map(Number);
    if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
    if (day < 1 || day > 31) return false;
    if (month < 1 || month > 12) return false;
    if (year < 1000 || year > 9999) return false;

    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;

    const formattedValue = range ? formatDateRange(e.target.value) : formatDate(e.target.value);
    setDisplayValue(formattedValue);

    // Permite limpar manualmente
    if (formattedValue.length === 0) {
      if (onChange) onChange('');
      return;
    }

    if (!range && min && formattedValue.length === 10 && formattedValue < min) return;
    if (!range && max && formattedValue.length === 10 && formattedValue > max) return;

    if (onChange) {
      if (range) {
        const parts = formattedValue.split(' - ');
        const start = parts[0] || '';
        const end = parts[1] || '';

        if (start.length === 10 && end.length === 10 && isValidDate(start) && isValidDate(end)) {
          onChange(formattedValue);
        }
      } else if (formattedValue.length === 10 && isValidDate(formattedValue)) {
        onChange(formattedValue);
      }
    }
  };

  const handleCancel = () => {
    setAbrirPicker(false);
  };

  useEffect(() => {
    let formatted = '';

    if (startDate) {
      formatted += startDate.toLocaleDateString();
    }

    if (endDate) {
      formatted += ' - ' + endDate.toLocaleDateString();
    }

    if (formatted) {
      setDisplayValue(formatted);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (value) {
      setDisplayValue(formatDateRange(value));

      const cleanValue = value.replace(/\D/g, '');
      const startRaw = cleanValue.slice(0, 8);
      const endRaw = cleanValue.slice(8, 16);

      const parseDate = (raw: string) => {
        const dia = parseInt(raw.slice(0, 2));
        const mes = parseInt(raw.slice(2, 4)) - 1; // mês começa em 0
        const ano = parseInt(raw.slice(4, 8));
        if (!isNaN(dia) && !isNaN(mes) && !isNaN(ano)) {
          return new Date(ano, mes, dia);
        }
        return null;
      };

      const start = parseDate(startRaw);
      const end = parseDate(endRaw);

      setStartDate(start);
      setEndDate(range ? end : null);
    } else {
      setDisplayValue('');
      setStartDate(null);
      setEndDate(null);
    }
  }, [value]);

  return (
    <div className={styles.container} style={{ gap: label ? '6px' : '0px', ...props.style }}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
        {obrigatorio && <span className={styles.obrigatorio}>*</span>}
      </div>
      <div className={styles.wrapper} style={{ minWidth: '100px', cursor: range ? 'pointer' : 'default' }}>
        <span className={`material-symbols-rounded ${styles.icone}`} style={{ color: cor }}>
          {icone}
        </span>
        <div onClick={() => (range ? setAbrirPicker(!abrirPicker) : null)} style={{ flex: 1 }}>
          <input
            type="text"
            className={styles.input}
            value={displayValue}
            onChange={handleChange}
            placeholder={placeholder || (range ? 'dd/MM/yyyy - dd/MM/yyyy' : 'dd/MM/yyyy')}
            disabled={disabled}
            maxLength={range ? 23 : 10} // Ajusta limite para range
          />
        </div>
        {props.expandir && (
          <span
            className={`material-symbols-rounded ${styles.icone}`}
            style={{ cursor: 'pointer' }}
            onClick={() => setAbrirPicker(!abrirPicker)}
          >
            expand_more
          </span>
        )}
        {hint && (
          <Tooltip text={hint}>
            <span className={`material-symbols-rounded ${styles.hint}`}>help</span>
          </Tooltip>
        )}
        {abrirPicker && (
          <PopUp onClose={() => handleCancel()} titulo="" subtitulo="" noFrame>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              range={range}
              onChange={(start, end) => {
                setStartDate(start);
                setEndDate(end);
              }}
              onSubmit={() => {
                if (onSubmit) {
                  if (!startDate || !endDate) {
                    return;
                  }
                  onSubmit(startDate, endDate);
                }
                setAbrirPicker(false);
              }}
              onClose={() => handleCancel()}
            />
          </PopUp>
        )}
      </div>
    </div>
  );
};

export default DateField;

