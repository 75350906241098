import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { useRef, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export type Categoria = {
  nome: string;
  valor: number;
  cor: string;
  data: string;
};

export type Grupo = {
  data: string;
  categorias: Categoria[];
};

type GraficoLinhasProps = {
  categorias: Grupo[];
  agrupamento: 'dia' | 'mes';
};

const GraficoLinhasMultiplas = ({ categorias, agrupamento }: GraficoLinhasProps) => {
  const chartRef = useRef(null);

  const getTickColor = () => getComputedStyle(document.documentElement).getPropertyValue('--textSecondary');
  const getGridColor = () => getComputedStyle(document.documentElement).getPropertyValue('--border');

  const tickColor = getTickColor();
  const gridColor = getGridColor();

  const { labels, datasets } = useMemo(() => {
    const labels = categorias.map((grupo) => grupo.data);

    const categoriasUnicas = Array.from(new Set(categorias.flatMap((grupo) => grupo.categorias.map((c) => c.nome))));

    // Criar os datasets
    const datasets = categoriasUnicas
      .map((categoriaNome) => {
        const categoriaCor = categorias.flatMap((grupo) => grupo.categorias).find((c) => c.nome === categoriaNome)?.cor || '#000';

        const data = labels.map((label) => {
          const grupo = categorias.find((g) => g.data === label);
          const categoria = grupo?.categorias.find((c) => c.nome === categoriaNome);
          return categoria ? categoria.valor : 0;
        });

        // Calcular o total acumulado para ordenar os datasets
        const total = data.reduce((acc, curr) => acc + curr, 0);

        return {
          label: categoriaNome,
          data,
          borderColor: categoriaCor,
          backgroundColor: categoriaCor,
          borderWidth: 2,
          tension: 0.1,
          fill: false,
          total
        };
      })
      .sort((a, b) => b.total - a.total); // Ordenar os datasets do maior para o menor

    return { labels, datasets };
  }, [categorias]);

  const data = {
    labels,
    datasets
  };

  const options = {
    animation: {
      duration: 1500
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'nearest' as const,
        intersect: false, // Exibir todos os valores mesmo sem passar direto na linha
        callbacks: {
          title: (tooltipItems: any) => {
            const valor = tooltipItems[0].label;
            const data = new Date(valor);

            return data.toLocaleDateString('pt-BR', {
              weekday: 'long',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            });
          },
          label: (tooltipItem: any) => {
            const label = tooltipItem.dataset.label || 'Sem descrição';
            const valor = `R$ ${tooltipItem.raw.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            return `${label} - ${valor}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false
        },
        grid: {
          display: false
        },
        ticks: {
          color: tickColor,
          font: {
            size: 10
          },
          callback: (value: any, index: any) => {
            const valor = labels[index];

            if (agrupamento === 'dia') {
              return new Date(valor)
                .toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })
                .replace('.', '')
                .replace(/^./, (match) => match.toUpperCase());
            } else {
              return new Date(valor)
                .toLocaleDateString('pt-BR', { month: 'short' })
                .replace('.', '')
                .replace(/^./, (match) => match.toUpperCase());
            }
          }
        }
      },
      y: {
        display: true,
        title: {
          display: false
        },
        grid: {
          display: true,
          color: gridColor
        },
        ticks: {
          color: tickColor,
          font: {
            size: 10
          },
          callback: (value: any) => {
            return `R$ ${Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }
      }
    },
    elements: {
      point: {
        radius: 1 // Remove os pontos da linha
      },
      line: {
        borderWidth: 2,
        tension: 0.1
      }
    }
  };

  return (
    <div style={{ flex: 1, height: '320px' }}>
      <Line ref={chartRef} data={data} options={options} id={uuidv4()} />
    </div>
  );
};

export default GraficoLinhasMultiplas;

