import { createContext, useContext } from 'react';
import { Conta } from '../servicos/contas/typeConta';

type ContaContextType = {
  contas: Conta[];
  setContas: React.Dispatch<React.SetStateAction<Conta[]>>;
};

export const ContaContexto = createContext<ContaContextType | undefined>(undefined);

export const useContaContexto = () => {
  const context = useContext(ContaContexto);
  if (!context) {
    throw new Error('useContaContexto deve ser usado dentro de um ContaContexto.Provider');
  }
  return context;
};

