import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Botao from '../../componentes/botao/Botao';
import styles from './LandingBase.module.css';

type Props = {
  onClick?: () => void;
  atualizar?: boolean;
};

const Main = ({ onClick = () => {}, atualizar }: Props) => {
  const tema = localStorage.getItem('tema');
  const imagem = tema === 'dark' ? require('../../images/print_dark.webp') : require('../../images/print.webp');
  const [expandido, setExpandido] = useState(false);

  useEffect(() => {}, [atualizar]);

  return (
    <div className={styles.container} style={{ paddingBottom: 0 }}>
      <div className={styles.wrapperMain}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '32px',
            marginTop: '64px',
            marginBottom: '64px'
          }}
        >
          <span className={styles.tituloPrincipal}>Controle seus gastos sem planilhas complicadas</span>
          <span className={styles.subtitulo}>
            Transforme o jeito como você gerencia suas finanças, de forma prática e inteligente. tenha clareza das suas receitas e despesas,
            com dashboards interativas.
          </span>
          <Botao
            texto="Quero controlar meu dinheiro"
            onClick={onClick}
            background="var(--primary)"
            color="var(--white)"
            borderColor="var(--primary)"
            iconPosition="right"
          />
        </div>

        <div
          style={{
            position: 'relative',
            flex: 1,
            overflow: 'hidden',
            maxHeight: expandido ? '100%' : '500px',
            transition: 'max-height 0.4s ease'
          }}
        >
          <img src={imagem} alt="Print" className={styles.mainImage} />

          {!expandido && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '200px',
                background: 'linear-gradient(to top, var(--background) 25%, transparent)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                paddingBottom: '36px'
              }}
            >
              <span className={'material-symbols-rounded ' + styles.expandir} onClick={() => setExpandido(true)}>
                expand_more
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Main;

