import { useState } from 'react';
import styles from './Switch.module.css';

type SwitchProps = {
  checked?: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  style?: React.CSSProperties;
};

const Switch = ({ checked = false, onChange, label, style }: SwitchProps) => {
  const handleToggle = () => {
    const newValue = !checked;
    onChange(newValue);
  };

  return (
    <div className={styles.container} onClick={handleToggle} style={style}>
      {label && (
        <div className={styles.labelContainer}>
          <span className={styles.label}>{label}</span>
        </div>
      )}
      <div className={`${styles.switchContainer} ${checked ? styles.checked : ''}`}>
        <div className={styles.switch}></div>
      </div>
    </div>
  );
};

export default Switch;

