import styles from './Botao.module.css';
import Google from '../../images/google.svg';

type BotaoProps = {
  id?: string;
  hint?: string;
  texto?: string;
  onClick: () => void;
  icone?: string;
  iconPosition?: 'left' | 'right';
  background?: string;
  color?: string;
  enabled?: boolean;
  spin?: boolean;
  borderColor?: string;
  style?: React.CSSProperties;
};

export default function Botao({
  id,
  hint,
  texto,
  onClick,
  icone,
  background = '#ffd862',
  color = '#333333',
  enabled = true,
  iconPosition = 'left',
  spin = false,
  borderColor,
  style
}: BotaoProps) {
  const handleClick = () => {
    if (enabled) {
      onClick();
    }
  };

  const corFundo = enabled === true ? background : '#dbdbdb';
  const corTexto = enabled === true ? color : 'var(--textSecondary)';

  return (
    <button
      id={id}
      className={styles.botao}
      onClick={handleClick}
      style={{
        background: corFundo,
        color: corTexto,
        borderColor: borderColor ? borderColor : corFundo,
        cursor: enabled ? 'pointer' : 'default',
        flexDirection: iconPosition === 'right' ? 'row-reverse' : 'row',
        border: `1px solid ${borderColor || corFundo}`,
        ...style
      }}
      disabled={!enabled}
    >
      {((icone && icone !== 'google') || spin) && (
        <span className={'material-symbols-rounded ' + styles.icone + (spin ? ' spin' : '')}>{spin ? 'progress_activity' : icone}</span>
      )}
      {icone === 'google' && <img src={Google} alt="Botão" className={styles.imagem} />}
      {texto && !spin && <span className={styles.texto}>{texto}</span>}
      {hint && <div className={styles.hint}>{hint}</div>}
    </button>
  );
}

