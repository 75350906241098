import styles from './Tooltip.module.css';

type TooltipProps = {
  children: React.ReactNode;
  text: string;
};

const Tooltip = ({ children, text }: TooltipProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.tooltip}>{text}</div>
      {children}
    </div>
  );
};

export default Tooltip;
