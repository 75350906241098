import { useCategoriaContexto } from '../../../contextos/CategoriaContexto';
import { usePerfilContexto } from '../../../contextos/PerfilContexto';
import GraficoRosca from '../graficos/GraficoRosca';
import Painel from '../painel/Painel';
import styles from './DashCategoria.module.css';

type DashCategoriaProps = {
  transacoes: any[];
  tipo: 'R' | 'D';
  categoriaSelecionada?: number;
  setCategoriaSelecionada?: (categoria?: number) => void;
};

const DashCategoria = ({ transacoes, tipo, categoriaSelecionada, setCategoriaSelecionada }: DashCategoriaProps) => {
  const { categorias } = useCategoriaContexto();
  const { perfil } = usePerfilContexto();

  const transacoesFiltro = transacoes.filter((t) => {
    if (categoriaSelecionada === undefined) return t.tipo === tipo;
    if (categoriaSelecionada === null) return t.tipo === tipo && (t.categoria_id === null || t.categoria_id === undefined);
    return t.tipo === tipo && t.categoria_id === categoriaSelecionada;
  });

  const agrupamentoCategoria = transacoesFiltro.reduce(
    (acc, transacao) => {
      const categoria = categorias.find((c) => c.id === transacao.categoria_id);
      const categoriaId = categoria?.id || null;
      const categoriaNome = categoria?.nome || 'Sem categoria';
      const categoriaValor = parseFloat(transacao.valor.toString());

      if (acc[categoriaNome]) {
        acc[categoriaNome].valor += categoriaValor;
        acc[categoriaNome].quantidade += 1;
      } else {
        acc[categoriaNome] = {
          id: categoriaId,
          nome: categoriaNome,
          valor: categoriaValor,
          quantidade: 1,
          cor: categoria?.cor_identificacao || '#17B26A',
          icone: categoria?.icone || 'category'
        };
      }

      return acc;
    },
    {} as { [key: string]: { id: number; nome: string; valor: number; cor: string; icone: string } }
  );

  const listaCategorias = Object.values(agrupamentoCategoria).sort((a: any, b: any) => b.valor - a.valor);

  const hexToRgba = (hex: string, alpha: number): string => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handleSelecao = (categoriaId: number) => {
    if (categoriaId === categoriaSelecionada) {
      if (setCategoriaSelecionada) {
        setCategoriaSelecionada(undefined);
      }
      return;
    }

    if (setCategoriaSelecionada) {
      setCategoriaSelecionada(categoriaId);
    }
  };

  return (
    <Painel flex={0.5} barraRolagem id={'categoria'} premium={perfil.subscription_status === 'active'}>
      <div className={styles.graficosWrapper}>
        <span className={styles.titulo}>{tipo === 'R' ? 'Receitas' : 'Despesas'} por categoria</span>
        {transacoes.length === 0 && (
          <div className={styles.semTransacoes}>
            <span>Nenhum registro encontrado</span>
          </div>
        )}
        <div className={styles.graficoCategorias}>
          <GraficoRosca
            categorias={listaCategorias.map((c: any) => ({
              nome: c.nome,
              valor: c.valor,
              quantidade: c.quantidade,
              cor_identificacao: c.cor,
              icone: c.icone
            }))}
            cores={listaCategorias.map((c: any) => c.cor)}
            tipo={tipo}
            premium={perfil.subscription_status === 'active'}
          />
          <div className={styles.transacoesWrapper}>
            {listaCategorias.map((categoria: any) => (
              <div key={categoria.nome} className={styles.transacao} onClick={() => handleSelecao(categoria.id)}>
                <span
                  className={`material-symbols-rounded ${styles.transacaoCategoria}`}
                  style={{ color: categoria.cor, backgroundColor: `${hexToRgba(categoria.cor, 0.1)}` }}
                >
                  {categoria.icone}
                </span>
                <div className={styles.transacaoInfo}>
                  <span className={styles.transacaoDescricao}>{categoria.nome}</span>
                </div>
                {perfil.subscription_status === 'active' ? (
                  <span
                    className={styles.transacaoValor}
                  >{`R$ ${parseFloat(categoria.valor).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</span>
                ) : (
                  <span className={styles.transacaoValor}>{`R$ **.**`}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Painel>
  );
};

export default DashCategoria;

