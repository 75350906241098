import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Transacao } from '../../../servicos/transacoes/typeTransacao';
import { useRef, useEffect, useMemo } from 'react';
import { useAtualizacaoContexto } from '../../../contextos/AtualizacaoContexto';
import { v4 as uuidv4 } from 'uuid';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

type GraficoLinhasProps = {
  transacoes: Transacao[];
  cor: string;
};

const GraficoLinhas = ({ transacoes, cor }: GraficoLinhasProps) => {
  const chartRef = useRef(null);
  const { atualizar } = useAtualizacaoContexto();

  // Função para obter o período em dias entre a primeira e a última transação
  const obterPeriodoEmDias = () => {
    if (transacoes.length === 0) return 0;
    const datas = transacoes.map((t) => new Date(t.data).getTime());
    const minData = Math.min(...datas);
    const maxData = Math.max(...datas);
    return Math.ceil((maxData - minData) / (1000 * 60 * 60 * 24));
  };

  const periodoEmDias = obterPeriodoEmDias();
  let agrupamento = 'mes'; // Padrão é agrupamento mensal

  if (periodoEmDias <= 31) {
    agrupamento = 'dia';
  }

  const { labels, valores } = useMemo(() => {
    let agrupamentos: { label: string; total: number }[] = [];

    // Agrupamento por dia
    if (agrupamento === 'dia') {
      const minDate = new Date(Math.min(...transacoes.map((t) => new Date(t.data).getTime())));
      const maxDate = new Date(Math.max(...transacoes.map((t) => new Date(t.data).getTime())));

      const agrupamentoMap = new Map<string, number>();

      // Inicializa o mapa com todos os dias no intervalo
      const currentDate = new Date(minDate);
      while (currentDate <= maxDate) {
        const label = currentDate.toLocaleDateString('pt-BR');
        agrupamentoMap.set(label, 0);
        currentDate.setDate(currentDate.getDate() + 1); // Avança um dia
      }

      // Preenche os totais das transações no mapa
      transacoes.forEach((transacao) => {
        const label = new Date(transacao.data).toLocaleDateString('pt-BR');
        const valorAtual = agrupamentoMap.get(label) || 0;
        agrupamentoMap.set(label, valorAtual + parseFloat(transacao.valor.toString()));
      });

      // Converte o mapa de agrupamentos para um array ordenado
      agrupamentos = Array.from(agrupamentoMap.entries()).map(([label, total]) => ({ label, total }));
    }

    // Agrupamento por mês (padrão)
    if (agrupamento === 'mes') {
      const minDate = new Date(Math.min(...transacoes.map((t) => new Date(t.data).getTime())));
      const maxDate = new Date(Math.max(...transacoes.map((t) => new Date(t.data).getTime())));

      const currentDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);

      // Gera agrupamentos para todos os meses no intervalo, mesmo que não haja transações
      while (currentDate <= maxDate) {
        const mesNome = currentDate
          .toLocaleDateString('pt-BR', { month: 'short' })
          .replace('.', '')
          .replace(/^./, (match) => match.toUpperCase());

        agrupamentos.push({ label: mesNome, total: 0 });
        currentDate.setMonth(currentDate.getMonth() + 1); // Avança um mês
      }
      const agrupamentoMap = new Map<string, { label: string; total: number }>();

      // Inicializa o mapa com os agrupamentos existentes
      agrupamentos.forEach((agrup) => {
        agrupamentoMap.set(agrup.label, agrup);
      });

      // Processa as transações e soma receitas/despesas por mês
      transacoes.forEach((transacao) => {
        const data = new Date(transacao.data);
        const label = new Date(data.getFullYear(), data.getMonth(), 1)
          .toLocaleDateString('pt-BR', { month: 'short' })
          .replace('.', '')
          .replace(/^./, (match) => match.toUpperCase());

        const agrup = agrupamentoMap.get(label);
        if (agrup) {
          agrup.total += parseFloat(transacao.valor.toString());
        } else {
          // Caso o agrupamento não exista, cria um novo
          agrupamentoMap.set(label, { label, total: parseFloat(transacao.valor.toString()) });
        }
      });

      // Converte o mapa de volta para um array
      agrupamentos = Array.from(agrupamentoMap.values());
    }

    return {
      labels: agrupamentos.map((g) => g.label),
      valores: agrupamentos.map((g) => g.total)
    };
  }, [transacoes, agrupamento]);

  const hexToRgba = (hex: string, alpha: number): string => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // Criando gradiente de cor para o fundo
  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current as any;
      const ctx = chart.ctx;
      const gradiente = ctx.createLinearGradient(0, 0, 0, 400);

      // Usando a função hexToRgba para gerar o gradiente com opacidade
      gradiente.addColorStop(0, hexToRgba(cor, 0.1));

      chart.data.datasets[0].backgroundColor = gradiente;
      chart.update();
    }
  }, [cor, atualizar]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Total',
        data: valores,
        borderColor: cor,
        borderWidth: 2,
        tension: 0.0,
        fill: true
      }
    ]
  };

  const options = {
    animation: {
      duration: 1500
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return `R$ ${tooltipItem.formattedValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false // Remove grid do eixo X
        },
        ticks: {
          color: '#6c757d', // Cor dos meses no rodapé
          font: {
            size: 12
          }
        }
      },
      y: {
        display: false,
        grid: {
          display: false // Remove grid do eixo X
        },
        ticks: {
          color: '#6c757d', // Cor dos meses no rodapé
          font: {
            size: 12
          }
        }
      }
    },
    elements: {
      point: {
        radius: 1 // Remove os pontos da linha
      },
      line: {
        borderWidth: 2,
        tension: 0.1
      }
    }
  };

  return (
    <div style={{ flex: 1, height: '90px' }}>
      <Line ref={chartRef} data={data} options={options} id={uuidv4()} />
    </div>
  );
};

export default GraficoLinhas;

