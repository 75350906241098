import { useEffect, useState } from 'react';
import Pagina from '../../estruturais/pagina/Pagina';
import styles from './CartaoCredito.module.css';
import { useCartaoCreditos } from '../../../servicos/cartaoCredito/useCartaoCredito';
import { valorParaTexto } from '../../../utils/Utils';
import { TituloPagina } from '../../estruturais/tituloPagina/TituloPagina';
import Botao from '../../../componentes/botao/Botao';
import { Pesquisa } from '../../../componentes/pesquisa/Pesquisa';
import TextField from '../../../componentes/inputs/TextField';
import DataTable, { Coluna } from '../../../componentes/datatable/DataTable';
import PopUp from '../../../componentes/popup/PopUp';
import Formulario from './Formulario';
import ComboBox from '../../../componentes/inputs/ComboBox';
import Guide from '../../../componentes/guia/Guide';

const colunas: Coluna[] = [
  { nome: 'Id', visivel: false },
  { nome: 'Nome' },
  { nome: 'Bandeira', alinhamento: 'center', tamanho: 100 },
  { nome: 'Melhor data', alinhamento: 'center', tamanho: 150 },
  { nome: 'Data de vencimento', alinhamento: 'center', tamanho: 150 },
  { nome: 'Saldo', alinhamento: 'center', tamanho: 150 },
  { nome: 'Limite', alinhamento: 'center', tamanho: 150 }
];

const isNotebook = window.innerWidth <= 1366;

const guias = [
  {
    mensagem:
      'Para adicionar um novo cartão de crédito, clique no botão "Adicionar". Registre seus cartões para acompanhar despesas e faturas de maneira organizada.',
    target: '#adicionar'
  },
  {
    mensagem:
      'Utilize a barra de pesquisa para encontrar rapidamente um cartão específico. Ideal para quando houver vários cartões cadastrados.',
    target: '#pesquisa'
  },
  {
    mensagem: 'Clique no ícone de edição para modificar os detalhes do cartão, como nome, limite ou data de vencimento.',
    target: '#editar'
  },
  {
    mensagem:
      'Para remover um cartão, clique no ícone de exclusão. As movimentações associadas serão automaticamente desvinculadas, garantindo que o histórico permaneça consistente.',
    target: '#remover'
  }
];

export default function CartaoCredito() {
  const { cartoes, deleteCartaoCredito } = useCartaoCreditos();
  const [selecionada, setSelecionada] = useState<any>(null);
  const [abrirEdicao, setAbrirEdicao] = useState(false);
  const [abrirExclusao, setAbrirExclusao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [bandeira, setBandeira] = useState('');
  const [mostrarGuia, setMostrarGuia] = useState(false);
  const [selecionados, setSelecionados] = useState<string[]>([]);

  const handleAbrirEdicao = (id: number) => {
    setAbrirEdicao(true);
    setSelecionada(cartoes.find((item) => item.id === id));
  };

  const handleAbrirExclusao = () => {
    setAbrirExclusao(true);
  };

  const handleSelecionarLinha = (id: string) => {
    if (selecionados.includes(id)) {
      setSelecionados(selecionados.filter((item) => item !== id));
    } else {
      setSelecionados([...selecionados, id]);
    }
  };

  const handleSelecionarTodos = () => {
    if (selecionados.length === dados.length) {
      setSelecionados([]);
    } else {
      setSelecionados(dados.map((item) => item[0].valor?.toString() || ''));
    }
  };

  const handleApagar = () => {
    if (selecionados.length > 0) {
      deleteCartaoCredito(selecionados);
      handleCancelar();
    }
  };

  const handleAbrirFormulario = () => {
    setAbrirEdicao(true);
  };

  const handleCancelar = () => {
    setAbrirEdicao(false);
    setAbrirExclusao(false);
    setSelecionada(null);
    setSelecionados([]);
  };

  const handleLimparFiltros = () => {
    setBandeira('');
    setPesquisa('');
  };

  const dados = cartoes
    .filter((categoria) => {
      return [
        () => pesquisa === '' || categoria.nome.toLowerCase().includes(pesquisa.toLowerCase()), // Pesquisa no nome
        () => bandeira === '' || categoria.bandeira === bandeira // Filtra pelo tipo (D ou R)
      ].every((filtro) => filtro()); // Verifica se todos os filtros retornam true
    })
    .map((cartao) => [
      { valor: cartao.id },
      { valor: cartao.nome },
      { valor: cartao.bandeira },
      { valor: 'Após dia ' + cartao.data_fechamento },
      { valor: 'Todo dia ' + cartao.data_vencimento },
      { valor: valorParaTexto(cartao.saldo || 0, true) },
      { valor: valorParaTexto(cartao.limite, true) }
    ]);

  const opcoesBandeira = [
    { id: 'Visa', valor: 'Visa' },
    { id: 'Mastercard', valor: 'Mastercard' },
    { id: 'Elo', valor: 'Elo' },
    { id: 'American Express', valor: 'American Express' },
    { id: 'Outros', valor: 'Outros' }
  ];

  return (
    <Pagina>
      <div className={styles.container}>
        {mostrarGuia && <Guide guias={guias} onClose={() => setMostrarGuia(false)} />}
        {abrirEdicao && (
          <PopUp
            icone="credit_card"
            titulo={selecionada ? 'Alterar cartão' : 'Adicionar um cartão'}
            subtitulo={'Adicione ou edite seus cartões de crédito.'}
            onClose={() => handleCancelar()}
          >
            <Formulario item={selecionada} fecharModal={() => handleCancelar()} />
          </PopUp>
        )}
        {abrirExclusao && (
          <PopUp
            icone="delete"
            titulo={'Excluir o cartão'}
            subtitulo={'Deseja realmente excluir este cartão de crédito?'}
            onClose={() => handleCancelar()}
            cor="#D92D20"
          >
            <div className={styles.botoes}>
              <Botao
                texto="Cancelar"
                icone={'close'}
                onClick={() => handleCancelar()}
                background="var(--background)"
                borderColor="var(--border)"
                color="var(--text);"
              />
              <Botao texto="Excluir" icone="delete" onClick={() => handleApagar()} background="#D92D20" color="var(--white)" />
            </div>
          </PopUp>
        )}
        <div className={styles.wrapper}>
          <TituloPagina titulo="Cartões de Crédito" subtitulo="Adicione ou edite seus cartões de crédito.">
            {selecionados.length > 0 ? (
              <Botao
                texto={`Excluir (${selecionados.length})`}
                onClick={() => handleAbrirExclusao()}
                icone="delete"
                background="var(--background)"
                borderColor="#D92D20"
                color="#D92D20"
              />
            ) : (
              <Botao
                id="adicionar"
                texto="Adicionar"
                onClick={() => setAbrirEdicao(true)}
                icone="add"
                background="var(--background)"
                color="var(--primary)"
                borderColor="var(--primary)"
              />
            )}
          </TituloPagina>
          <Pesquisa>
            <div className={styles.filtros}>
              <TextField value={pesquisa} onChange={setPesquisa} placeholder="Pesquisar..." icone="search" />
              <ComboBox
                value={bandeira}
                icone="credit_card"
                onChange={setBandeira}
                placeholder={isNotebook ? '' : 'Bandeira do cartão'}
                options={opcoesBandeira}
              />
              {(bandeira || pesquisa) && (
                <Botao texto="Limpar Filtros" onClick={handleLimparFiltros} background="transparent" color="var(--text);" icone="close" />
              )}
            </div>
            <Botao
              texto={isNotebook ? '' : 'Ajuda'}
              hint="Guia"
              onClick={() => setMostrarGuia(true)}
              background="var(--background)"
              color="var(--text)"
              borderColor="var(--border)"
              icone="help"
              iconPosition="right"
            />
          </Pesquisa>
          <DataTable
            colunas={colunas}
            linhas={dados}
            onEditar={handleAbrirEdicao}
            multiselecao
            selecionados={selecionados}
            onSelecionar={handleSelecionarLinha}
            onSelecionarTodos={handleSelecionarTodos}
          />
        </div>
      </div>
    </Pagina>
  );
}

