import { useState } from 'react';
import TextField from '../../../componentes/inputs/TextField';
import styles from './CriarConta.module.css';
import Botao from '../../../componentes/botao/Botao';
import { useNavigate } from 'react-router-dom';
import { usePerfis } from '../../../servicos/perfis/usePerfil';
import useAvisos from '../../../hooks/useAviso';
import Aviso from '../../estruturais/aviso/Aviso';
import useFirebase from '../../../hooks/useFirebase';
import Google from '../../../images/google.svg';

export default function CriarConta({ onClick }: { onClick: () => void }) {
  const [dados, setDados] = useState({ email: '', senha: '', senhaRepetida: '', termoAceite: true });

  const { loading, loginOrRegisterWithGoogle, register } = useFirebase();
  const { avisos, adicionarAviso } = useAvisos();
  const { login, createPerfil } = usePerfis();

  const navigate = useNavigate();

  const handleCriarConta = async () => {
    if (dados.email === '' || dados.senha === '' || dados.senhaRepetida === '') {
      adicionarAviso('Por favor, preencha todos os campos.', 'alerta');
      return;
    }

    if (dados.senha !== dados.senhaRepetida) {
      adicionarAviso('As senhas não coincidem. Verifique e tente novamente.', 'alerta');
      return;
    }

    const user = await register(dados.email, dados.senha);

    if (user) {
      await createPerfil({ nome: user.displayName ?? '', telefone: user.phoneNumber ?? '', email: dados.email, hashCode: user.uid }, true);
    }
  };

  const handleLoginGoogle = async () => {
    const user = await loginOrRegisterWithGoogle();

    if (user) {
      login(user.email ?? '', user.uid, false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <div className={styles.formulario}>
            <TextField label="E-mail" value={dados.email} onChange={(email) => setDados({ ...dados, email: email })} />
            <TextField label="Senha" value={dados.senha} onChange={(senha) => setDados({ ...dados, senha: senha })} password />
            <TextField
              label="Confirme a senha"
              value={dados.senhaRepetida}
              onChange={(senhaRepetida) => setDados({ ...dados, senhaRepetida: senhaRepetida })}
              password
            />
            <Botao
              texto={'Continuar'}
              icone={'check'}
              onClick={() => handleCriarConta()}
              background="var(--primary)"
              color="var(--white)"
              spin={loading}
            />
            <div className={styles.provedores}>
              <span>ou</span>
              <Botao
                texto={'Entrar com o Google'}
                icone="google"
                onClick={handleLoginGoogle}
                background="var(--background)"
                color="var(--text)"
                borderColor="var(--text)"
              />
            </div>
            <span className={styles.hint}>
              Ao criar a conta, você concorda com os{' '}
              <b style={{ color: 'var(--primary)', cursor: 'pointer' }} onClick={() => navigate('/termos-e-politicas')}>
                Termos de Serviço
              </b>{' '}
              e a{' '}
              <b style={{ color: 'var(--primary)', cursor: 'pointer' }} onClick={() => navigate('/termos-e-politicas')}>
                Política de Privacidade
              </b>
            </span>
          </div>
          <span className={styles.hint}>
            Já tem uma conta?{' '}
            <span className={styles.opcao} onClick={onClick}>
              Entrar
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

