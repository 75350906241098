import { useState } from 'react';
import Pagina from '../../estruturais/pagina/Pagina';
import styles from './Categoria.module.css';
import { useCategorias } from '../../../servicos/categorias/useCategorias';
import { TituloPagina } from '../../estruturais/tituloPagina/TituloPagina';
import Botao from '../../../componentes/botao/Botao';
import { Pesquisa } from '../../../componentes/pesquisa/Pesquisa';
import TextField from '../../../componentes/inputs/TextField';
import DataTable, { Coluna } from '../../../componentes/datatable/DataTable';
import PopUp from '../../../componentes/popup/PopUp';
import Formulario from './Formulario';
import ComboBox from '../../../componentes/inputs/ComboBox';
import { valorParaTexto } from '../../../utils/Utils';
import Guide from '../../../componentes/guia/Guide';
import Abas from '../../../componentes/abas/Abas';

const colunas: Coluna[] = [
  { nome: 'Id', visivel: false },
  { nome: 'Nome' },
  { nome: 'Meta Mensal', alinhamento: 'center', tamanho: 200 },
  { nome: 'Tipo', alinhamento: 'center', tamanho: 100 },
  { nome: 'Icone', alinhamento: 'center', tamanho: 100 }
];

const isNotebook = window.innerWidth <= 1366;

const guias = [
  {
    mensagem:
      'Para adicionar uma nova categoria, clique no botão "Adicionar". Organize suas receitas e despesas criando categorias personalizadas.',
    target: '#adicionar'
  },
  {
    mensagem:
      'Utilize a barra de pesquisa para encontrar uma categoria específica rapidamente. Ideal para quando houver várias categorias cadastradas.',
    target: '#pesquisa'
  },
  {
    mensagem: 'Clique no ícone de edição para modificar os detalhes da categoria, como nome, meta mensal, tipo e outras informações.',
    target: '#editar'
  },
  {
    mensagem:
      'Para remover uma categoria, clique no ícone de exclusão. As movimentações associadas serão automaticamente desvinculadas, garantindo que o histórico permaneça consistente.',
    target: '#remover'
  }
];

export default function Categoria() {
  const { categorias, deleteCategoria } = useCategorias();
  const [selecionada, setSelecionada] = useState<any>(null);
  const [abrirEdicao, setAbrirEdicao] = useState(false);
  const [abrirExclusao, setAbrirExclusao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [tipo, setTipo] = useState<'D' | 'R'>('D');
  const [mostrarGuia, setMostrarGuia] = useState(false);
  const [selecionados, setSelecionados] = useState<string[]>([]);
  const [abaSelecionada, setAbaSelecionada] = useState('Despesas');

  const handleAbrirEdicao = (id: number) => {
    setAbrirEdicao(true);
    setSelecionada(categorias.find((item) => item.id === id));
  };

  const handleAbrirExclusao = () => {
    setAbrirExclusao(true);
  };

  const handleSelecionarLinha = (id: string) => {
    if (selecionados.includes(id)) {
      setSelecionados(selecionados.filter((item) => item !== id));
    } else {
      setSelecionados([...selecionados, id]);
    }
  };

  const handleSelecionarTodos = () => {
    if (selecionados.length === dados.length) {
      setSelecionados([]);
    } else {
      setSelecionados(dados.map((item) => item[0].valor?.toString() || ''));
    }
  };

  const handleApagar = () => {
    if (selecionados.length > 0) {
      deleteCategoria(selecionados);
      handleCancelar();
    }
  };

  const handleSelecionarAba = (aba: string) => {
    setAbaSelecionada(aba);
    setSelecionados([]);
    setTipo(aba === 'Despesas' ? 'D' : 'R');
  };

  const handleCancelar = () => {
    setAbrirEdicao(false);
    setAbrirExclusao(false);
    setSelecionada(null);
    setSelecionados([]);
  };

  const handleLimparFiltros = () => {
    setPesquisa('');
  };

  const dados = categorias
    .filter((categoria) => {
      return [
        () => pesquisa === '' || categoria.nome.toLowerCase().includes(pesquisa.toLowerCase()), // Pesquisa no nome
        () => categoria.tipo === tipo // Filtra pelo tipo (D ou R)
      ].every((filtro) => filtro()); // Verifica se todos os filtros retornam true
    })
    .map((categoria) => [
      { valor: categoria.id },
      { valor: categoria.nome },
      { valor: valorParaTexto(categoria.valor_meta) || '-' },
      { valor: categoria.tipo === 'D' ? 'Despesa' : 'Receita' },
      { valor: '', icone: categoria.icone, cor: categoria.cor_identificacao }
    ]);

  return (
    <Pagina>
      <div className={styles.container}>
        {mostrarGuia && <Guide guias={guias} onClose={() => setMostrarGuia(false)} />}
        {abrirEdicao && (
          <PopUp
            icone="category"
            titulo={selecionada ? 'Alterar categoria' : 'Adicionar uma categoria'}
            subtitulo={'Adicione ou edite suas categorias de despesas e receitas.'}
            onClose={() => handleCancelar()}
          >
            <Formulario item={selecionada} fecharModal={() => handleCancelar()} />
          </PopUp>
        )}
        {abrirExclusao && (
          <PopUp
            icone="delete"
            titulo={'Excluir categoria'}
            subtitulo={'Deseja realmente excluir esta categoria?'}
            onClose={() => handleCancelar()}
            cor="#D92D20"
          >
            <div className={styles.botoes}>
              <Botao
                texto="Cancelar"
                icone={'close'}
                onClick={() => handleCancelar()}
                background="var(--background)"
                borderColor="var(--border)"
                color="var(--text);"
              />
              <Botao texto="Excluir" icone="delete" onClick={() => handleApagar()} background="#D92D20" color="var(--white)" />
            </div>
          </PopUp>
        )}
        <div className={styles.wrapper}>
          <TituloPagina titulo="Categorias" subtitulo="Adicione ou edite suas categorias de despesas e receitas.">
            {selecionados.length > 0 ? (
              <Botao
                texto={`Excluir (${selecionados.length})`}
                onClick={() => handleAbrirExclusao()}
                icone="delete"
                background="var(--background)"
                borderColor="#D92D20"
                color="#D92D20"
              />
            ) : (
              <Botao
                id="adicionar"
                texto="Adicionar"
                onClick={() => setAbrirEdicao(true)}
                icone="add"
                background="var(--background)"
                color="var(--primary)"
                borderColor="var(--primary)"
              />
            )}
          </TituloPagina>
          <Abas abas={['Despesas', 'Receitas']} abaSelecionada={abaSelecionada} setAbaSelecionada={handleSelecionarAba} />
          <Pesquisa>
            <div className={styles.filtros}>
              <TextField value={pesquisa} onChange={setPesquisa} placeholder="Pesquisar..." icone="search" />
              {pesquisa && (
                <Botao texto="Limpar Filtros" onClick={handleLimparFiltros} background="transparent" color="var(--text);" icone="close" />
              )}
            </div>
            <Botao
              texto={isNotebook ? '' : 'Ajuda'}
              hint="Guia"
              onClick={() => setMostrarGuia(true)}
              background="var(--background)"
              color="var(--text)"
              borderColor="var(--border)"
              icone="help"
              iconPosition="right"
            />
          </Pesquisa>
          <DataTable
            colunas={colunas}
            linhas={dados}
            onEditar={handleAbrirEdicao}
            multiselecao
            selecionados={selecionados}
            onSelecionar={handleSelecionarLinha}
            onSelecionarTodos={handleSelecionarTodos}
          />
        </div>
      </div>
    </Pagina>
  );
}

