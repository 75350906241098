import { useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo';
import styles from './LandingBase.module.css';

const Footer = ({ esconder = false }) => {
  const navigate = useNavigate();

  const handleTermos = () => {
    navigate('/termos-e-politicas');
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapperFooter}>
        <div className={styles.logo} onClick={() => document.getElementById('principal')?.scrollIntoView({ behavior: 'smooth' })}>
          <Logo className={styles.logoIcone} />
          <span>Finant</span>
        </div>
        <span className={styles.direitos}>Você no controle da sua vida financeira</span>
        {!esconder && (
          <div className={styles.menus}>
            <div className={styles.menu} onClick={() => document.getElementById('funcionalidades')?.scrollIntoView({ behavior: 'smooth' })}>
              Funcionalidades
            </div>
            <div className={styles.menu} onClick={() => document.getElementById('depoimentos')?.scrollIntoView({ behavior: 'smooth' })}>
              Depoimentos
            </div>
            <div className={styles.menu} onClick={() => document.getElementById('perguntas')?.scrollIntoView({ behavior: 'smooth' })}>
              Perguntas Frequentes
            </div>
            <div className={styles.menu} onClick={handleTermos}>
              Termos e Políticas
            </div>
          </div>
        )}
        <span className={styles.divisor} />
        <span className={styles.direitos}>🔒 Seus dados estão protegidos com criptografia avançada.</span>
        <span className={styles.direitos}>© {new Date().getFullYear()} Finant. Todos os direitos reservados.</span>
      </div>
    </div>
  );
};

export default Footer;

