import styles from './Checkout.module.css';
import { useLocation } from 'react-router-dom';
import usePagamento from '../../servicos/pagamento/usePagamento';
import { useEffect } from 'react';
import PopUp from '../../componentes/popup/PopUp';

const Checkout = () => {
  const { validarCompra } = usePagamento();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const sessionId = query.get('session_id');

  const limparUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('session_id');
    url.searchParams.delete('checkout');
    window.history.replaceState(null, '', url.toString());
  };

  const validaPagamento = async () => {
    if (sessionId) {
      const status = await validarCompra(sessionId);
      limparUrl();
      if (status?.subscription_status === 'active') {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (sessionId) {
      validaPagamento();
    }
  }, [sessionId]);

  return (
    <div className={styles.container}>
      <span className={styles.texto}>Validando pagamento..</span>
      <span className={`${styles.icone} material-symbols-rounded spin`}>progress_activity</span>
    </div>
  );
};

export default Checkout;

