import styles from './LandingBase.module.css';

const Questions = () => {
  return (
    <section id="perguntas" className={styles.container}>
      <div className={styles.wrapperFeatures}>
        <span className={styles.titulo}>Perguntas frequentes</span>
        <span className={styles.subtitulo}>Tudo que você precisa saber sobre o Finant</span>
        <div className={styles.faqs}>
          <div className={styles.faq}>
            <span className={styles.pergunta}>Para que serve o Finant?</span>
            <span className={styles.resposta}>
              O Finant é uma ferramenta de controle financeiro pessoal que ajuda você a organizar suas finanças, acompanhar seus gastos e
              planejar seu futuro financeiro.
            </span>
          </div>
          <div className={styles.faq}>
            <span className={styles.pergunta}>Eu preciso pagar para usar?</span>
            <span className={styles.resposta}>
              Não! Você pode usar a versão gratuita para sempre, mas também oferecemos planos pagos com funcionalidades avançadas.
            </span>
          </div>
          <div className={styles.faq}>
            <span className={styles.pergunta}>Consegue me dar exemplos do que conquistar com o Finant?</span>
            <span className={styles.resposta}>
              Com o Finant, você pode controlar suas contas bancárias, cartões de crédito, metas de gastos, consultar insights avançados e
              muito mais!
            </span>
          </div>
          <div className={styles.faq}>
            <span className={styles.pergunta}>Me preocupo com meus dados, é seguro?</span>
            <span className={styles.resposta}>
              Sim! Utilizamos tecnologia de ponta para proteger suas informações. Seus dados estão sempre seguros com criptografia avançada.
            </span>
          </div>
          <div className={styles.faq}>
            <span className={styles.pergunta}>Como posso começar a usar?</span>
            <span className={styles.resposta}>
              Basta criar uma conta gratuita e começar a organizar suas finanças. É fácil, rápido e seguro!
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;

