import { useEffect, useState } from 'react';
import styles from './Formulario.module.css';
import Botao from '../../../componentes/botao/Botao';
import { useContas } from '../../../servicos/contas/useContas';
import { Conta } from '../../../servicos/contas/typeConta';
import ComboBox from '../../../componentes/inputs/ComboBox';
import TextField from '../../../componentes/inputs/TextField';
import MoneyField from '../../../componentes/inputs/MoneyField';
import useAviso from '../../../hooks/useAviso';

const cores = [
  { valor: '#03a9f4', id: '#03a9f4', cor: '#03a9f4', icone: 'radio_button_checked' },
  { valor: '#4caf50', id: '#4caf50', cor: '#4caf50', icone: 'radio_button_checked' },
  { valor: '#ffc107', id: '#ffc107', cor: '#ffc107', icone: 'radio_button_checked' },
  { valor: '#ff9800', id: '#ff9800', cor: '#ff9800', icone: 'radio_button_checked' },
  { valor: '#ff5722', id: '#ff5722', cor: '#ff5722', icone: 'radio_button_checked' },
  { valor: '#cc0000', id: '#cc0000', cor: '#cc0000', icone: 'radio_button_checked' },
  { valor: '#9e9e9e', id: '#9e9e9e', cor: '#9e9e9e', icone: 'radio_button_checked' },
  { valor: '#607d8b', id: '#607d8b', cor: '#607d8b', icone: 'radio_button_checked' },
  { valor: '#8A05BE', id: '#8A05BE', cor: '#8A05BE', icone: 'radio_button_checked' },
  { valor: '#CC092F', id: '#CC092F', cor: '#CC092F', icone: 'radio_button_checked' },
  { valor: '#FF6900', id: '#FF6900', cor: '#FF6900', icone: 'radio_button_checked' },
  { valor: '#EE3124', id: '#EE3124', cor: '#EE3124', icone: 'radio_button_checked' },
  { valor: '#FFCC29', id: '#FFCC29', cor: '#FFCC29', icone: 'radio_button_checked' },
  { valor: '#005AA7', id: '#005AA7', cor: '#005AA7', icone: 'radio_button_checked' },
  { valor: '#FF7A00', id: '#FF7A00', cor: '#FF7A00', icone: 'radio_button_checked' },
  { valor: '#0033A0', id: '#0033A0', cor: '#0033A0', icone: 'radio_button_checked' },
  { valor: '#00A859', id: '#00A859', cor: '#00A859', icone: 'radio_button_checked' },
  { valor: '#101820', id: '#101820', cor: '#101820', icone: 'radio_button_checked' }
];

const bancos = [
  { id: 'Banco do Brasil', valor: 'Banco do Brasil' },
  { id: 'Bradesco', valor: 'Bradesco' },
  { id: 'Caixa Econômica', valor: 'Caixa Econômica' },
  { id: 'Itaú', valor: 'Itaú' },
  { id: 'Nubank', valor: 'Nubank' },
  { id: 'Santander', valor: 'Santander' },
  { id: 'XP Investimentos', valor: 'XP Investimentos' },
  { id: 'Outros', valor: 'Outros' }
];

const tipos = [
  { id: 'Corrente', valor: 'Corrente' },
  { id: 'Dinheiro', valor: 'Dinheiro' },
  { id: 'Poupança', valor: 'Poupança' },
  { id: 'Investimento', valor: 'Investimento' },
  { id: 'Outros', valor: 'Outros' }
];

type FormularioProps = {
  item: Conta | null;
  fecharModal: () => void;
};

export default function Formulario({ item, fecharModal }: FormularioProps) {
  const { adicionarAviso } = useAviso();

  const [dados, setDados] = useState({ saldo: 0, descricao: '', tipo: '' });

  const [saldo, setSaldo] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [tipo, setTipo] = useState('');

  const { loading, createConta, updateConta } = useContas();

  const handleSalvar = async () => {
    if (!descricao || !tipo) {
      adicionarAviso('Por favor, preencha todos os campos obrigatórios.', 'erro');
      return;
    }

    const contaAtualizada = {
      saldo: saldo,
      descricao: dados.descricao,
      tipo: dados.tipo as 'Corrente' | 'Poupança' | 'Investimento' | 'Outro'
    };

    if (item && item.id !== undefined) {
      await updateConta(item.id, contaAtualizada);
    } else {
      await createConta(contaAtualizada);
    }

    fecharModal();
  };

  useEffect(() => {
    if (item) {
      setSaldo(item.saldo);
      setDescricao(item.descricao);
      setTipo(item.tipo);
    }
  }, [item]);

  useEffect(() => {
    setDados({ saldo, descricao, tipo });
  }, [saldo, descricao, tipo]);

  return (
    <div className={styles.container}>
      <div className={styles.campos}>
        <TextField
          icone="description"
          label="Identificação"
          value={descricao}
          onChange={setDescricao}
          placeholder="Descrição da conta"
          obrigatorio
        />
        <ComboBox
          label="Tipo de conta"
          value={tipo}
          onChange={setTipo}
          icone={'account_balance_wallet'}
          placeholder="Tipo de conta"
          options={tipos}
          obrigatorio
        />
        <MoneyField
          icone="attach_money"
          label="Saldo inicial"
          value={saldo}
          onChange={setSaldo}
          placeholder="Saldo atual"
          disabled={item !== null}
          hint={item === null ? '' : 'O saldo é atualizado automaticamente com as movimentações financeiras'}
          allowNegative={true}
        />
      </div>
      <div className={styles.acoes}>
        <Botao
          texto="Cancelar"
          icone={'close'}
          onClick={fecharModal}
          background="var(--background)"
          color="var(--text);"
          borderColor="var(--border)"
        />
        <Botao
          texto={item ? 'Alterar conta' : 'Adicionar conta'}
          onClick={handleSalvar}
          background="var(--primary)"
          icone="check"
          color="var(--white)"
          spin={loading}
          enabled={!loading}
        />
      </div>
    </div>
  );
}

