import { useEffect, useState } from 'react';
import axios from 'axios';
import { Transacao } from './typeTransacao';
import { useTransacaoContexto } from '../../contextos/TransacaoContexto';
import { primeiroDiaMesAtual, ultimoDiaMesAtual } from '../../utils/Utils';
import useAvisos from '../../hooks/useAviso';

const API_URL = process.env.REACT_APP_API_URL + '/transacoes';

export function useTransacoes(atualizarGlobal = true) {
  const { transacoes, setTransacoes } = useTransacaoContexto();

  const { adicionarAviso } = useAvisos();

  const [loading, setLoading] = useState<boolean>(false);

  const parseDate = (dateStr: any): Date | null => {
    if (!dateStr) return null;

    if (typeof dateStr === 'string') {
      const parts = dateStr.split('/');
      if (parts.length !== 3) return null; // Verifica se a string está no formato esperado

      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day); // Mês começa do 0 no JS
    } else {
      return dateStr;
    }
  };

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token');

  // Fetch all transacoes with JWT token and optional filters
  const fetchTransacoes = async (filtros?: any) => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      // Construir a query string a partir dos filtros fornecidos
      const response = await axios.get<Transacao[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          // Os filtros são passados como query parameters aqui
          tipo: filtros?.tipo,
          dataInicial: filtros?.dataInicial ? parseDate(filtros?.dataInicial) : primeiroDiaMesAtual(),
          dataFinal: filtros?.dataFinal ? parseDate(filtros?.dataFinal) : ultimoDiaMesAtual(),
          descricao: filtros?.descricao,
          valorInicial: filtros?.valorInicial,
          valorFinal: filtros?.valorFinal,
          tipo_pagamento: filtros?.tipo_pagamento,
          conta_id: filtros?.conta,
          categoria_id: filtros?.categoria,
          cartao_credito_id: filtros?.cartao
        }
      });

      if (atualizarGlobal) {
        setTransacoes(response.data);
      }

      return response.data;
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao buscar as transações.');
    } finally {
      setLoading(false);
    }
  };

  // Create a new transacao with JWT token
  const createTransacao = async (newTransacao: Transacao) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const registro = await axios.post(API_URL, newTransacao, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (registro.status === 201) {
        setTransacoes((prevTransacoes) => [...prevTransacoes, registro.data]);
      }

      adicionarAviso('Movimentação adicionada com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao adicionar a movimentação.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Update a transacao with JWT token
  const updateTransacao = async (id: number, updatedTransacao: Transacao) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const registro = await axios.put(`${API_URL}/${id}`, updatedTransacao, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setTransacoes((prevTransacoes) => prevTransacoes.map((transacao) => (transacao.id === id ? registro.data : transacao)));

      adicionarAviso('Movimentação alterada com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao alterar a movimentação.');
    } finally {
      setLoading(false);
    }
  };

  const bulkUpdateTransacao = async (
    listaIds: string[],
    categoriaId?: string | null,
    contaId?: string | null,
    cartaoCreditoId?: string | null,
    tipoPagamento?: string | null
  ) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    console.log(categoriaId, contaId, cartaoCreditoId, tipoPagamento);

    // Monta dinamicamente apenas os campos informados
    const payload: any = { ids: listaIds };

    if (categoriaId) payload.categoria_id = parseInt(categoriaId);
    if (contaId) payload.conta_id = parseInt(contaId);
    if (cartaoCreditoId) payload.cartao_credito_id = parseInt(cartaoCreditoId);
    if (tipoPagamento) payload.tipo_pagamento = tipoPagamento;

    try {
      await axios.put(`${API_URL}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setTransacoes((prevTransacoes) =>
        prevTransacoes.map((transacao) => (listaIds.includes(transacao.id?.toString() || '') ? { ...transacao, ...payload } : transacao))
      );

      adicionarAviso('Movimentações alteradas com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao alterar as movimentações.');
    } finally {
      setLoading(false);
    }
  };

  // Delete a transacao with JWT token
  const deleteTransacao = async (listaIds: string[]) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${API_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          ids: listaIds
        }
      });
      setTransacoes((prevTransacoes) => prevTransacoes.filter((transacao) => !listaIds.includes(transacao.id?.toString() || '')));

      adicionarAviso('Movimentações removidas com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao remover a movimentação.');
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    transacoes,
    loading,
    fetchTransacoes,
    createTransacao,
    updateTransacao,
    bulkUpdateTransacao,
    deleteTransacao
  };
}

