import styles from './TituloPagina.module.css';

export const TituloPagina = (props: { titulo: string; subtitulo: string; children: React.ReactNode }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.containerTitulo}>
          <span className={styles.titulo}>{props.titulo}</span>
          <span className={styles.subtitulo}>{props.subtitulo}</span>
        </div>
        <div className={styles.containerChildren}>{props.children}</div>
      </div>
    </div>
  );
};
