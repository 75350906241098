import styles from './LandingBase.module.css';

const Features = () => {
  return (
    <section id="funcionalidades" className={styles.container} style={{ backgroundColor: 'var(--light-gray)' }}>
      <div className={styles.wrapperFeatures}>
        <span className={styles.dica}>Funcionalidades</span>
        <span className={styles.titulo}>Organize suas finanças de forma simples e eficiente</span>
        <span className={styles.subtitulo}>Tudo o que você precisa para sair do caos financeiro e assumir o controle do seu dinheiro.</span>
        <div className={styles.features}>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>account_balance</span>
            <span className={styles.tituloFeature}>Centralize suas contas</span>
            <span className={styles.descricaoFeature}>
              Veja saldos, transações e movimentações de todas as suas contas bancárias em um só lugar, sem precisar de planilhas.
            </span>
          </div>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>credit_card</span>
            <span className={styles.tituloFeature}>Controle total dos cartões</span>
            <span className={styles.descricaoFeature}>
              Acompanhe limites, faturas e despesas com cartões de crédito para evitar surpresas e manter seu orçamento sob controle.
            </span>
          </div>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>flag</span>
            <span className={styles.tituloFeature}>Metas que funcionam de verdade</span>
            <span className={styles.descricaoFeature}>
              Defina quanto quer gastar em cada categoria e visualize seu progresso. Ideal para cortar excessos e economizar.
            </span>
          </div>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>insights</span>
            <span className={styles.tituloFeature}>Relatórios que fazem sentido</span>
            <span className={styles.descricaoFeature}>
              Gráficos e dashboards fáceis de entender mostram onde está o problema e como melhorar sua vida financeira.
            </span>
          </div>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>lock</span>
            <span className={styles.tituloFeature}>Segurança de verdade</span>
            <span className={styles.descricaoFeature}>Seus dados protegidos com criptografia de ponta. Você no controle, sem riscos.</span>
          </div>
          <div className={styles.feature}>
            <span className={`material-symbols-rounded ${styles.iconeFeatures}`}>check_circle</span>
            <span className={styles.tituloFeature}>Fácil pra qualquer pessoa</span>
            <span className={styles.descricaoFeature}>
              Interface direta e simples. Você não precisa entender de finanças pra começar a economizar.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

