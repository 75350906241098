import { useEffect, useState } from 'react';
import styles from './DataTable.module.css';
import Botao from '../botao/Botao';
import CheckBox from '../checkbox/CheckBox';

export type Coluna = {
  visivel?: boolean;
  nome: string;
  tamanho?: number;
  alinhamento?: 'left' | 'right' | 'center';
};

export type Linha = {
  valor: any;
  cor?: string;
  icone?: string;
  imagem?: string;
};

type Props = {
  colunas: Coluna[];
  linhas: Linha[][];
  itensPorPagina?: number;
  multiselecao?: boolean;
  onEditar?: (id: number) => void;
  selecionados?: string[];
  onSelecionar?: (id: string) => void;
  onSelecionarTodos?: () => void;
};

const DataTable = ({
  colunas,
  linhas,
  multiselecao,
  itensPorPagina = 12,
  onEditar,
  selecionados,
  onSelecionar,
  onSelecionarTodos
}: Props) => {
  const [sortConfig, setSortConfig] = useState<{ key: number; direction: 'asc' | 'desc' } | null>(null);
  const [paginaAtual, setPaginaAtual] = useState(1);

  // Função para identificar o tipo de valor
  const identifyType = (value: string): string => {
    if (/^-?\d+$/.test(value)) return 'integer'; // Números inteiros
    if (/^-?\d+(\.\d+|\,\d+)?$/.test(value)) return 'decimal'; // Números decimais
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) return 'dateBR'; // Data formato BR (dd/mm/yyyy)
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) return 'dateISO'; // Data formato ISO (yyyy-mm-dd)
    if (/^(R\$|\$|€)?\s?-?\d+([.,]\d{3})*(,\d{2})?$/.test(value)) return 'currency'; // Valor monetário
    return 'text'; // Texto por padrão
  };

  // Função de normalização para texto
  const normalizeText = (text: any): string => {
    if (text === null || text === undefined) return '';

    const str = String(text); // Garante que o valor seja uma string
    return str
      .normalize('NFD') // Decompor caracteres acentuados
      .replace(/[\u0300-\u036f]/g, '') // Remover marcas diacríticas (acentos)
      .replace(/\s+/g, ' ') // Substituir múltiplos espaços por um único
      .trim() // Remover espaços no início e no fim
      .toLowerCase(); // Converter para minúsculas
  };

  // Função de comparação com base no tipo identificado
  const compare = (valA: string, valB: string): number => {
    const typeA = identifyType(valA);
    const typeB = identifyType(valB);

    if (typeA === 'integer' && typeB === 'integer') {
      return parseInt(valA) - parseInt(valB);
    }

    if (typeA === 'decimal' && typeB === 'decimal') {
      return parseFloat(valA.replace(',', '.')) - parseFloat(valB.replace(',', '.'));
    }

    if (typeA === 'currency' && typeB === 'currency') {
      const cleanValue = (value: string) =>
        parseFloat(
          value
            .replace(/[^\d,.-]/g, '')
            .replace(/\./g, '')
            .replace(',', '.')
        );
      const numA = cleanValue(valA);
      const numB = cleanValue(valB);
      return numA - numB;
    }

    if (typeA === 'dateBR' && typeB === 'dateBR') {
      const dateA = new Date(valA.split('/').reverse().join('-')).getTime();
      const dateB = new Date(valB.split('/').reverse().join('-')).getTime();
      return dateA - dateB;
    }

    if (typeA === 'dateISO' && typeB === 'dateISO') {
      return new Date(valA).getTime() - new Date(valB).getTime();
    }

    // Comparação de texto normalizado
    const normalizedA = normalizeText(valA || '');
    const normalizedB = normalizeText(valB || '');
    return normalizedA.localeCompare(normalizedB);
  };

  const sortedLinhas = [...linhas].sort((a, b) => {
    // Verificar se existe uma configuração de ordenação
    if (!sortConfig) {
      return 0;
    }

    // Se houver sortConfig, utilizar a coluna definida
    const valA = a[sortConfig.key]?.valor || '';
    const valB = b[sortConfig.key]?.valor || '';
    return sortConfig.direction === 'asc' ? compare(valA, valB) : compare(valB, valA);
  });

  const handleSort = (index: number) => {
    setSortConfig((prev) => {
      if (prev?.key === index) {
        return prev.direction === 'asc' ? { key: index, direction: 'desc' } : { key: index, direction: 'asc' };
      }
      return { key: index, direction: 'asc' };
    });
  };

  const totalPaginas = Math.ceil(sortedLinhas.length / itensPorPagina);
  const paginatedLinhas = sortedLinhas.slice((paginaAtual - 1) * itensPorPagina, paginaAtual * itensPorPagina);
  const retornarAlinhamentoJustificado = (alinhamento: string) => {
    if (alinhamento === 'center') return 'center';
    if (alinhamento === 'right') return 'flex-end';
    return 'flex-start';
  };

  const gerarNumerosPaginas = () => {
    if (totalPaginas <= 6) return Array.from({ length: totalPaginas }, (_, i) => i + 1);

    if (paginaAtual <= 3) return [1, 2, 3, '...', totalPaginas - 1, totalPaginas];
    if (paginaAtual >= totalPaginas - 2) return [1, 2, '...', totalPaginas - 2, totalPaginas - 1, totalPaginas];

    return [1, '...', paginaAtual - 1, paginaAtual, paginaAtual + 1, '...', totalPaginas];
  };

  const handlePaginaAnterior = () => {
    if (paginaAtual === 1) return;
    setPaginaAtual(paginaAtual - 1);
  };

  const handlePaginaProxima = () => {
    if (paginaAtual === totalPaginas) return;
    setPaginaAtual(paginaAtual + 1);
  };

  useEffect(() => {
    if (paginaAtual > totalPaginas) {
      setPaginaAtual(1);
    }
  }, [linhas]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {multiselecao && linhas.length > 0 && (
              <th className={styles.checkbox}>
                <CheckBox checked={selecionados?.length === linhas.length} setChecked={onSelecionarTodos} />
              </th>
            )}
            {colunas.map((coluna, index) => (
              <th
                key={index}
                onClick={() => handleSort(index)}
                style={{
                  width: coluna.tamanho ? coluna.tamanho + 'px' : 'auto',
                  display: coluna.visivel === false ? 'none' : ''
                }}
              >
                <div
                  className={styles.cabecalho}
                  style={{
                    textAlign: coluna.alinhamento || 'left',
                    justifyContent: retornarAlinhamentoJustificado(coluna.alinhamento || '')
                  }}
                >
                  <span>{coluna.nome}</span>
                  {sortConfig?.key === index ? (
                    sortConfig.direction === 'asc' ? (
                      <span className={'material-symbols-rounded ' + styles.icon}>arrow_upward_alt</span>
                    ) : (
                      <span className={'material-symbols-rounded ' + styles.icon}>arrow_downward_alt</span>
                    )
                  ) : null}
                </div>
              </th>
            ))}
            {onEditar && selecionados?.length === 0 && <th style={{ width: '0px' }}></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedLinhas.length === 0 && (
            <tr>
              <td colSpan={colunas.length} className={styles.valor} style={{ fontWeight: '600' }}>
                Nenhum registro encontrado
              </td>
            </tr>
          )}
          {paginatedLinhas.map((linha, rowIndex) => (
            <tr key={rowIndex}>
              {multiselecao && (
                <td className={styles.checkbox}>
                  <CheckBox
                    checked={selecionados?.includes(linha[0].valor.toString()) || false}
                    setChecked={() => onSelecionar && onSelecionar(linha[0].valor.toString())}
                  />
                </td>
              )}
              {linha.map((item, colIndex) => (
                <td
                  key={colIndex}
                  className={styles.valor}
                  style={{
                    textAlign: colunas[colIndex]?.alinhamento || 'left',
                    display: colunas[colIndex]?.visivel === false ? 'none' : ''
                  }}
                >
                  {item.imagem ? (
                    <img src={item.imagem} alt="imagem" className={styles.imagem} />
                  ) : item.icone ? (
                    <span className={'material-symbols-rounded ' + styles.icon} style={item.cor ? { color: item.cor } : {}}>
                      {item.icone}
                    </span>
                  ) : (
                    <span style={item.cor ? { color: item.cor } : {}}>{item.valor}</span>
                  )}
                </td>
              ))}
              {onEditar && selecionados?.length === 0 && (
                <td className={styles.editar} onClick={() => onEditar(linha[0].valor)} id="editar">
                  Editar
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.footer}>
        <div className={styles.botoes} style={{ justifyContent: 'flex-start' }}>
          {paginaAtual > 1 && (
            <Botao
              texto="Anterior"
              onClick={handlePaginaAnterior}
              background="transparent"
              color="var(--text)"
              icone="arrow_back"
              iconPosition="left"
            />
          )}
        </div>
        <div className={styles.paginas}>
          {gerarNumerosPaginas().map((num, index) => (
            <div
              key={index}
              onClick={() => typeof num === 'number' && setPaginaAtual(num)}
              className={paginaAtual === num ? styles.paginaAtiva : styles.pagina}
            >
              {num}
            </div>
          ))}
        </div>
        <div className={styles.botoes} style={{ justifyContent: 'flex-end' }}>
          {paginaAtual < totalPaginas && (
            <Botao
              texto="Próximo"
              onClick={handlePaginaProxima}
              background="transparent"
              color="var(--text)"
              icone="arrow_forward"
              iconPosition="right"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataTable;

