import { useCallback, useState } from 'react';
import useAvisos from '../../hooks/useAviso';

const API_URL = process.env.REACT_APP_API_URL + '/pagamentos';

const usePagamento = () => {
  const [loading, setLoading] = useState(false);

  // Função para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token');

  const { adicionarAviso } = useAvisos();

  const checkout = useCallback((price_key: string) => {
    const token = getToken();
    if (!token) {
      return;
    }

    setLoading(true);

    const destination = window.location.pathname;

    try {
      return fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ destination, price_key })
      })
        .then((res) => res.json())
        .then((data) => data.client_secret);
    } catch (err: any) {
      adicionarAviso(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const validarCompra = useCallback(async (sessionId: string) => {
    const token = getToken();
    if (!token) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ sessionId })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao validar a compra');
      }

      return await response.json();
    } catch (err: any) {
      adicionarAviso(err.message || 'Erro ao validar a compra');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const cancelar = useCallback(async () => {
    const token = getToken();
    if (!token) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao cancelar a assinatura');
      } else {
        const responseData = await response.json();
        const cancelDate = new Date(responseData.period_end * 1000);
        const dataNormalizada = cancelDate.toLocaleDateString('pt-BR');

        adicionarAviso(`Assinatura cancelada, você tem até ${dataNormalizada} para aproveitar de todos os benefícios do Plus!`);
      }
    } catch (err: any) {
      adicionarAviso(err.message || 'Erro ao cancelar a assinatura');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    checkout,
    validarCompra,
    cancelar,
    loading
  };
};

export default usePagamento;

