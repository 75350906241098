import { useEffect, useState } from 'react';
import Abas from '../../../componentes/abas/Abas';
import styles from './Termos.module.css';
import Footer from '../Footer';
import Header from '../Header';

export default function Termos() {
  const [abas, setAbas] = useState(['Termos de Serviço', 'Política de Privacidade']);
  const [abaSelecionada, setAbaSelecionada] = useState(abas[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Header esconder={true} onLogin={() => null} onCreateAccount={() => null} atualizar={false} onAtualizar={() => null} />
        <div className={styles.cabecalho}>
          <div className={styles.titulo}>Políticas e Termos de Serviço</div>
        </div>
        <div className={styles.corpo}>
          <Abas abas={abas} setAbaSelecionada={setAbaSelecionada} abaSelecionada={abaSelecionada} />
          {abaSelecionada === 'Termos de Serviço' ? (
            <div className={styles.conteudo}>
              <div className={styles.conteudoTitulo}>Termo de Serviço</div>

              <div className={styles.conteudoTopico}>1. Aceitação dos Termos</div>
              <div className={styles.conteudoTexto}>
                Ao acessar e utilizar a plataforma Finant, o usuário declara que leu, entendeu e concorda integralmente com os termos e
                condições aqui descritos. Caso não concorde com algum dos termos, o usuário deve interromper imediatamente o uso da
                plataforma.
              </div>

              <div className={styles.conteudoTopico}>2. Sobre a Plataforma</div>
              <div className={styles.conteudoTexto}>
                O Finant é uma plataforma digital voltada para o gerenciamento financeiro pessoal. Através dela, os usuários podem registrar
                e acompanhar movimentações financeiras, cadastrar contas bancárias, cartões de crédito, categorias de despesas e receitas,
                além de estabelecer metas financeiras e visualizar dashboards analíticos sobre o fluxo de caixa e evolução de receitas e
                despesas.
              </div>

              <div className={styles.conteudoTopico}>3. Cadastro e Responsabilidades do Usuário</div>
              <div className={styles.conteudoTexto}>
                Para utilizar os recursos da plataforma, o usuário deverá criar uma conta, fornecendo dados pessoais e de contato, como
                nome, e-mail e senha. O Finant utiliza o Google Firebase para autenticação segura dos usuários. As credenciais são
                armazenadas de forma segura e criptografada. O usuário é responsável por manter suas informações de acesso confidenciais e
                seguras, não devendo compartilhar suas credenciais com terceiros. Em caso de suspeita de uso indevido da conta, o usuário
                deverá notificar imediatamente a equipe de suporte do Finant através do e-mail suporte@finant.com.br.
              </div>

              <div className={styles.conteudoTopico}>4. Funcionalidades da Plataforma</div>
              <div className={styles.conteudoTexto}>
                <ul>
                  <li>
                    <strong>Contas:</strong> Cadastrar contas bancárias e outras fontes financeiras para registrar saldos e acompanhar
                    movimentações.
                  </li>
                  <li>
                    <strong>Cartões de Crédito:</strong> Registro e acompanhamento de cartões de crédito, incluindo gastos e faturas
                    mensais.
                  </li>
                  <li>
                    <strong>Categorias:</strong> Criar e gerenciar categorias de despesas e receitas para controle financeiro organizado.
                  </li>
                  <li>
                    <strong>Movimentações:</strong> Registrar entradas e saídas financeiras, vinculando contas e categorias para controle
                    detalhado.
                  </li>
                  <li>
                    <strong>Metas Financeiras:</strong> Estabelecer metas de receitas e despesas por categoria e acompanhar o progresso
                    através de dashboards.
                  </li>
                  <li>
                    <strong>Dashboards e Relatórios:</strong> Visualizar gráficos e relatórios para uma visão clara do fluxo financeiro e
                    alcance de metas.
                  </li>
                </ul>
              </div>

              <div className={styles.conteudoTopico}>5. Exclusão da Conta</div>
              <div className={styles.conteudoTexto}>
                O usuário pode solicitar a exclusão de sua conta a qualquer momento, entrando em contato pelo e-mail suporte@finant.com.br.
                Após a confirmação, todos os dados pessoais e registros financeiros serão permanentemente removidos do sistema.
              </div>

              <div className={styles.conteudoTopico}>6. Segurança dos Dados</div>
              <div className={styles.conteudoTexto}>
                Os dados dos usuários são armazenados em um banco de dados seguro, gerenciado pelo backend da plataforma. Todos os dados
                sensíveis são protegidos com criptografia e acessados apenas por pessoal autorizado. A autenticação dos usuários é realizada
                por meio do Google Firebase, garantindo proteção robusta das credenciais de acesso.
              </div>

              <div className={styles.conteudoTopico}>7. Limitação de Responsabilidade</div>
              <div className={styles.conteudoTexto}>
                O Finant não garante que a plataforma estará disponível de forma ininterrupta ou livre de falhas técnicas. O Finant não se
                responsabiliza por perdas financeiras decorrentes de decisões tomadas com base nos dados ou relatórios fornecidos pela
                plataforma.
              </div>

              <div className={styles.conteudoTopico}>8. Alterações nos Termos</div>
              <div className={styles.conteudoTexto}>
                O Finant reserva-se o direito de modificar os presentes termos a qualquer momento. O uso continuado da plataforma após
                modificações implica aceitação dos novos termos.
              </div>

              <div className={styles.conteudoTopico}>9. Contato</div>
              <div className={styles.conteudoTexto}>
                Para dúvidas, sugestões ou suporte, o usuário pode entrar em contato pelo e-mail suporte@finant.com.br.
              </div>
            </div>
          ) : (
            <div className={styles.conteudo}>
              <div className={styles.conteudoTitulo}>Política de Privacidade</div>

              <div className={styles.conteudoTopico}>1. Coleta de Dados</div>
              <div className={styles.conteudoTexto}>
                O Finant coleta dados pessoais dos usuários durante o cadastro, como nome, e-mail e senha, além de dados financeiros para
                registro de contas e movimentações. Dados coletados automaticamente incluem endereço IP, dispositivo utilizado e
                comportamento de navegação.
              </div>

              <div className={styles.conteudoTopico}>2. Uso dos Dados</div>
              <div className={styles.conteudoTexto}>
                Os dados coletados são utilizados para oferecer as funcionalidades da plataforma, personalizar a experiência do usuário e
                garantir a segurança. O Finant não compartilha dados pessoais com terceiros, exceto em casos previstos por lei ou com o
                consentimento expresso do usuário.
              </div>

              <div className={styles.conteudoTopico}>3. Armazenamento e Segurança</div>
              <div className={styles.conteudoTexto}>
                Todos os dados são armazenados em um ambiente seguro e criptografado, com medidas robustas para prevenção de acessos não
                autorizados. O armazenamento de dados é feito em servidores protegidos contra acessos indevidos e monitorados
                constantemente.
              </div>

              <div className={styles.conteudoTopico}>4. Direitos do Usuário</div>
              <div className={styles.conteudoTexto}>
                O usuário tem o direito de acessar, corrigir ou excluir seus dados pessoais armazenados na plataforma. Para exercer seus
                direitos, o usuário deve enviar uma solicitação para suporte@finant.com.br.
              </div>

              <div className={styles.conteudoTopico}>5. Alterações na Política</div>
              <div className={styles.conteudoTexto}>
                Esta política pode ser atualizada periodicamente. Recomenda-se que o usuário revise este documento regularmente para se
                manter informado sobre possíveis mudanças.
              </div>
            </div>
          )}
        </div>
        <Footer esconder={true} />
      </div>
    </div>
  );
}

