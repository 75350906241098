import { useEffect, useState } from 'react';
import { AvisoTipo } from '../../../contextos/AvisoContexto';
import style from './Aviso.module.css';
import useAvisos from '../../../hooks/useAviso';

type AvisoProps = {
  id: string;
  descricao: string;
  tipo: AvisoTipo;
};

const COLORS = {
  erro: '#D92D20',
  sucesso: '#079455',
  alerta: '#DC6803',
  info: '#1570ef'
};

const ICONE = {
  erro: 'error',
  sucesso: 'check_circle',
  alerta: 'warning',
  info: 'info'
};

export default function Aviso(props: AvisoProps) {
  const { id, descricao, tipo } = props;
  const [tamanho, setTamanho] = useState(100);

  const { removerAviso } = useAvisos();

  useEffect(() => {
    const duracao = 3000; // 5 segundos
    const steps = 100;
    const interval = duracao / steps; // intervalo de atualização
    let progresso = 100;

    const timer = setInterval(() => {
      progresso -= 1;
      setTamanho(progresso);

      if (progresso <= 0) {
        clearInterval(timer);
        removerAviso(id);
      }
    }, interval);

    return () => clearInterval(timer);
  }, []);

  return (
    <div id={id} className={style.container}>
      <div className={style.background} style={{ backgroundColor: COLORS[tipo] + '10', borderColor: COLORS[tipo] }}>
        <div className={style.wrapper} onClick={() => removerAviso(id)}>
          <span className={'material-symbols-rounded ' + style.icone} style={{ color: COLORS[tipo] }}>
            {ICONE[tipo]}
          </span>
          <span style={{ flex: 1 }}>{descricao}</span>
          <span className={'material-symbols-rounded ' + style.fechar}>close</span>
        </div>
        <div
          className={style.barra}
          style={{
            backgroundColor: COLORS[tipo],
            width: `${tamanho}%`,
            transition: 'width 0.05s linear'
          }}
        />
      </div>
    </div>
  );
}

