import { useEffect, useRef, useState } from 'react';
import styles from './DatePicker.module.css';
import Botao from '../botao/Botao';
import useAvisos from '../../hooks/useAviso';

type DatePickerProps = {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (startDate: Date, endDate: Date | null) => void;
  onSubmit: () => void;
  onClose: () => void;
  range?: boolean;
};

const meses = [
  { id: 0, valor: 'Janeiro' },
  { id: 1, valor: 'Fevereiro' },
  { id: 2, valor: 'Março' },
  { id: 3, valor: 'Abril' },
  { id: 4, valor: 'Maio' },
  { id: 5, valor: 'Junho' },
  { id: 6, valor: 'Julho' },
  { id: 7, valor: 'Agosto' },
  { id: 8, valor: 'Setembro' },
  { id: 9, valor: 'Outubro' },
  { id: 10, valor: 'Novembro' },
  { id: 11, valor: 'Dezembro' }
];

const semana = [
  { id: 0, valor: 'Dom' },
  { id: 1, valor: 'Seg' },
  { id: 2, valor: 'Ter' },
  { id: 3, valor: 'Qua' },
  { id: 4, valor: 'Qui' },
  { id: 5, valor: 'Sex' },
  { id: 6, valor: 'Sab' }
];

const DatePicker = ({ startDate, endDate, range = true, onChange, onSubmit, onClose }: DatePickerProps) => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Dia da semana (0 = Domingo, 1 = Segunda, ..., 6 = Sábado)

  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const { adicionarAviso } = useAvisos();

  const scrollToMonth = (month: number, year: number) => {
    setCurrentMonth(month);
    setCurrentYear(year);
  };

  const handleDateClick = (date: Date) => {
    const normalizedDate = normalizeDate(date);

    if (!range) {
      onChange(normalizedDate, null);
      return;
    }

    if (!startDate || (startDate && endDate)) {
      onChange(normalizedDate, null);
    } else {
      const start = normalizeDate(startDate);
      const end = normalizeDate(date < start ? startDate : date);

      const diffInDays = Math.abs((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
      if (diffInDays > 365) {
        adicionarAviso('O período máximo permitido é de 1 ano.', 'erro');
        return;
      }

      onChange(start, end);
    }
  };

  const handleMonthChange = (direction: 'prev' | 'next') => {
    if (direction === 'prev') {
      setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1));
      if (currentMonth === 0) setCurrentYear((prev) => prev - 1);
    } else {
      setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1));
      if (currentMonth === 11) setCurrentYear((prev) => prev + 1);
    }
  };

  // Função para normalizar as datas (removendo horas, minutos e milissegundos)
  const normalizeDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const renderCalendar = (monthOffset: number) => {
    const month = (currentMonth + monthOffset) % 12;
    const year = currentYear + Math.floor((currentMonth + monthOffset) / 12);
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className={styles.empty}></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = normalizeDate(new Date(year, month, day));

      // Comparação agora usa a data normalizada
      const isStart = startDate && date.getTime() === normalizeDate(startDate).getTime();
      const isEnd = endDate && date.getTime() === normalizeDate(endDate).getTime();
      const isSelected = startDate && (!range ? isStart : (!endDate && isStart) || (endDate && date >= startDate && date <= endDate));

      days.push(
        <div
          key={day}
          className={`${styles.day} ${isSelected ? styles.selected : ''} ${isStart ? styles.start : ''} ${isEnd ? styles.end : ''}`}
          onClick={() => handleDateClick(date)}
        >
          {day}
        </div>
      );
    }

    return (
      <div className={styles.calendar}>
        <div className={styles.header}>
          {monthOffset === 1 && <span />}
          {monthOffset === 0 && (
            <span className={`material-symbols-rounded ${styles.navegacao}`} onClick={() => handleMonthChange('prev')}>
              chevron_left
            </span>
          )}
          <span className={styles.month}>{meses.find((m) => m.id === month)?.valor + ' ' + year}</span>
          {monthOffset === 1 && (
            <span className={`material-symbols-rounded ${styles.navegacao}`} onClick={() => handleMonthChange('next')}>
              chevron_right
            </span>
          )}
          {monthOffset === 0 && <span />}
        </div>
        <div className={styles.weekdays}>
          {semana.map((dia) => (
            <div key={dia.id} className={styles.weekday}>
              {dia.valor}
            </div>
          ))}
        </div>
        <div className={styles.days}>{days}</div>
      </div>
    );
  };

  useEffect(() => {
    const refDate = startDate || endDate;
    if (refDate) {
      const normalized = normalizeDate(refDate);
      setCurrentMonth(normalized.getMonth());
      setCurrentYear(normalized.getFullYear());
    }
  }, [startDate, endDate]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {range && (
          <div className={styles.sidebar}>
            <div
              className={styles.option}
              onClick={() => {
                const todayNormalized = normalizeDate(new Date());
                onChange(todayNormalized, todayNormalized);
                scrollToMonth(today.getMonth(), today.getFullYear());
              }}
            >
              Hoje
            </div>

            <div className={styles.separador} />

            <div
              className={styles.option}
              onClick={() => {
                // Calcular a segunda-feira da semana passada
                const mondayLastWeek = new Date(today);
                mondayLastWeek.setDate(today.getDate() - dayOfWeek - 7);

                // Calcular o domingo da semana passada (6 dias após a segunda)
                const sundayLastWeek = new Date(mondayLastWeek);
                sundayLastWeek.setDate(mondayLastWeek.getDate() + 7);

                onChange(mondayLastWeek, sundayLastWeek);
                scrollToMonth(mondayLastWeek.getMonth(), mondayLastWeek.getFullYear());
              }}
            >
              Semana passada
            </div>
            <div
              className={styles.option}
              onClick={() => {
                const monday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek - 1));
                const sunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - dayOfWeek));
                onChange(monday, sunday);
                scrollToMonth(monday.getMonth(), monday.getFullYear());
              }}
            >
              Esta semana
            </div>
            <div
              className={styles.option}
              onClick={() => {
                // Calcular a segunda-feira da próxima semana
                const monday = new Date(today);
                monday.setDate(today.getDate() - dayOfWeek + 7);
                // Calcular o domingo da próxima semana (6 dias após a segunda)
                const sunday = new Date(monday);
                sunday.setDate(monday.getDate() + 7);
                onChange(monday, sunday);
                scrollToMonth(monday.getMonth(), monday.getFullYear());
              }}
            >
              Próxima semana
            </div>

            <div className={styles.separador} />

            <div
              className={styles.option}
              onClick={() => {
                // Calcular o primeiro dia do mês passado
                const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                onChange(firstDayLastMonth, lastDayLastMonth);
                scrollToMonth(firstDayLastMonth.getMonth(), firstDayLastMonth.getFullYear());
              }}
            >
              Mês passado
            </div>
            <div
              className={styles.option}
              onClick={() => {
                // Calcular o primeiro dia do mês atual
                const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                onChange(firstDayThisMonth, lastDayThisMonth);
                scrollToMonth(firstDayThisMonth.getMonth(), firstDayThisMonth.getFullYear());
              }}
            >
              Este mês
            </div>
            <div
              className={styles.option}
              onClick={() => {
                const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                const lastDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

                // Garantir que o próximo mês não ultrapasse 1 ano
                const diffInDays = Math.abs((lastDayNextMonth.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
                if (diffInDays > 365) {
                  adicionarAviso('O período máximo permitido é de 1 ano.', 'erro');
                  return;
                }

                onChange(firstDayNextMonth, lastDayNextMonth);
                scrollToMonth(firstDayNextMonth.getMonth(), firstDayNextMonth.getFullYear());
              }}
            >
              Próximo mês
            </div>

            <div className={styles.separador} />

            <div
              className={styles.option}
              onClick={() => {
                // Calcular o primeiro dia deste ano
                const firstDayThisYear = new Date(today.getFullYear(), 0, 1);
                const lastDayThisYear = new Date(today.getFullYear(), 11, 31);
                onChange(firstDayThisYear, lastDayThisYear);
                scrollToMonth(firstDayThisYear.getMonth(), firstDayThisYear.getFullYear());
              }}
            >
              Este ano
            </div>

            <div className={styles.separador} />

            <div
              className={styles.option}
              onClick={() => {
                const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
                onChange(firstDay, today);
                scrollToMonth(firstDay.getMonth(), firstDay.getFullYear());
              }}
            >
              Últimos 7 dias
            </div>

            <div
              className={styles.option}
              onClick={() => {
                const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
                onChange(firstDay, today);
                scrollToMonth(firstDay.getMonth(), firstDay.getFullYear());
              }}
            >
              Últimos 30 dias
            </div>

            <div
              className={styles.option}
              onClick={() => {
                const firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 364);
                onChange(firstDay, today);
                scrollToMonth(firstDay.getMonth(), firstDay.getFullYear());
              }}
            >
              Últimos 365 dias
            </div>
          </div>
        )}
        <div className={styles.calendarContainer}>{renderCalendar(0)}</div>
        <div className={styles.calendarContainer}>{renderCalendar(1)}</div>
      </div>
      <div className={styles.footer}>
        <Botao
          texto="Selecionar"
          onClick={onSubmit}
          background="var(--primary)"
          color="var(--white)"
          borderColor="var(--primary)"
          icone="check"
        />
      </div>
    </div>
  );
};

export default DatePicker;

