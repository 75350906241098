import axios from 'axios';
import { useState } from 'react';
import useAvisos from '../../hooks/useAviso';

const API_URL = process.env.REACT_APP_API_URL + '/openfinance';

const useImportacao = () => {
  const [loading, setLoading] = useState(false);
  const { adicionarAviso } = useAvisos();

  const getToken = () => localStorage.getItem('token');

  const importar = async (arquivo: File) => {
    if (loading) return;

    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.');
      return 'Usuário não autenticado.';
    }

    const formData = new FormData();
    formData.append('arquivo', arquivo);

    setLoading(true);
    try {
      const response = await axios.post(API_URL, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
    } catch (err: any) {
      const msg = err?.response?.data?.error || 'Erro ao importar arquivo.';
      return msg; // <-- importante retornar o erro como string
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    importar
  };
};

export default useImportacao;

