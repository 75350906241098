import { useState } from 'react';
import TextField from '../../../componentes/inputs/TextField';
import styles from './RecuperarSenha.module.css';
import Botao from '../../../componentes/botao/Botao';
import useFirebase from '../../../hooks/useFirebase';

type RecuperarSenhaProps = {
  onClick: () => void;
};

export default function RecuperarSenha({ onClick }: RecuperarSenhaProps) {
  const [dados, setDados] = useState({ email: '' });

  const { resetPassword } = useFirebase();

  const handleRecuperarSenha = () => {
    resetPassword(dados.email);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <div className={styles.formulario}>
            <TextField
              label="E-mail cadastrado"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              placeholder="E-mail"
            />
            <Botao
              icone="check"
              texto="Continuar"
              onClick={() => handleRecuperarSenha()}
              background="var(--primary)"
              color="var(--white)"
            />
          </div>
          <span className={styles.hint}>
            Lembrou da senha?{' '}
            <span className={styles.opcao} onClick={onClick}>
              Entrar
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

