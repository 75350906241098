import { useMemo } from 'react';
import useAvisos from '../../../hooks/useAviso';
import Aviso from './Aviso';
import styles from './Avisos.module.css';
import { usePerfis } from '../../../servicos/perfis/usePerfil';

type Props = {
  children: React.ReactNode;
};

const Avisos = (props: Props) => {
  const { avisos } = useAvisos();
  const {} = usePerfis();

  const avisosRenderizados = useMemo(
    () => avisos.map((aviso) => <Aviso key={aviso.id} id={aviso.id} descricao={aviso.descricao} tipo={aviso.tipo} />),
    [avisos]
  );

  return (
    <div className={styles.container}>
      <div className={styles.avisos}>{avisosRenderizados}</div>
      {props.children}
    </div>
  );
};
export default Avisos;

