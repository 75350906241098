import { useEffect, useState } from 'react';
import axios from 'axios';
import { CartaoCredito } from './typeCartaoCredito';
import useAvisos from '../../hooks/useAviso';
import { useCartaoCreditoContexto } from '../../contextos/CartaoCreditoContexto';
import { usePerfilContexto } from '../../contextos/PerfilContexto';

const API_URL = process.env.REACT_APP_API_URL + '/cartoes';

export function useCartaoCreditos() {
  const { cartoes, setCartoes } = useCartaoCreditoContexto();
  const { perfil, setPerfil } = usePerfilContexto();

  const [loading, setLoading] = useState<boolean>(false);

  const { adicionarAviso } = useAvisos();

  // Função auxiliar para obter o token JWT do localStorage
  const getToken = () => localStorage.getItem('token');

  // Fetch all cartaoCreditos with JWT token
  const fetchCartaoCreditos = async () => {
    if (loading) {
      return;
    }

    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get<CartaoCredito[]>(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCartoes(response.data);
      setPerfil((prev) => ({ ...prev, carregarCartoes: false }));
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao buscar os cartões.');
    } finally {
      setLoading(false);
    }
  };

  // Create a new cartaoCredito with JWT token
  const createCartaoCredito = async (newCartaoCredito: CartaoCredito) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(API_URL, newCartaoCredito, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setCartoes((prev) => [...prev, response.data]);

      adicionarAviso('Cartão adicionado com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao adicionar o cartão.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Update a cartaoCredito with JWT token
  const updateCartaoCredito = async (id: number, updatedCartaoCredito: CartaoCredito) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedCartaoCredito, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCartoes((prev) => prev.map((cartao: CartaoCredito) => (cartao.id === id ? { ...response.data } : cartao)));
      adicionarAviso('Cartão alterado com sucesso!', 'sucesso');
    } catch (err) {
      adicionarAviso('Ocorreu um erro ao alterar o cartão.');
    } finally {
      setLoading(false);
    }
  };

  // Delete a cartaoCredito with JWT token
  const deleteCartaoCredito = async (listaIds: string[]) => {
    const token = getToken();
    if (!token) {
      adicionarAviso('Usuário não autenticado.', 'erro');
      return;
    }

    setLoading(true);

    try {
      await axios.delete(`${API_URL}/`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          ids: listaIds
        }
      });
      setCartoes((prev) => prev.filter((cartao: CartaoCredito) => !listaIds.includes(cartao.id?.toString() || '')));
      adicionarAviso('Cartão removido com sucesso!', 'sucesso');
    } catch (err: any) {
      if (err?.response?.data?.message) {
        adicionarAviso(err.response.data.message);
      } else {
        adicionarAviso('Ocorreu um erro ao remover o cartão.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (perfil.carregarCartoes) {
      fetchCartaoCreditos();
    }
  }, [perfil.carregarCartoes]);

  return {
    cartoes,
    loading,
    fetchCartaoCreditos,
    createCartaoCredito,
    updateCartaoCredito,
    deleteCartaoCredito
  };
}

