import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Transacao } from '../../../servicos/transacoes/typeTransacao';
import { useEffect, useMemo, useRef } from 'react';
import { useAtualizacaoContexto } from '../../../contextos/AtualizacaoContexto';
import { v4 as uuidv4 } from 'uuid';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type GraficoBarrasDuplasProps = {
  transacoes: Transacao[];
  cor1: string;
  cor2: string;
};

const GraficoBarrasDuplas = ({ transacoes, cor1, cor2 }: GraficoBarrasDuplasProps) => {
  const chartRef = useRef(null);
  const { atualizar } = useAtualizacaoContexto();

  const getTickColor = () => getComputedStyle(document.documentElement).getPropertyValue('--textSecondary');
  const getGridColor = () => getComputedStyle(document.documentElement).getPropertyValue('--border');

  const tickColor = getTickColor();
  const gridColor = getGridColor();

  // Função para obter o período em dias entre a primeira e a última transação
  const obterPeriodoEmDias = () => {
    if (transacoes.length === 0) return 0;
    const datas = transacoes.map((t) => new Date(t.data).getTime());
    const minData = Math.min(...datas);
    const maxData = Math.max(...datas);
    return Math.ceil((maxData - minData) / (1000 * 60 * 60 * 24));
  };

  const periodoEmDias = obterPeriodoEmDias();

  let agrupamento = 'mes'; // Padrão é agrupamento mensal

  if (periodoEmDias <= 31) {
    agrupamento = 'dia';
  }

  const { labels, receitaData, despesaData } = useMemo(() => {
    let agrupamentos: { label: string; receita: number; despesa: number }[] = [];

    // Agrupamento por dia
    if (agrupamento === 'dia') {
      const agrupamentoMap = new Map<string, { receita: number; despesa: number }>();

      // Preenche o mapa com todas as datas presentes nas transações
      transacoes.forEach((transacao) => {
        const label = new Date(transacao.data).toLocaleDateString('pt-BR');
        if (!agrupamentoMap.has(label)) {
          agrupamentoMap.set(label, { receita: 0, despesa: 0 });
        }
        const agrup = agrupamentoMap.get(label);
        if (agrup) {
          if (transacao.tipo === 'R') {
            agrup.receita += parseFloat(transacao.valor.toString());
          } else if (transacao.tipo === 'D') {
            agrup.despesa += parseFloat(transacao.valor.toString());
          }
        }
      });

      // Converte o mapa para um array de agrupamentos
      agrupamentos = Array.from(agrupamentoMap.entries()).map(([label, { receita, despesa }]) => ({
        label,
        receita,
        despesa
      }));
    }

    // Agrupamento por mês (padrão)
    if (agrupamento === 'mes') {
      const agrupamentoMap = new Map<string, { receita: number; despesa: number }>();

      // Preenche o mapa com todos os meses presentes nas transações
      transacoes.forEach((transacao) => {
        const data = new Date(transacao.data);
        const chave = `${data.getFullYear()}-${data.getMonth()}`;

        if (!agrupamentoMap.has(chave)) {
          agrupamentoMap.set(chave, { receita: 0, despesa: 0 });
        }

        const agrup = agrupamentoMap.get(chave);
        if (agrup) {
          if (transacao.tipo === 'R') {
            agrup.receita += parseFloat(transacao.valor.toString());
          } else if (transacao.tipo === 'D') {
            agrup.despesa += parseFloat(transacao.valor.toString());
          }
        }
      });

      // Converte o mapa para um array de agrupamentos
      agrupamentos = Array.from(agrupamentoMap.entries()).map(([chave, { receita, despesa }]) => {
        const [ano, mesNum] = chave.split('-').map(Number);
        const date = new Date(ano, mesNum, 1);
        const mesNome = date
          .toLocaleDateString('pt-BR', { month: 'short' })
          .replace('.', '')
          .replace(/^./, (match) => match.toUpperCase());
        return { label: mesNome, receita, despesa, data: date };
      });
    }

    return {
      labels: agrupamentos.map((g) => g.label),
      receitaData: agrupamentos.map((g) => g.receita),
      despesaData: agrupamentos.map((g) => g.despesa)
    };
  }, [transacoes, agrupamento]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Receita',
        data: receitaData,
        borderColor: cor1,
        borderWidth: 1,
        barThickness: 10,
        borderRadius: 5
      },
      {
        label: 'Despesa',
        data: despesaData,
        borderColor: cor2,
        borderWidth: 1,
        barThickness: 10,
        borderRadius: 5
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            return `R$ ${parseFloat(tooltipItem.raw).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          color: tickColor,
          font: {
            size: 10
          }
        }
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          color: gridColor
        },
        ticks: {
          color: tickColor,
          font: {
            size: 10
          },
          callback: (value: any) =>
            `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        }
      }
    }
  };

  // Função para atualizar as cores dinâmicas
  const updateChartColors = () => {
    if (chartRef.current) {
      const chart = chartRef.current as any;
      const ctx = chart.ctx;

      const gradienteA = ctx.createLinearGradient(0, 0, 0, 400);
      const gradienteB = ctx.createLinearGradient(0, 0, 0, 400);

      // Gradiente A - verde
      gradienteA.addColorStop(0, hexToRgba(cor1, 1));

      // Gradiente B - vermelho
      gradienteB.addColorStop(0, hexToRgba(cor2, 1));

      chart.data.datasets[0].backgroundColor = gradienteA;
      chart.data.datasets[1].backgroundColor = gradienteB;

      chart.options.scales.x.ticks.color = getTickColor();
      chart.options.scales.y.ticks.color = getTickColor();
      chart.options.scales.y.grid.color = getGridColor();

      chart.update();
    }
  };

  const hexToRgba = (hex: string, alpha: number): string => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  useEffect(() => {
    updateChartColors();
  }, [options, cor1, cor2, atualizar]);

  return (
    <div style={{ width: '100%', height: '300px' }}>
      <Bar ref={chartRef} data={data} options={options} id={uuidv4()} />
    </div>
  );
};

export default GraficoBarrasDuplas;

