import GraficoLinhas from '../graficos/GraficoLinhas';
import Painel from '../painel/Painel';
import styles from './DashDespesas.module.css';

type DashDespesasProps = {
  transacoes: any[];
};

const DashDespesas = ({ transacoes }: DashDespesasProps) => {
  const saldo = transacoes.reduce((acc, t) => acc + parseFloat(t.valor.toString()), 0);

  const cor = getComputedStyle(document.documentElement).getPropertyValue('--negative');

  const transacoesOrdenadas = transacoes.sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());

  return (
    <Painel flex={1} id="despesas">
      <div className={styles.graficos}>
        <div className={styles.graficosWrapper}>
          <span className={styles.titulo}>Despesas</span>
          <span className={styles.labelSaldo}>Gastos totais</span>
          <div className={styles.infosSaldo}>
            <span className={styles.saldoConta}>
              R$ {saldo.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>
        <GraficoLinhas transacoes={transacoesOrdenadas} cor={cor} />
      </div>
    </Painel>
  );
};

export default DashDespesas;

