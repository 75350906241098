import Tooltip from '../tooltip/Tooltip';
import styles from './InputBase.module.css';

const NumberField = (props: {
  label?: string;
  hint?: string;
  value?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  icone?: string;
  expandir?: boolean;
  disabled?: boolean;
  cor?: string;
  min?: number;
  max?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
  obrigatorio?: boolean;
}) => {
  const { label, hint, value, onChange, placeholder, icone, obrigatorio } = props;

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled || parseFloat(e.target.value) < 0) {
      return;
    }

    if (props.max && parseFloat(e.target.value) > props.max) {
      return;
    }

    if (props.min && parseFloat(e.target.value) < props.min) {
      return;
    }

    if (onChange) {
      onChange(parseFloat(e.target.value));
    }
  };

  return (
    <div className={styles.container} style={{ gap: label ? '6px' : '0px', ...props.style }}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
        {obrigatorio && <span className={styles.obrigatorio}>*</span>}
      </div>
      <div className={styles.wrapper} onClick={props.onClick}>
        {icone && (
          <span className={`material-symbols-rounded ${styles.icone}`} style={{ color: props.cor }}>
            {icone}
          </span>
        )}
        <input
          type="text"
          className={styles.input}
          value={value || ''}
          onChange={onChangeInput}
          placeholder={placeholder}
          disabled={props.disabled}
          min={props.min}
          max={props.max}
        />
        {props.expandir && <span className={`material-symbols-rounded ${styles.icone}`}>expand_more</span>}
        {props.hint && (
          <Tooltip text={props.hint}>
            <span className={`material-symbols-rounded ${styles.hint}`}>help</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default NumberField;

