import { useEffect, useState } from 'react';
import PopUp from '../popup/PopUp';
import styles from './Guia.module.css';
import Botao from '../botao/Botao';
import Logo from '../../images/Logo';

const Guia = () => {
  const [mostrarGuia, setMostrarGuia] = useState(false);

  const [passos, setPassos] = useState([
    {
      titulo: 'Bem-vindo ao Finant!',
      subtitulo: 'Vamos te mostrar como aproveitar ao máximo seu controle financeiro.'
    },
    {
      imagem: require('../../images/guia1.png'),
      titulo: 'Cadastro de Contas',
      subtitulo: 'Adicione suas contas bancárias e acompanhe o saldo disponível.'
    },
    {
      imagem: require('../../images/guia2.png'),
      titulo: 'Cadastro de Cartão de Crédito',
      subtitulo: 'Registre seus cartões para controlar gastos e faturas mensais.'
    },
    {
      imagem: require('../../images/guia3.png'),
      titulo: 'Categorias de Receitas e Despesas',
      subtitulo: 'Organize suas movimentações por categorias e defina metas financeiras.'
    },
    {
      imagem: require('../../images/guia4.png'),
      titulo: 'Registro de Movimentações',
      subtitulo: 'Registre suas despesas e receitas para manter o controle das finanças.'
    },
    {
      imagem: require('../../images/guia5.png'),
      titulo: 'Dashboard Financeiro',
      subtitulo: 'Visualize sua evolução! análise por categoria e acompanhe suas metas.'
    }
  ]);

  const [passoAtual, setPassoAtual] = useState(0);

  const handleClose = () => {
    setMostrarGuia(false);
    localStorage.setItem('guia', 'true');
  };

  useEffect(() => {
    const guia = localStorage.getItem('guia');
    if (!guia) {
      setMostrarGuia(true);
    }
  }, []);

  return !mostrarGuia ? null : (
    <PopUp onClose={handleClose}>
      <div className={styles.guia}>
        <div className={styles.imageContainer} style={{ borderWidth: passoAtual === 0 ? 0 : 1 }}>
          {passoAtual === 0 ? (
            // <Logo className={styles.logo} />
            <iframe
              width="520"
              height="293"
              src="https://www.youtube.com/embed/Vb7omR82MKM?si=4xhQn-qlGsjmS5A-&amp;controls=0"
              title="Finant"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          ) : (
            <img src={passos[passoAtual].imagem} alt="Guia" className={styles.imagem} />
          )}
        </div>
        <div className={styles.titulo}>{passos[passoAtual].titulo}</div>
        <div className={styles.subtitulo}>{passos[passoAtual].subtitulo}</div>
        <div className={styles.progresso}>
          {passos.map((_, index) => (
            <div key={index} className={index === passoAtual ? styles.ativo : styles.inativo} />
          ))}
        </div>
        <div className={styles.botoes}>
          {passoAtual > 0 ? (
            <Botao
              onClick={() => setPassoAtual(passoAtual - 1)}
              texto="Anterior"
              icone="arrow_back"
              background="var(--background)"
              color="var(--text);"
              borderColor="var(--border)"
            />
          ) : (
            <div />
          )}
          {passoAtual < passos.length - 1 ? (
            <Botao
              onClick={() => setPassoAtual(passoAtual + 1)}
              texto="Proximo"
              background="var(--primary)"
              icone="arrow_forward"
              color="var(--white)"
              iconPosition="right"
            />
          ) : (
            <Botao onClick={handleClose} texto="Fechar" background="var(--primary)" icone="check" color="var(--white)" />
          )}
        </div>
      </div>
    </PopUp>
  );
};

export default Guia;

